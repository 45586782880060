import { useParams } from "react-router-dom";
import t from "@/lang/lang";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { DeleteWorkOrderDialog } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/delete-work-order-dialog";
import { WorkOrderPdf } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/work-order-pdf";
import {
  OfflineContextProvider,
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import { OfflineButton } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-button";
import { StaleContextProvider } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/stale-context";
import { useGetWorkOrder } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/work-order-hooks";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { ReactNode } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { Check, RefreshCw } from "lucide-react";
import { SignatureButton } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/signature-button";
import {
  ErrArtefactNotReadyContextProvider,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { hasFieldError } from "@/shared/app-lib/errors/validation-error";
import { ArtefactImmutableAlert } from "@/shared/service-manager/artefact/artefact";
import {
  ArtefactPage,
  ArtefactPageActions,
  ArtefactPageContent,
  ArtefactPageHeader,
  ArtefactPageNavigation,
  ArtefactPageNavigationLink,
  ArtefactPageNavigationOutlet,
} from "@/shared/service-manager/default-ui/artefact-page/artefact-page";

export function WorkOrderRoute() {
  const { workOrderId } = useParams();

  return (
    <OfflineContextProvider workOrderId={workOrderId!}>
      <StaleContextProvider
        initiallyStale={[
          // TODO, add missing
          "workOrder",
          "position",
          "airHandlingUnit",
          "airMicrobialSampleSet",
          "dustDensitySample",
          "surfaceMicrobialSampleSet",
          "waterSample",
        ]}
      >
        <PersistenceContextProvider>
          <OfflineStateWrapper>
            <ErrArtefactNotReadyContextProvider>
              <WorkOrderPage workOrderId={workOrderId!} />
            </ErrArtefactNotReadyContextProvider>
          </OfflineStateWrapper>
        </PersistenceContextProvider>
      </StaleContextProvider>
    </OfflineContextProvider>
  );
}

function OfflineStateWrapper({ children }: { children: ReactNode }) {
  const { offlineState } = useOfflineContext();

  // While preparing for offline, or syncing, there may be no
  // valid values available from indexedDB or the backend.
  // Therefore, explicit indicators are shown. This also allows
  // all children to rely on the two states ONLINE and OFFLINE
  // only. Not catching PREPARING_OFFLINE and SYNCING here would
  // lead to significant complexity in the children.
  switch (offlineState) {
    case OfflineState.ONLINE:
    case OfflineState.OFFLINE:
      return children;
    case OfflineState.PREPARING_OFFLINE:
      return <PreparingOfflineCard />;
    case OfflineState.SYNCING:
      return <SynchronizingCard />;
    default:
      return null;
  }
}

function WorkOrderPage({ workOrderId }: { workOrderId: string }) {
  const { data: workOrder, isLoading, error } = useGetWorkOrder(workOrderId);
  const { notReadyError } = useErrArtefactNotReady();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  const hasDataError = hasFieldError(
    notReadyError,
    "beginDate",
    "endDate",
    "teamLeader",
    "address",
    "representative",
  );
  const hasPosError = hasFieldError(notReadyError, "position");

  // The offline hook can have a cycle where the work order is
  // not yet available, but isLoading is already false.
  // This will be fixed, until then, we need this check.
  if (!workOrder) {
    return null;
  }

  const links = [
    { to: "data", hasValidationError: hasDataError, label: t("Arbeitsschein") },
    {
      to: "positions",
      hasValidationError: hasPosError,
      label: t("Leistungen"),
    },
    { to: "laboratory", label: t("Labor") },
  ];

  return (
    <ArtefactPage>
      <ArtefactImmutableAlert artefact={workOrder!} />
      <ArtefactPageHeader>
        <ArtefactPageNavigation>
          {links.map((link) => (
            <ArtefactPageNavigationLink
              to={link.to}
              hasValidationError={link.hasValidationError}
            >
              {link.label}
            </ArtefactPageNavigationLink>
          ))}
        </ArtefactPageNavigation>
        <ArtefactPageActions>
          {!workOrder?.immutable && (
            <>
              <OfflineButton workOrderId={workOrder!.id} />
              <PersistenceStateUsingContext className="mr-2" />
              <SignatureButton workOrderId={workOrder!.id} />
              <DeleteWorkOrderDialog workOrderId={workOrder!.id} />
            </>
          )}
          <WorkOrderPdf workOrder={workOrder!} />
        </ArtefactPageActions>
      </ArtefactPageHeader>
      <ArtefactPageContent>
        <ArtefactPageNavigationOutlet />
      </ArtefactPageContent>
    </ArtefactPage>
  );
}

function PreparingOfflineCard() {
  const { synced } = useOfflineContext();
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Preparing Offline State")}</CardTitle>
      </CardHeader>
      <CardContent>
        <table>
          <tbody>
            {synced.map((item, index) => (
              <tr key={item}>
                <td className="p-2">
                  {index === synced.length - 1 ? (
                    <RefreshCw className="animate-spin" />
                  ) : (
                    <Check className="text-green-700" />
                  )}
                </td>
                <td className="p-2">{item}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
}

function SynchronizingCard() {
  const { synced } = useOfflineContext();
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Synchronisiere den Offline Status...")}</CardTitle>
      </CardHeader>
      <CardContent>
        <table>
          <tbody>
            {synced.map((item, index) => (
              <tr key={item}>
                <td className="p-2">
                  {index === synced.length - 1 ? (
                    <RefreshCw className="animate-spin" />
                  ) : (
                    <Check className="text-green-700" />
                  )}
                </td>
                <td className="p-2">{item}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
}
