import { Report } from "@/services/backend/htz/report/report";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { Photo } from "@/services/backend/htz/inspection/photo";
import { useHtzWorkOrderPhotoDocumentationShowImageQuery } from "@/services/backend/htz/work-order/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { AirHandlingUnitComponentName } from "@/shared/components/domain/htz/ahu/air-handling-unit-name";

export function PhotosComponent({ report }: { report: Report }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Fotos")}</CardTitle>
        <CardDescription>{t("Fotos aus dem Arbeitsschein.")}</CardDescription>
      </CardHeader>
      <CardContent className="grid grid-cols-3 gap-4">
        {report.photos.map((photo) => (
          <PhotoComponent photo={photo} key={photo.id} />
        ))}
      </CardContent>
    </Card>
  );
}

function PhotoComponent({ photo }: { photo: Photo }) {
  const {
    data: photoData,
    isLoading,
    error,
  } = useHtzWorkOrderPhotoDocumentationShowImageQuery({
    fileId: photo.fileId!,
  });

  if (isLoading) {
    return <div>{t("Lade Foto...")}</div>;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <div className="space-y-3">
      <img src={photoData!.objectURL} alt="Foto" />
      <div className="space-y-1 text-sm">
        <h3 className="font-medium leading-none">
          <AirHandlingUnitComponentName
            id={photo.airHandlingUnitId}
            componentId={photo.componentId}
          />
        </h3>
        <p className="text-xs text-muted-foreground">{photo.comment}</p>
      </div>
    </div>
  );
}
