import { Label } from "@/shared/components/ui/label";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";

interface BooleanSelectProps {
  label: string;
  field: string;
  value: boolean;
  onValueChange: (value: boolean) => void;
  errors: string[] | null;
  placeholder?: string;
  disabled?: boolean;
}

export function BooleanSelect({
  label,
  field,
  value,
  onValueChange,
  errors,
  placeholder,
  disabled,
}: BooleanSelectProps) {
  const stringValue = value ? "true" : "false";
  const stringToBoolean = (v: string) => onValueChange(v === "true");

  return (
    <div className="grid content-start gap-1.5">
      <Label htmlFor={field}>{label}</Label>
      <Select
        disabled={disabled}
        value={stringValue}
        onValueChange={stringToBoolean}
      >
        <SelectTrigger
          id={field}
          className={
            errors && errors.length > 0 ? "border-red-500 shadow-md" : ""
          }
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="true">Ja</SelectItem>
            <SelectItem value="false">Nein</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      <InputErrorMessages errors={errors ?? []} />
    </div>
  );
}
