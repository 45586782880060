import { Report } from "@/services/backend/htz/report/report";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import {
  AttachmentDropdownInput,
  AttachmentList,
  AttachmentListItem,
  AttachmentName,
  Attachments,
  AttachmentSheet,
} from "@/shared/service-manager/attachment/attachment-components";
import { Button } from "@/shared/components/ui/button";
import {
  useHtzReportDeleteAttachmentMutation,
  useHtzReportShowAttachmentQuery,
  useHtzReportUploadAttachmentMutation,
} from "@/services/backend/htz/report/service";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { Attachment } from "@/shared/service-manager/attachment/attachment";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Trash2 } from "lucide-react";

export function AttachmentsCard({ report }: { report: Report }) {
  const [patch, { isLoading, error, reset }] =
    useHtzReportUploadAttachmentMutation();

  const { toast } = useToast();
  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  const doUpload = (file: File) => {
    if (isLoading) return;

    patch({
      id: report.id!,
      name: file.name,
      data: file,
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Anhänge")}</CardTitle>
        <CardDescription>
          {t(
            "Hier können Dokumente zum Bericht abgelegt werden, welche ergänzende Informationen enthalten, z.B. Laborberichte.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Attachments>
          <AttachmentDropdownInput
            onChange={doUpload}
            isLoading={isLoading}
            accept="application/pdf"
            disabled={report.immutable}
          />
          <AttachmentList>
            {report!.attachments?.map((attachment) => (
              <Item
                key={attachment.id}
                reportId={report.id!}
                attachment={attachment}
              />
            ))}
          </AttachmentList>
        </Attachments>
      </CardContent>
    </Card>
  );
}

function Item({
  reportId,
  attachment,
}: {
  reportId: string;
  attachment: Attachment;
}) {
  const { data: attachmentResponse } = useHtzReportShowAttachmentQuery({
    id: reportId,
    attachmentId: attachment.id!,
  });

  return (
    <AttachmentListItem>
      <AttachmentName>{attachment.name}</AttachmentName>
      {attachmentResponse && (
        <AttachmentSheet attachment={attachmentResponse} />
      )}
      <DeleteAttachmentDialog reportId={reportId} attachment={attachment} />
    </AttachmentListItem>
  );
}

function DeleteAttachmentDialog({
  reportId,
  attachment,
}: {
  reportId: string;
  attachment: Attachment;
}) {
  const [doDelete, { isLoading, error, reset }] =
    useHtzReportDeleteAttachmentMutation();

  const { toast } = useToast();
  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="destructive" size="sm">
          <Trash2 className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Anhang wirklich löschen?")}</DialogTitle>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button
            variant="destructive"
            onClick={() => {
              doDelete({ id: reportId, attachmentId: attachment.id! });
            }}
            disabled={isLoading}
          >
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
