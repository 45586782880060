import { ComplementaryServiceComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/additional-information/complementary-service-component";
import { TravelInfoComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/additional-information/travel-info-component";
import { PestsComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/additional-information/pests-component";
import { CycleComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/additional-information/cycle-component";
import { CertificatesComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/additional-information/certificates-component";
import { AdditionalCommentsComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/additional-information/additional-comments-component";
import {
  useQpmRequirementAdditionalInformationAddMutation,
  useQpmRequirementAdditionalInformationGetQuery,
  useQpmRequirementGetQuery,
} from "@/services/backend/qpm/requirements/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  AddRequirementAdditionalInformationRequest,
  Requirement,
  RequirementAdditionalInformation,
} from "@/services/backend/qpm/requirements/types";
import { useParams } from "react-router-dom";
import { URLParams } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/create-requirement";
import { useCallback, useEffect, useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { Loader } from "lucide-react";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";

export function AdditionalInfoRoute() {
  const { processId, requirementId } = useParams<URLParams>();

  const {
    data: process,
    error: processDataError,
    isLoading: processDataIsLoading,
  } = useProcessesProcessShowQuery({
    id: processId ?? "",
  });

  const {
    data: requirement,
    error: errorRequirement,
    isLoading: isLoadingRequirement,
  } = useQpmRequirementGetQuery({
    artefactMetaId: requirementId ?? "",
  });

  const { data: dataAdditionalInfo, error: errorAdditionalInformation } =
    useQpmRequirementAdditionalInformationGetQuery(
      {
        artefactMetaId: requirement!.artefactData.id,
      },
      { skip: !requirement },
    );

  if (processDataIsLoading || isLoadingRequirement) {
    return <Loader className="animate-pulse" />;
  }

  if (processDataError || !process) {
    return <div>Fehler beim Laden der Vorgangsdaten</div>;
  }

  if (errorRequirement) {
    return <RTKQueryErrorAlert error={errorRequirement} />;
  }

  if (errorAdditionalInformation) {
    return <RTKQueryErrorAlert error={errorAdditionalInformation} />;
  }

  return (
    dataAdditionalInfo &&
    requirement &&
    process && (
      <AdditionalInformationComponent
        customerId={process.customerId}
        requirement={requirement}
        additionalInformation={dataAdditionalInfo}
      />
    )
  );
}

export type AdditionalInformationProps = {
  customerId: string;
  requirement: Requirement;
  additionalInformation: RequirementAdditionalInformation;
};

export function AdditionalInformationComponent({
  customerId,
  requirement,
  additionalInformation,
}: AdditionalInformationProps) {
  const [updateAdditionalInfo, { error, isLoading, isSuccess, reset }] =
    useQpmRequirementAdditionalInformationAddMutation();

  const [additionalInfoState, setAdditionalInfoState] =
    useState<AddRequirementAdditionalInformationRequest>({
      ...additionalInformation,
    });

  useEffect(() => {
    if (additionalInformation) {
      setAdditionalInfoState(additionalInformation);
    }
  }, [additionalInformation]);

  const updateAdditionalInfoState = useCallback(
    (updatedInfo: Partial<RequirementAdditionalInformation>) => {
      setAdditionalInfoState((prev) => ({
        ...prev,
        ...updatedInfo,
      }));
    },
    [],
  );

  useDebouncedMutationWithPersistenceStateContextUpdate(
    additionalInfoState,
    updateAdditionalInfo,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
    { toastError: true },
  );

  if (!additionalInfoState) {
    return <div>Fehler beim Laden der zusätlichen Informationen.</div>;
  }

  return (
    <div className="flex flex-col">
      <ComplementaryServiceComponent
        additionalInformation={additionalInfoState}
        onUpdate={updateAdditionalInfoState}
      />
      <TravelInfoComponent
        additionalInformation={additionalInfoState}
        onUpdate={updateAdditionalInfoState}
      />
      <PestsComponent requirement={requirement} />
      <CycleComponent
        additionalInformation={additionalInfoState}
        onUpdate={updateAdditionalInfoState}
      />
      <CertificatesComponent
        additionalInformation={additionalInfoState}
        customerId={customerId}
        onUpdate={updateAdditionalInfoState}
      />
      <AdditionalCommentsComponent
        additionalInformation={additionalInfoState}
        onUpdate={updateAdditionalInfoState}
      />
    </div>
  );
}
