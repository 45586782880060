import {
  Label,
  useProcessesStateForceRunningMutation,
  useProcessesStatePutMutation,
  useProcessesStateShowQuery,
} from "@/services/backend/processes/state/state";
import { AlertTriangle } from "lucide-react";
import t from "@/lang/lang";
import { Button } from "@/shared/components/ui/button";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useGuard } from "@/shared/lib/authorization/rbac-guard";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";

export function StateWarning({ processId }: { processId: string }) {
  const { data: state } = useProcessesStateShowQuery({ processId });

  if (!state) {
    return null;
  }

  switch (state.state) {
    case Label.Unknown:
    case Label.Running:
      return null;
    case Label.Archived:
      return <ArchivedWarning processId={processId} />;
    case Label.Done:
      return <DoneWarning processId={processId} />;
    default:
      return null;
  }
}

function ArchivedWarning({ processId }: { processId: string }) {
  const [put, { isLoading, error }] = useProcessesStatePutMutation();

  const onClick = () => {
    if (isLoading) {
      return;
    }

    put({
      processId,
      state: Label.Running,
    });
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="border-destructive text-destructive"
          size="sm"
        >
          <AlertTriangle className="h-5 w-5" />
          {t("Vorgang archiviert")}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex items-center gap-4">
            <AlertTriangle className="h-5 w-5" />
            <span>{t("Der Vorgang ist archiviert.")}</span>
          </DialogTitle>
          <DialogDescription>
            <span>
              {t(
                'Der Vorgang ist archiviert, kann aber wieder in den Stand "In Bearbeitung" gesetzt werden.',
              )}
            </span>
          </DialogDescription>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={onClick} disabled={isLoading}>
            {t('Vorgang in Stand "In Bearbeitung" setzen')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function DoneWarning({ processId }: { processId: string }) {
  const [forceRunning, { isLoading, error }] =
    useProcessesStateForceRunningMutation();

  const { canExecute } = useGuard("Processes.State.ForceRunningRequest");

  const onClick = () => {
    if (isLoading) {
      return;
    }

    forceRunning({ processId });
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="border-destructive text-destructive"
          size="sm"
        >
          <AlertTriangle className="h-5 w-5" />
          {t("Vorgang beendet")}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex items-center gap-4">
            <AlertTriangle className="h-5 w-5" />
            <span>{t("Der Vorgang ist beendet.")}</span>
          </DialogTitle>
          <DialogDescription>
            <span>
              {t(
                "Der Vorgang ist beendet und darf nicht mehr bearbeitet werden. Bitte erstellen Sie einen neuen Vorgang.",
              )}
            </span>
          </DialogDescription>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        {canExecute && (
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline">{t("Abbrechen")}</Button>
            </DialogClose>
            <Button onClick={onClick} disabled={isLoading}>
              {t('Stand "In Bearbeitung" erzwingen')}
            </Button>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
}
