import { useParams } from "react-router-dom";
import { useHtzReportShowQuery } from "@/services/backend/htz/report/service";
import { CardSkeleton } from "@/shared/components/ui/card-skeleton";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { AirMicrobialSamplesTable } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/microbial/_components/air-microbial-samples-table";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { H4 } from "@/shared/components/ui/typography";
import { TransferLabResultsDialog } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/microbial/_components/transfer-lab-results-dialog";
import { SurfaceMicrobialSamplesTable } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/microbial/_components/surface-microbial-samples-table";
import { DustDensitySamplesTable } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/microbial/_components/dust-density-samples-table";
import { WaterSamplesTable } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/microbial/_components/water-samples-table";

export function MicrobialRoute() {
  const { reportId } = useParams();

  const {
    data: report,
    isLoading,
    error,
  } = useHtzReportShowQuery({ id: reportId! });

  if (isLoading) {
    return <CardSkeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Card>
      <CardHeader className="relative">
        <CardTitle>{t("Proben")}</CardTitle>
        <div className="absolute right-6 top-5">
          <TransferLabResultsDialog report={report!} />
        </div>
        <CardDescription>
          {t(
            "Die Ergebnisse der Proben können entweder aus den Schnittstellen der Labore abgefragt werden oder manuell eingetragen.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent className="pl-3">
        <H4 className="pl-3">{t("Luftkeimproben")}</H4>
        <AirMicrobialSamplesTable report={report!} />
        <H4 className="pl-3 pt-3">{t("Oberflächenkeimproben")}</H4>
        <SurfaceMicrobialSamplesTable report={report!} />
        <H4 className="pl-3 pt-3">{t("Wasserproben")}</H4>
        <WaterSamplesTable report={report!} />
        <H4 className="pl-3 pt-3">{t("Straubdichtemessungen")}</H4>
        <DustDensitySamplesTable report={report!} />
      </CardContent>
    </Card>
  );
}
