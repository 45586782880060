import { Report } from "@/services/backend/htz/report/report";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { Kind } from "@/services/backend/samples/sample/kind";
import {
  useSamplesAirMicrobialSampleSetPutMutation,
  useSamplesAirMicrobialSampleSetShowQuery,
} from "@/services/backend/samples/sample/air-microbial-service";
import { AirMicrobialSampleSet } from "@/services/backend/samples/sample/air-microbial-sample";
import { useState } from "react";
import { Input } from "@/shared/components/ui/input";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { LaboratorySelect } from "@/shared/components/domain/samples/laboratory-select";
import { MicrobialAssessment } from "@/services/backend/htz/inspection/microbial-assessment";
import { AssessmentResultSelect } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/microbial/_components/assessment-result-select";
import { MoldDifferentiation } from "@/services/backend/samples/sample/mold-differentiation";
import { Button } from "@/shared/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { PlusCircle, Trash2 } from "lucide-react";

export function AirMicrobialSamplesTable({ report }: { report: Report }) {
  const assessments = report.microbialAssessments.filter(
    (assessment) => assessment.kind === Kind.AirMicrobial,
  );

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="pl-3">{t("Nummer")}</TableHead>
          <TableHead>{t("Labor")}</TableHead>
          <TableHead className="w-36">{t("Einheit")}</TableHead>
          <TableHead className="w-28">{t("Caso")}</TableHead>
          <TableHead className="w-28">{t("Malz")}</TableHead>
          <TableHead className="w-28">{t("DG18")}</TableHead>
          <TableHead className="w-28">{t("Gesamt")}</TableHead>
          <TableHead className="w-28">{t("Schimmelpilze")}</TableHead>
          <TableHead className="w-28">{t("Ergebnis")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {assessments.map(
          (assessment) =>
            assessment.sampleId && (
              <SampleRowLoader
                key={assessment.id}
                report={report}
                assessment={assessment}
              />
            ),
        )}
        {assessments.length === 0 && (
          <TableRow>
            <TableCell colSpan={7} className="text-center">
              {t("Keine Luftkeimproben")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

function SampleRowLoader({
  report,
  assessment,
}: {
  report: Report;
  assessment: MicrobialAssessment;
}) {
  const {
    data: sample,
    isLoading,
    error,
  } = useSamplesAirMicrobialSampleSetShowQuery({
    id: assessment.sampleId ?? "",
  });

  if (isLoading) {
    return null;
  }

  if (error) {
    return null;
  }

  return <SampleRow report={report} assessment={assessment} sample={sample!} />;
}

function SampleRow({
  report,
  assessment,
  sample,
}: {
  report: Report;
  assessment: MicrobialAssessment;
  sample: AirMicrobialSampleSet;
}) {
  const [request, setRequest] = useState({ ...sample });
  const [put, { isLoading, error, isSuccess, reset }] =
    useSamplesAirMicrobialSampleSetPutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  return (
    <TableRow className="border-b-0">
      <TableCell className="py-1.5 pl-3">{sample!.number}</TableCell>
      <TableCell className="py-1.5">
        <LaboratorySelect
          labId={sample!.labId}
          onLabIdChange={(labId) => setRequest({ ...request, labId })}
          className="h-8"
          disabled={report.immutable}
        />
      </TableCell>
      <TableCell className="py-1.5">
        <Input
          type="text"
          className="h-8"
          value={request.unit}
          onChange={(e) => setRequest({ ...request, unit: e.target.value })}
          disabled={report.immutable}
        />
      </TableCell>
      <TableCell className="py-1.5">
        {sample!.caso ? (
          <Input
            type="text"
            className="h-8"
            value={request.caso?.value}
            onChange={(e) =>
              setRequest({
                ...request,
                caso: { ...request.caso!, value: e.target.value },
              })
            }
            disabled={report.immutable}
          />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell className="py-1.5">
        {sample!.malt ? (
          <Input
            type="text"
            className="h-8"
            value={request.malt?.value}
            onChange={(e) =>
              setRequest({
                ...request,
                malt: { ...request.malt!, value: e.target.value },
              })
            }
            disabled={report.immutable}
          />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell className="py-1.5">
        {sample!.dg18 ? (
          <Input
            type="text"
            className="h-8"
            value={request.dg18?.value}
            onChange={(e) =>
              setRequest({
                ...request,
                dg18: { ...request.dg18!, value: e.target.value },
              })
            }
            disabled={report.immutable}
          />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell className="py-1.5">
        <Input
          type="text"
          className="h-8"
          value={request.value}
          onChange={(e) => setRequest({ ...request, value: e.target.value })}
          disabled={report.immutable}
        />
      </TableCell>
      <TableCell className="py-1.5">
        <MoldDifferentiationDialog
          moldDifferentiations={request.moldDifferentiation}
          onMoldDifferentiationsChange={(moldDifferentiation) =>
            setRequest({ ...request, moldDifferentiation })
          }
          disabled={report.immutable}
        />
      </TableCell>
      <TableCell className="py-1.5">
        <AssessmentResultSelect
          report={report}
          assessment={assessment}
          disabled={report.immutable}
          errorKey={`microbialAssessments.${assessment.id}.result`}
        />
      </TableCell>
    </TableRow>
  );
}

export function MoldDifferentiationDialog({
  moldDifferentiations,
  onMoldDifferentiationsChange,
  disabled,
}: {
  moldDifferentiations: MoldDifferentiation[];
  onMoldDifferentiationsChange: (
    moldDifferentiations: MoldDifferentiation[],
  ) => void;
  disabled: boolean;
}) {
  const add = () => {
    onMoldDifferentiationsChange([
      ...moldDifferentiations,
      { description: "", value: "", unit: "", overgrown: false },
    ]);
  };

  const indexedMoldDifferentiations: (MoldDifferentiation & { id: number })[] =
    moldDifferentiations.map((m, i) => ({
      ...m,
      id: i,
    }));

  const update = (
    moldDifferentiation: MoldDifferentiation & { id: number },
  ) => {
    const newMoldDifferentiations = indexedMoldDifferentiations.map((m) =>
      m.id === moldDifferentiation.id ? moldDifferentiation : m,
    );
    onMoldDifferentiationsChange(newMoldDifferentiations);
  };

  const remove = (
    moldDifferentiation: MoldDifferentiation & { id: number },
  ) => {
    const newMoldDifferentiations = indexedMoldDifferentiations.filter(
      (m) => m.id !== moldDifferentiation.id,
    );
    onMoldDifferentiationsChange(newMoldDifferentiations);
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm" className="h-8">
          {t("Differenzierung")}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle>{t("Schimmelpilzdifferenzierung")}</DialogTitle>
        </DialogHeader>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("Pilz")}</TableHead>
              <TableHead className="w-28">{t("Wert")}</TableHead>
              <TableHead className="w-40">{t("Einheit")}</TableHead>
              <TableHead>{t("Überwachsen")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {indexedMoldDifferentiations.map((moldDifferentiation) => (
              <TableRow key={moldDifferentiation.id}>
                <TableCell className="py-1.5">
                  <Input
                    type="text"
                    className="h-8"
                    value={moldDifferentiation.description}
                    onChange={(e) =>
                      update({
                        ...moldDifferentiation,
                        description: e.target.value,
                      })
                    }
                    disabled={disabled}
                  />
                </TableCell>
                <TableCell className="py-1.5">
                  <Input
                    type="text"
                    className="h-8"
                    value={moldDifferentiation.value}
                    onChange={(e) =>
                      update({ ...moldDifferentiation, value: e.target.value })
                    }
                    disabled={disabled}
                  />
                </TableCell>
                <TableCell className="py-1.5">
                  <Input
                    type="text"
                    className="h-8"
                    value={moldDifferentiation.unit}
                    onChange={(e) =>
                      update({ ...moldDifferentiation, unit: e.target.value })
                    }
                    disabled={disabled}
                  />
                </TableCell>
                <TableCell className="py-1.5">
                  <div className="flex h-8 items-center">
                    <Checkbox
                      checked={moldDifferentiation.overgrown}
                      onCheckedChange={(overgrown) =>
                        update({
                          ...moldDifferentiation,
                          overgrown: Boolean(overgrown),
                        })
                      }
                      disabled={disabled}
                    />
                  </div>
                </TableCell>
                <TableCell className="py-1.5 text-right">
                  <Button
                    size="sm"
                    variant="destructive"
                    onClick={() => remove(moldDifferentiation)}
                  >
                    <Trash2 className="h-5 w-5" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <DialogFooter className="pr-4">
          <Button size="sm" onClick={add}>
            <PlusCircle className="mr-2 h-5 w-5" />
            <span>{t("Schimmelpilz")}</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
