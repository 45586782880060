import {
  useQpmRequirementGetQuery,
  useQpmRequirementListQuery,
} from "@/services/backend/qpm/requirements/service";
import { FilterMode } from "@/shared/lib/filter/filter";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Skeleton } from "@/shared/components/ui/skeleton";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { Requirement } from "@/services/backend/qpm/requirements/types";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { formatDate, formatTime } from "@/shared/lib/utilities/date";
import { useQpmCalculationLoadRequirementMutation } from "@/services/backend/qpm/calculations/service";
import {
  Calculation,
  LoadRequirementRequest,
} from "@/services/backend/qpm/calculations/types";
import {
  ArtefactMeta,
  ArtefactMetaList,
} from "@/services/backend/qpm/artefact-meta/types";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { Loader2 } from "lucide-react";
import { ReactNode } from "react";
import { Button } from "@/shared/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { CreateFromRequirement } from "@/routes/gemex/processes/qpm/calculations/_components/create-from-requirement";

export type SelectRequirementComponentProps = {
  calculation: Calculation;
  customerId: string;
  processId: string;
};

export function SelectRequirementComponent({
  calculation,
  customerId,
  processId,
}: SelectRequirementComponentProps) {
  const {
    data: list,
    isLoading,
    error,
  } = useQpmRequirementListQuery({
    ownerType: {
      active: false,
      values: [],
    },
    process: {
      active: true,
      values: [processId!],
    },
    owner: {
      active: true,
      mode: FilterMode.IsNull,
      values: [],
    },
    customer: {
      active: true,
      values: [customerId],
    },
    immutable: {
      active: false,
      values: [],
    },
    parent: {
      active: true,
      mode: FilterMode.IsNull,
      values: [],
    },
  });

  const artefactMetaId =
    calculation?.artefactData?.child?.artefact?.linkedArtefacts?.requirement
      ?.linkedArtefact?.id;

  const skipQuery = !artefactMetaId;

  const {
    data: selectedRequirement,
    isLoading: isLoadingSelectedRequirement,
    error: errorSelectedRequirement,
  } = useQpmRequirementGetQuery({ artefactMetaId }, { skip: skipQuery });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (errorSelectedRequirement) {
    return <RTKQueryErrorAlert error={errorSelectedRequirement} />;
  }

  if (isLoading || isLoadingSelectedRequirement) {
    return (
      <div className="mb-2">
        <Skeleton className="h-32 w-full" />
      </div>
    );
  }

  return (
    <div className="mb-2">
      <Card>
        <CardHeader>
          <CardTitle>{t("Bestandsaufnahmen")}</CardTitle>
          <CardDescription>
            {t(
              "Wählen Sie hier die Bestandsaufnahme, auf Basis derer Sie die Kalkulation erstellen möchten.",
            )}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="w-full">
            <RequirementSelector
              calculationArtefactMetaId={calculation.artefactData.id}
              requirementList={list!}
              selectedRequirement={selectedRequirement}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

type RequirementDropDownProps = {
  calculationArtefactMetaId: string;
  requirementList: ArtefactMetaList;
  selectedRequirement?: Requirement;
};

function RequirementSelector({
  calculationArtefactMetaId,
  requirementList,
  selectedRequirement,
}: RequirementDropDownProps) {
  const [loadRequirementData, { error, isLoading }] =
    useQpmCalculationLoadRequirementMutation();

  const onChange = (value: string) => {
    const requirement = requirementList.artefacts.find(
      (req) => req.id === value,
    );
    if (!requirement || isLoading || error) {
      return;
    }
    const request: LoadRequirementRequest = {
      calculationArtefactId: calculationArtefactMetaId,
      requirementArtefactId: requirement.id,
    };

    loadRequirementData(request);
  };

  return (
    <Select
      defaultValue={selectedRequirement?.artefactData.id}
      value={selectedRequirement?.artefactData.id}
      onValueChange={(value: string) => {
        onChange(value);
      }}
    >
      <SelectTrigger className="w-full">
        <SelectValue placeholder={t("Bestandsaufnahmen")} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {requirementList.artefacts.map((requirement) => (
            <SelectItem key={requirement.id} value={requirement.id}>
              <RequirementDisplayRow requirement={requirement} />
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

export type SelectRequirementButtonComponentProps = {
  calculationId?: string;
  processId: string;
  selectedRequirementId?: string;
};

export function SelectRequirementButtonComponent({
  calculationId,
  processId,
  selectedRequirementId,
}: SelectRequirementButtonComponentProps) {
  const {
    data: selectedRequirement,
    isLoading: isLoadingSelectedRequirement,
    error: errorSelectedRequirement,
  } = useQpmRequirementGetQuery(
    { artefactMetaId: selectedRequirementId! },
    { skip: !selectedRequirementId },
  );

  if (errorSelectedRequirement) {
    return <RTKQueryErrorAlert error={errorSelectedRequirement} />;
  }

  if (isLoadingSelectedRequirement) {
    return <Loader2 className="animate-spin" />;
  }

  return (
    <SelectArtefactButton
      selectedArtefactId={selectedRequirement?.artefactData.id}
    >
      <CreateFromRequirement
        calculationId={calculationId}
        processId={processId}
      />
    </SelectArtefactButton>
  );
}

export type SelectArtefactButtonProps = {
  selectedArtefactId?: string;
  defaultButtonText?: string;
  children?: ReactNode;
};

export function SelectArtefactButton({
  selectedArtefactId,
  defaultButtonText,
  children,
}: SelectArtefactButtonProps) {
  if (selectedArtefactId && selectedArtefactId !== "") {
    return (
      <Button size="sm" variant="outline" disabled onClick={() => {}}>
        <ArtefactNumberById
          className="text-sm"
          artefactId={selectedArtefactId}
        />
      </Button>
    );
  }

  return (
    <Dialog>
      <DialogTrigger className="h-9 rounded-md border border-input bg-background px-3 hover:bg-accent hover:text-accent-foreground">
        {(defaultButtonText && t(defaultButtonText)) ||
          t("-- nicht gewählt --")}
      </DialogTrigger>
      <DialogContent className="max-h-[80vh] min-w-[80vw] overflow-y-scroll">
        {children}
      </DialogContent>
    </Dialog>
  );
}

type RequirementDisplayRowProps = {
  requirement: ArtefactMeta;
};

function RequirementDisplayRow({ requirement }: RequirementDisplayRowProps) {
  return (
    <div className="grid grid-cols-2">
      <div className="flex items-center">
        <div className="border-r px-2">
          <ArtefactNumberById className="text-sm" artefactId={requirement.id} />
        </div>
        <div className="border-r px-2">
          <span>{requirement.subject}</span>
        </div>
      </div>
      <div className="flex items-center">
        <div className="px-2">
          <span>{formatDate(requirement.createdAt)}</span>
        </div>
        <div className="px-2">
          <span>{formatTime(requirement.createdAt)}</span>
        </div>
      </div>
    </div>
  );
}
