import { useParams } from "react-router-dom";
import { useContactsDefaultContactListQuery } from "@/services/backend/contacts/default-contact/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import t from "@/lang/lang";
import { Card, CardDescription, CardHeader } from "@/shared/components/ui/card";
import {
  ContactInfoCard,
  ContactInfoCardSkeleton,
} from "@/shared/components/domain/contacts/contact-info-card";
import {
  MilestonePage,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/service-manager/default-ui/milestone-page/milestone-page";

export default function ContactRoute() {
  const { processId } = useParams();
  const {
    data: contacts,
    isLoading,
    error,
  } = useContactsDefaultContactListQuery({ processId: processId! });

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Ansprechpartner")}</MilestonePageTitle>
      </MilestonePageHeader>
      <MilestonePageContent>
        {error && <RTKQueryErrorAlert error={error} />}
        <div className="grid w-fit grid-cols-1 gap-4 lg:grid-cols-2">
          {isLoading && <ContactInfoCardSkeleton />}
          {contacts?.contacts?.map((contact) => (
            <ContactInfoCard key={contact.id} contact={contact} />
          ))}
          {contacts?.contacts?.length === 0 && (
            <Card>
              <CardHeader>
                <CardDescription>
                  {t("Keine Ansprechpartner gefunden")}
                </CardDescription>
              </CardHeader>
            </Card>
          )}
        </div>
      </MilestonePageContent>
    </MilestonePage>
  );
}
