import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useParams } from "react-router-dom";
import {
  fieldErrorsForPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import {
  useRltOrderShowQuery,
  useRltOrderUpdatePostalAddressMutation,
  useRltOrderUpdateServiceAddressMutation,
} from "@/services/backend/rlt/orders/service";
import { Card } from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { PostalAddressCardWithMutation } from "@/shared/components/domain/addresses/postal-address-card";
import { ServiceAddressCardWithMutation } from "@/shared/components/domain/addresses/service-address-card";

export function OrderCustomerAddressRoute() {
  const { orderId } = useParams();
  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();
  const {
    data: order,
    isLoading,
    error,
  } = useRltOrderShowQuery({
    id: orderId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    <div className="space-y-2">
      <PostalAddressCardWithMutation
        id={order!.id}
        address={order!.postalAddress}
        mutation={useRltOrderUpdatePostalAddressMutation}
        fieldErrors={fieldErrorsForPrefix(notReadyError, "postalAddress.")}
        resetFieldError={(field) =>
          resetNotReadyErrorField(`postalAddress.${field}`)
        }
      />
      <ServiceAddressCardWithMutation
        id={order!.id}
        address={order!.serviceAddress}
        mutation={useRltOrderUpdateServiceAddressMutation}
        fieldErrors={fieldErrorsForPrefix(notReadyError, "serviceAddress.")}
        resetFieldError={(field) =>
          resetNotReadyErrorField(`serviceAddress.${field}`)
        }
      />
    </div>
  );
}
