import { Filter } from "@/shared/lib/filter/filter";
import {
  Monitor,
  MonitorList,
  MonitorRecord,
} from "@/services/backend/qpm/monitors/types";
import { Area, AreaList } from "@/services/backend/qpm/buildings/types";
import { ArtefactMeta } from "@/services/backend/qpm/artefact-meta/types";
import { MonitorTypeKey } from "@/services/backend/qpm/shared/enums";

export type Requirement = {
  artefactData: ArtefactMeta;
  areaList: AreaList;
  monitor: MonitorList;
  selectedAreas: RequirementAreaList;
  selectedMonitors: SelectedAreaMonitors;
};

export interface RequirementList {
  requirements: Requirement[];
  count: number;
}

export type SelectedMonitor = {
  areaId: string;
  monitorId: string;
  monitor: MonitorRecord;
  amount: number;
  amountOfMeasures: number;
  existingStockAmount?: number;
  comment: string;
  position: string;
};

export type SelectedAreaMonitors = {
  monitors: { [areaId: string]: AreaMonitor[] };
};

export type SelectedMonitorsList = {
  monitors: SelectedMonitor[];
};

export type MonitorSummary = {
  monitor: Monitor;
  totalAmount: number;
  totalInstallationTime: number;
  totalServiceTime: number;
};

export type SelectedMonitorSummary = {
  artefactMetaId: string;
  monitorSummaryList: MonitorSummaryList;
  typeSummary: { [key in MonitorTypeKey]: number };
  totalInstallationTime: number;
  totalServiceTime: number;
};

export type MonitorSummaryList = {
  monitors: MonitorSummary[];
};

export type AreaMonitor = MonitorRecord & {
  amount: number;
  amountOfMeasures: number;
  existingStockAmount?: number;
  comment: string;
  position: string;
};

export type QpmRequirementServiceProperties = {
  artefactMetaId: string;
  properties: [
    {
      property: { id: string };
      value: number;
    },
  ];
};

export type CreateRequirementRequest = {
  artefactMetaId: string;
  processId: string;
  subject?: string;
  description?: string;
};

export type UpdateRequirementRequest = {
  artefactMeta: ArtefactMeta;
  subject?: string;
  description?: string;
};

export type GetRequirementRequest = {
  artefactMetaId: string;
};

export type ListRequirementRequest = {
  process: Filter<string>;
  customer: Filter<string>;
  immutable: Filter<boolean>;
  owner: Filter<string>;
  ownerType: Filter<string>;
  parent: Filter<string>;
};

export type DeleteRequest = {
  id: string;
};

export type SelectMonitorRequest = {
  monitorId: string;
  artefactMetaId: string;
  areaId: string;
  amount: number;
  comment: string;
  position: string;
};

export type GetSelectedMonitorSummaryRequest = {
  artefactMetaId: Filter<string>;
  monitorTypeKey: Filter<MonitorTypeKey>;
  buildingId: Filter<string>;
  levelId: Filter<string>;
};

export type UpdateRequirementMonitorRequest = {
  monitorId: string;
  artefactMetaId: string;
  areaId: string;
  amount: number;
  amountOfMeasures: number;
  existingStockAmount?: number;
  comment: string;
};

export type DeleteRequirementMonitorRequest = {
  monitorId: string;
  artefactMetaId: string;
  areaId: string;
};
export type ListSelectedMonitorsRequest = {
  artefactMetaId: Filter<string>;
  monitorTypeKey: Filter<MonitorTypeKey>;
  buildingId: Filter<string>;
  levelId: Filter<string>;
  areaId: Filter<string>;
};

export type RequirementArea = {
  artefactMetaId: string;
  areaId: string;
  amountOfMeasures: number;
  area: Area;
};

export type AddRequirementAreaRequest = {
  artefactMetaId: string;
  areaId: string;
};

export type RequirementAreaSetAmountOfMeasuresRequest = {
  artefactMetaId: string;
  areaId: string;
  amountOfMeasures: number;
};

export type RequirementAreaList = {
  requirementAreas: RequirementArea[];
};

export type DeleteRequirementAreaRequest = {
  artefactMetaId: string;
  areaId: string;
};

export type ListRequirementAreasRequest = {
  artefactMetaId: Filter<string>;
  buildingId: Filter<string>;
  levelId: Filter<string>;
};

export type ListByRequirementIdRequest = {
  artefactMetaId: string;
};

export type RequirementPest = {
  artefactMetaId: string;
  pestId: string;
};

export type RequirementPestList = RequirementPest[];

export type RequirementPestCategory = {
  id: string;
  artefactMetaId: string;
  pestCategoryId: string;
  pestPresent: boolean;
  name: string;
  position: boolean;
  requiredQpmCyclePerYear: number;
  type: string;
  immutable: boolean;
};

export type RequirementPestCategoryList = {
  pestCategories: RequirementPestCategory[];
};

export type AddRequirementPestRequest = {
  artefactMetaId: string;
  pestId: string;
};

export type DeleteRequirementPestRequest = {
  artefactMetaId: string;
  pestId: string;
};

export type GetRequirementPestListRequest = {
  artefactMetaId: string;
};

export type SetRequirementPestPresent = {
  id: string;
  pestPresent: boolean;
};

export type GetRequirementPestCategoryListRequest = {
  artefactMetaId: string;
};

export type DeleteRequirementPestCategoryRequest = {
  artefactMetaId: string;
  pestCategoryId: string;
};

export enum RequirementCycleType {
  None = "",
  InitialControl = "initial-control",
  InitialControlEradicationMonitoring = "initial-control-eradication-monitoring",
}

export type RequirementAdditionalInformation = {
  artefactMetaId: string;
  certificateNotes: string;
  additionalNotes: string;
  travelTime: number;
  travelCompany: string;
  cycleType: RequirementCycleType;
  qpmCycleAmountPerYear: number;
  aloraAmountOfMeasures: number;
  scbAmountOfMeasures: number;
  basicRefurbishmentMonthlyHours: number;
  redemptionControlTimeAfterWeeks: number;
  redemptionControlMonthlyHours: number;
  installationTime: number;
  maintenanceTime: number;
  rfidServiceTime: number;
  hygieneAuditTimesPerYear: number;
  hygieneAuditMonthlyHours: number;
};

export type AddRequirementAdditionalInformationRequest = {
  artefactMetaId: string;
  certificateNotes: string;
  additionalNotes: string;
  travelTime: number;
  travelCompany: string;
  cycleType: RequirementCycleType;
  qpmCycleAmountPerYear: number;
  aloraAmountOfMeasures: number;
  scbAmountOfMeasures: number;
  basicRefurbishmentMonthlyHours: number;
  redemptionControlTimeAfterWeeks: number;
  redemptionControlMonthlyHours: number;
  installationTime: number;
  maintenanceTime: number;
  rfidServiceTime: number;
  hygieneAuditTimesPerYear: number;
  hygieneAuditMonthlyHours: number;
};

export type GetRequirementAdditionalInformationRequest = {
  artefactMetaId: string;
};

export type DeleteRequirementAdditionalInformationRequest = {
  artefactMetaId: string;
};

export type RequirementAreaRisk = {
  artefactMetaId: string;
  areaId: string;
  riskLevelId: string;
  riskTypeId: string;
  detection: string;
  recommendation: string;
  measure: string;
};

export type AddRequirementAreaRiskRequest = {
  artefactMetaId: string;
  areaId: string;
  riskLevelId: string;
  riskTypeId: string;
  detection: string;
  recommendation: string;
  measure: string;
};

export type ListRequirementAreaRisks = {
  risks: RequirementAreaRisk[];
};

export type ListRequirementAreaRisksRequest = {
  artefactMetaId: Filter<string>;
  areaId: Filter<string>;
};

export type DeleteRequirementAreaRisksRequest = {
  artefactMetaId: string;
  areaId: string;
  riskTypeId: string;
};
