import { backendApi } from "../api";

const ugcMatchApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    showCompanyMatch: build.query<Match, ShowRequest>({
      query: (request) => ({
        url: `/dynamics/company-match`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result
          ? [{ type: "CompanyMatch", id: result.companyId }]
          : [{ type: "CompanyMatch", id: "LIST" }],
    }),
    putCompanyMatch: build.mutation<Match, PutRequest>({
      query: (request) => ({
        url: `/dynamics/company-match`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: (result, error, request) => [
        { type: "CompanyMatch", id: request.companyId },
        { type: "CompanyMatch", id: "LIST" },
      ],
    }),
    deleteCompanyMatch: build.mutation<void, DeleteRequest>({
      query: (request) => ({
        url: `/dynamics/company-match`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (result, error, request) => [
        { type: "CompanyMatch", id: request.companyId },
      ],
    }),
    listNewUnternehmensgruppe: build.query<
      UnternehmensgruppenList,
      ListRequest
    >({
      query: (request) => ({
        url: `/dynamics/company-match/list-new-unternehmensgruppe`,
        method: "GET",
        params: request,
      }),
    }),
  }),
});

export const {
  useShowCompanyMatchQuery,
  usePutCompanyMatchMutation,
  useDeleteCompanyMatchMutation,
  useListNewUnternehmensgruppeQuery,
} = ugcMatchApi;

export interface Match {
  companyId: string;
  newUnternehmensgruppeValue: number;
}

export interface Unternehmensgruppe {
  value: number;
  label: string;
}

export interface UnternehmensgruppenList {
  newUnternehmensgruppen: Unternehmensgruppe[];
  count: number;
}

export interface ShowRequest {
  companyId: string;
}

export interface DeleteRequest {
  companyId: string;
}

export interface PutRequest {
  companyId: string;
  newUnternehmensgruppeValue: number;
}

export interface ListRequest {}
