import { useParams } from "react-router-dom";
import { useHtzReportShowQuery } from "@/services/backend/htz/report/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  ReportData,
  ReportDataSkeleton,
} from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/data/_components/report-data";
import { useState } from "react";

export function DataRoute() {
  const { reportId } = useParams();
  const {
    data: report,
    isLoading,
    error,
  } = useHtzReportShowQuery({
    id: reportId!,
  });

  // The data route components save state locally (useState), based on the
  // report values. When the workOrderPositionId is updated, the server
  // will set multiple values in the report object. To reflect this change
  // in the local state, the component tree must be re-rendered. Changing
  // the key of the ReportData component will trigger a re-render.
  const [key, setKey] = useState<string | null>(null);
  if (report && report.workOrderPositionId !== key) {
    setKey(report.workOrderPositionId);
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <ReportDataSkeleton />;
  }

  return <ReportData report={report!} key={key} />;
}
