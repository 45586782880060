import { Filter } from "@/shared/lib/filter/filter";
import { Address } from "@/services/backend/addresses/address/address";
import { Representative } from "@/services/backend/representatives/representative/representative";
import { backendApi } from "../../api";
import { Confirmation, IncidentalCost } from "./confirmation";
import t from "../../../../lang/lang";

const confirmationApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    vbsConfirmationCreate: build.mutation<Confirmation, CreateRequest>({
      query: (request) => ({
        url: `/vbs/confirmation`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: () => [
        { type: "VbsConfirmation", id: "LIST" },
        "VbsConfirmation",
        "VbsConfirmationCurrent",
      ],
    }),
    vbsConfirmationCreateFromOld: build.mutation<
      Confirmation,
      CreateFromOldRequest
    >({
      query: (request) => ({
        url: "/vbs/confirmation/create-from-old",
        method: "POST",
        body: request,
      }),
      invalidatesTags: () => [
        { type: "VbsConfirmation", id: "LIST" },
        "VbsConfirmation",
        "VbsConfirmationCurrent",
      ],
    }),
    vbsConfirmationCreateFromOffer: build.mutation<
      Confirmation,
      CreateFromOfferRequest
    >({
      query: (request) => ({
        url: "/vbs/confirmation/create-from-offer",
        method: "POST",
        body: request,
      }),
      invalidatesTags: () => [
        { type: "VbsConfirmation", id: "LIST" },
        "VbsConfirmation",
        "VbsConfirmationCurrent",
      ],
    }),
    vbsConfirmationShow: build.query<Confirmation, ShowRequest>({
      query: (request) => ({
        url: `/vbs/confirmation`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "VbsConfirmation", id: result.id }] : [],
    }),
    vbsConfirmationList: build.query<List, Partial<ListRequest>>({
      query: (request) => ({
        url: `/vbs/confirmation/list`,
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) =>
        result ? [{ type: "VbsConfirmation", id: "LIST" }] : [],
    }),
    vbsConfirmationUpdateDate: build.mutation<Confirmation, UpdateDateRequest>({
      query: (request) => ({
        url: `/vbs/confirmation/confirmation-date`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, e, request) => [
        { type: "VbsConfirmation", id: request.id },
        "VbsConfirmation",
      ],
    }),
    vbsConfirmationUpdateOrderReference: build.mutation<
      Confirmation,
      UpdateOrderReferenceRequest
    >({
      query: (request) => ({
        url: `/vbs/confirmation/order-reference`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, _e, request) => [
        { type: "VbsConfirmation", id: request.id },
        { type: "VbsCalculation", id: result?.calculationId },
        { type: "VbsTreatment", id: result?.treatmentId },
        "VbsConfirmation",
        "VbsCalculation",
        "VbsTreatment",
      ],
    }),
    vbsConfirmationUpdateOrderDate: build.mutation<
      Confirmation,
      UpdateOrderDateRequest
    >({
      query: (request) => ({
        url: `/vbs/confirmation/order-date`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, e, request) => [
        { type: "VbsConfirmation", id: request.id },
        "VbsConfirmation",
      ],
    }),
    vbsConfirmationUpdateOfferReference: build.mutation<
      Confirmation,
      UpdateOfferReferenceRequest
    >({
      query: (request) => ({
        url: `/vbs/confirmation/offer-reference`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, _e, request) => [
        { type: "VbsConfirmation", id: request.id },
        { type: "VbsCalculation", id: result?.calculationId },
        { type: "VbsTreatment", id: result?.treatmentId },
        "VbsConfirmation",
        "VbsCalculation",
        "VbsTreatment",
      ],
    }),
    vbsConfirmationUpdateTreatmentIgnoreOfferDiscounts: build.mutation<
      Confirmation,
      UpdateTreatmentIgnoreOfferDiscountRequest
    >({
      query: (request) => ({
        url: `/vbs/confirmation/treatment/ignore-offer-discounts`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsConfirmation", id: request.id },
        "VbsConfirmation",
      ],
    }),
    vbsConfirmationUpdateTreatmentBasePrice: build.mutation<
      Confirmation,
      UpdateTreatmentBasePriceRequest
    >({
      query: (request) => ({
        url: `/vbs/confirmation/treatment/base-price`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsConfirmation", id: request.id },
        "VbsConfirmation",
      ],
    }),
    vbsConfirmationUpdateTreatmentUseCalculation: build.mutation<
      Confirmation,
      UpdateTreatmentUseCalculationRequest
    >({
      query: (request) => ({
        url: `/vbs/confirmation/treatment-use-calculation`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsConfirmation", id: request.id },
        "VbsConfirmation",
      ],
    }),
    vbsConfirmationUpdateFinalDiscount: build.mutation<
      Confirmation,
      UpdateFinalDiscountRequest
    >({
      query: (request) => ({
        url: `/vbs/confirmation/treatment/final-discount`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, e, request) => [
        { type: "VbsConfirmation", id: request.id },
        "VbsConfirmation",
      ],
    }),
    vbsConfirmationUpdateIncidentalCost: build.mutation<
      Confirmation,
      UpdateIncidentalCostRequest
    >({
      query: (request) => ({
        url: `/vbs/confirmation/incidental-cost`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, e, request) => [
        { type: "VbsConfirmation", id: request.id },
      ],
    }),
    vbsConfirmationUpdateIncidentalCostUseCalculation: build.mutation<
      Confirmation,
      UpdateIncidentalCostUseCalculationRequest
    >({
      query: (request) => ({
        url: `/vbs/confirmation/incidental-cost-use-calculation`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, e, request) => [
        { type: "VbsConfirmation", id: request.id },
      ],
    }),
    vbsConfirmationUpdateRatePerAccessPanel: build.mutation<
      Confirmation,
      UpdateRatePerAccessPanelRequest
    >({
      query: (request) => ({
        url: `/vbs/confirmation/prices-for-extras/rate-per-access-panel`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, e, request) => [
        { type: "VbsConfirmation", id: request.id },
        "VbsConfirmation",
      ],
    }),
    vbsConfirmationUpdateHourlyRate: build.mutation<
      Confirmation,
      UpdateHourlyRateRequest
    >({
      query: (request) => ({
        url: `/vbs/confirmation/prices-for-extras/hourly-rate`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, e, request) => [
        { type: "VbsConfirmation", id: request.id },
        "VbsConfirmation",
      ],
    }),
    vbsConfirmationUpdatePostalAddress: build.mutation<
      Confirmation,
      UpdatePostalAddressRequest
    >({
      query: (request) => ({
        url: `/vbs/confirmation/postal-address`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsConfirmation", id: request.id },
        "VbsConfirmation",
      ],
    }),
    vbsConfirmationUpdateServiceAddress: build.mutation<
      Confirmation,
      UpdateServiceAddressRequest
    >({
      query: (request) => ({
        url: `/vbs/confirmation/service-address`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsConfirmation", id: request.id },
        "VbsConfirmation",
      ],
    }),
    vbsConfirmationUpdateRepresentative: build.mutation<
      Confirmation,
      UpdateRepresentativeRequest
    >({
      query: (request) => ({
        url: `/vbs/confirmation/representative`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsConfirmation", id: request.id },
        "VbsConfirmation",
      ],
    }),
    vbsConfirmationPreviewPDF: build.query<
      { objectURL: string },
      PreviewPDFRequest
    >({
      query: (request) => ({
        url: `/vbs/confirmation/preview-pdf`,
        method: "GET",
        params: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          return {
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this query will download the PDF under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
      providesTags: (_r, _e, request) => [
        { type: "VbsConfirmation", id: request.id },
      ],
    }),
    vbsConfirmationDownloadPDF: build.mutation<
      { filename: string; objectURL: string },
      DownloadPDFRequest
    >({
      query: (request) => ({
        url: `/vbs/confirmation/download-pdf`,
        method: "PATCH",
        body: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          // attempt to retrieve the filename from content-disposition header
          const contentDisposition = response.headers.get(
            "Content-Disposition",
          );
          let filename = t("Auftragsbestätigung.pdf");
          if (
            contentDisposition &&
            contentDisposition.indexOf("attachment") !== -1
          ) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return {
            filename,
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this mutation will download the PDF under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
      invalidatesTags: (result, e, request) => [
        { type: "VbsConfirmation", id: request.id },
        "VbsConfirmation",
      ],
    }),
    vbsConfirmationDelete: build.mutation<Confirmation, DeleteRequest>({
      query: (request) => ({
        url: `/vbs/confirmation`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (result, e, request) => [
        { type: "VbsConfirmation", id: request.id },
        "VbsConfirmation",
        "VbsConfirmationCurrent",
      ],
    }),
    vbsConfirmationShowCurrent: build.query<Current, ShowCurrentRequest>({
      query: (request) => ({
        url: `/vbs/confirmation/current`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result
          ? [{ type: "VbsConfirmationCurrent", id: result.processId }]
          : [],
    }),
    vbsConfirmationSetCurrent: build.mutation<Current, Current>({
      query: (request) => ({
        url: `/vbs/confirmation/current`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "VbsConfirmationCurrent", id: request.processId },
      ],
    }),
  }),
});

export const {
  useVbsConfirmationShowQuery,
  useVbsConfirmationCreateMutation,
  useVbsConfirmationCreateFromOfferMutation,
  useVbsConfirmationCreateFromOldMutation,
  useVbsConfirmationListQuery,
  useVbsConfirmationDeleteMutation,
  useVbsConfirmationUpdateDateMutation,
  useVbsConfirmationUpdateOrderReferenceMutation,
  useVbsConfirmationUpdateOrderDateMutation,
  useVbsConfirmationUpdateOfferReferenceMutation,
  useVbsConfirmationUpdateTreatmentIgnoreOfferDiscountsMutation,
  useVbsConfirmationUpdateTreatmentBasePriceMutation,
  useVbsConfirmationUpdateTreatmentUseCalculationMutation,
  useVbsConfirmationUpdateFinalDiscountMutation,
  useVbsConfirmationUpdateIncidentalCostMutation,
  useVbsConfirmationUpdateIncidentalCostUseCalculationMutation,
  useVbsConfirmationUpdateRatePerAccessPanelMutation,
  useVbsConfirmationUpdateHourlyRateMutation,
  useVbsConfirmationUpdatePostalAddressMutation,
  useVbsConfirmationUpdateServiceAddressMutation,
  useVbsConfirmationUpdateRepresentativeMutation,
  useVbsConfirmationPreviewPDFQuery,
  useVbsConfirmationDownloadPDFMutation,
  useVbsConfirmationShowCurrentQuery,
  useVbsConfirmationSetCurrentMutation,
} = confirmationApi;

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  process?: Filter<string>;
  confirmationDate?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface List {
  confirmations: Confirmation[];
  count: number;
}

export interface CreateRequest {
  id: string;
  processId: string;
}

export interface CreateFromOfferRequest {
  id: string;
  processId: string;
  offerId: string;
}

export interface CreateFromOldRequest {
  oldConfirmationId: string;
  newConfirmationId: string;
  newProcessId: string;
}

export interface DeleteRequest {
  id: string;
}

export interface UpdateDateRequest {
  id: string;
  date: string;
}

export interface UpdateOrderReferenceRequest {
  id: string;
  orderId: string;
}

export interface UpdateOrderDateRequest {
  id: string;
  date: string;
}

export interface UpdateOfferReferenceRequest {
  id: string;
  offerId: string;
}

export interface UpdateTreatmentUseCalculationRequest {
  id: string;
  value: boolean;
}

export interface UpdateTreatmentIgnoreOfferDiscountRequest {
  id: string;
  value: boolean;
}

export interface UpdateTreatmentBasePriceRequest {
  id: string;
  price: number;
}

export interface UpdateFinalDiscountRequest {
  id: string;
  finalDiscount: number;
}

export interface UpdateIncidentalCostRequest {
  id: string;
  incidentalCost: IncidentalCost;
}

export interface UpdateIncidentalCostUseCalculationRequest {
  id: string;
  value: boolean;
}

export interface UpdateRatePerAccessPanelRequest {
  id: string;
  ratePerAccessPanel: number;
}

export interface UpdateHourlyRateRequest {
  id: string;
  hourlyRate: number;
}

export interface UpdatePostalAddressRequest {
  id: string;
  postalAddress: Address;
}

export interface UpdateServiceAddressRequest {
  id: string;
  serviceAddress: Address;
}

export interface UpdateRepresentativeRequest {
  id: string;
  representative: Representative;
}

export interface PreviewPDFRequest {
  id: string;
  document: ConfirmationDocument;
}

export interface DownloadPDFRequest {
  id: string;
  document: ConfirmationDocument;
  userId: string;
}

export enum ConfirmationDocument {
  All = "all",
  Letter = "letter",
  Treatment = "treatment",
}

export interface Current {
  processId: string;
  confirmationId: string;
}

export interface ShowCurrentRequest {
  processId: string;
}
