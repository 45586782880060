import { backendApi } from "@/services/backend/api";
import {
  List,
  Process,
  ViewModel,
} from "@/services/backend/processes/process/process";
import { ProcessKind } from "@/services/backend/processes/process/kinds";
import { Filter } from "@/shared/lib/filter/filter";
import { Label } from "@/services/backend/processes/state/state";

const processesApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    processesProcessPut: build.mutation<Process, PutRequest>({
      query: (request) => ({
        url: `/processes/process`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "Process", id: request.id },
        { type: "Process", id: "LIST" },
      ],
    }),
    processesProcessShow: build.query<ViewModel, ShowRequest>({
      query: (request) => ({
        url: `/processes/process`,
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _e, request) => [
        { type: "Process", id: request.id },
      ],
    }),
    processesProcessDelete: build.mutation<void, DeleteRequest>({
      query: (request) => ({
        url: `/processes/process`,
        method: "DELETE",
        params: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "Process", id: request.id },
        { type: "Process", id: "LIST" },
      ],
    }),
    processesProcessList: build.query<List, ListRequest>({
      query: (request) => ({
        url: `/processes/process/list`,
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.processes.map(({ id }) => ({
                type: "Process" as const,
                id,
              })),
              { type: "Process", id: "LIST" },
            ]
          : [{ type: "Process", id: "LIST" }],
    }),
  }),
});

export const {
  useProcessesProcessPutMutation,
  useProcessesProcessShowQuery,
  useProcessesProcessDeleteMutation,
  useProcessesProcessListQuery,
} = processesApi;

export interface PutRequest extends Process {}

export interface ShowRequest {
  id: string;
}

export interface DeleteRequest {
  id: string;
}

export interface ListRequest {
  kind?: Filter<ProcessKind>;
  topic?: Filter<string>;
  customer?: Filter<string>;
  company?: Filter<string>;
  branch?: Filter<string>;
  state?: Filter<Label>;
  createdAt?: Filter<string>;
  updatedAt?: Filter<string>;
  limit?: number;
  offset?: number;
}
