import { useState } from "react";
import {
  SignRequest,
  useVbsWorkOrderSignMutation,
} from "@/services/backend/vbs/work-orders/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { SignatureDialog } from "@/shared/components/domain/signature/signature-dialog";
import t from "@/lang/lang";

export function SignatureButton(props: { workOrderID: string }) {
  const { workOrderID } = props;
  const [request, setRequest] = useState<SignRequest>({
    id: workOrderID,
    signature: "",
    dataFormat: "image/svg+xml:base64",
    nameOfSigner: "",
  });
  const [sign, { isLoading, error, isSuccess, reset }] =
    useVbsWorkOrderSignMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    sign,
    isLoading,
    error,
    isSuccess,
    reset,
    1,
  );

  return (
    <SignatureDialog
      title={t("Arbeitsschein bestätigen")}
      name=""
      onConfirm={(data) => {
        setRequest({
          ...request,
          signature: data.signature,
          nameOfSigner: data.name,
        });
      }}
    />
  );
}
