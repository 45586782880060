import {
  generateSampleNumber,
  newSample,
  Sample,
} from "@/services/backend/samples/sample/sample";
import { Kind } from "@/services/backend/samples/sample/kind";
import { EntityId } from "@/shared/nidavellir/types/entity-id";
import { ArtefactNamespace } from "@/shared/service-manager/artefact/artefact-namespace";

export interface WaterSample extends Sample {
  waterKind: "warm" | "cold";
  desinfected: boolean;
  temperature: number;
  measurements: Measurement[];
}

export interface Measurement {
  order: number;
  waterSampleId: string;
  name: string;
  value: string;
  referenceValue: string;
  unit: string;
}

export function newColdWaterSample(
  id: string,
  artefactId: EntityId,
  artefactNamespace: ArtefactNamespace | null,
  location: string,
  spot: string,
): WaterSample {
  return {
    ...newSample(
      id,
      generateSampleNumber(),
      Kind.Water,
      artefactId,
      artefactNamespace,
    ),
    location,
    spot,
    desinfected: false,
    waterKind: "cold",
    temperature: 0,
    measurements: [
      {
        order: 1,
        waterSampleId: id,
        name: "Koloniezahl bei 22°C",
        value: "",
        referenceValue: "100",
        unit: "KBE/ml",
      },
      {
        order: 2,
        waterSampleId: id,
        name: "Koloniezahl bei 36°C",
        value: "",
        referenceValue: "100",
        unit: "KBE/ml",
      },
      {
        order: 3,
        waterSampleId: id,
        name: "Coliforme Keime",
        value: "",
        referenceValue: "1",
        unit: "KBE/100ml",
      },
      {
        order: 4,
        waterSampleId: id,
        name: "Escherichia coli",
        value: "",
        referenceValue: "1",
        unit: "KBE/100ml",
      },
      {
        order: 5,
        waterSampleId: id,
        name: "Intestinale Enterokokken",
        value: "",
        referenceValue: "1",
        unit: "KBE/100ml",
      },
      {
        order: 6,
        waterSampleId: id,
        name: "Pseudomonas aeruginosa",
        value: "",
        referenceValue: "1",
        unit: "KBE/100ml",
      },
    ],
  };
}

export function newWarmWaterSample(
  id: string,
  artefactId: EntityId,
  artefactNamespace: ArtefactNamespace | null,
  location: string,
  spot: string,
): WaterSample {
  return {
    ...newSample(
      id,
      generateSampleNumber(),
      Kind.Water,
      artefactId,
      artefactNamespace,
    ),
    location,
    spot,
    desinfected: false,
    waterKind: "warm",
    temperature: 0,
    measurements: [
      {
        order: 1,
        waterSampleId: id,
        name: "Konstante Maximaltemperatur",
        value: "",
        referenceValue: "",
        unit: "°C",
      },
      {
        order: 2,
        waterSampleId: id,
        name: "Legionellen",
        value: "",
        referenceValue: "100",
        unit: "KBE/100ml",
      },
    ],
  };
}

export function updateTemperature(
  sample: WaterSample,
  temperature: number,
): WaterSample {
  return {
    ...sample,
    temperature,
  };
}

export function updateMeasurement(
  sample: WaterSample,
  measurement: Measurement,
): WaterSample {
  const measurements = sample.measurements.map((m) =>
    m.order === measurement.order ? measurement : m,
  );
  return {
    ...sample,
    measurements,
  };
}
