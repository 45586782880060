import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import t from "@/lang/lang";

/**
 * RTKQueryErrorAlert is a component to display errors from RTK Query.
 * https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling
 * @constructor
 * @param error
 * @param ignoreValidationErrors
 */
export function parseRTKQueryError(
  error: FetchBaseQueryError | SerializedError | undefined,
  ignoreValidationErrors = true,
): { title: string; description: string } | null {
  if (error === undefined || error === null) {
    return null;
  }

  if (isFetchBaseQueryError(error)) {
    switch (error.status) {
      // Handle cases with known error types from backend.
      // Theses are the most likely errors in production and
      // should be handled first.
      case 400:
        if (is400WithMessage(error)) {
          return {
            title: "400",
            description: error.data.message,
          };
        }
        if (is400WithMsg(error)) {
          return {
            title: "400",
            description: error.data.msg,
          };
        }
        return {
          title: "400",
          description: t("Ungültige Anfrage"),
        };
      case 403:
        return {
          title: "403",
          description: t("Nicht erlaubt"),
        };
      case 404:
        return {
          title: "404",
          description: t("Nicht gefunden"),
        };
      case 415:
        return {
          title: "415",
          description: t("Medientyp nicht unterstützt"),
        };
      case 422:
        if (ignoreValidationErrors) {
          return null;
        }
        return {
          title: "422",
          description: t("Ungültige Daten"),
        };
      case 504:
        return {
          title: "504",
          description: t("Dienst nicht verfügbar"),
        };
      // handle all remaining cases, comments are from docs
      case "FETCH_ERROR":
        /**
         * * `"FETCH_ERROR"`:
         *   An error that occurred during execution of `fetch` or the `fetchFn` callback option
         * */
        return {
          title: "Verbindungsfehler",
          description:
            "Es konnte keine Verbindung zum Server hergestellt werden.",
        };
      case "PARSING_ERROR":
        /**
         * * `"PARSING_ERROR"`:
         *   An error happened during parsing.
         *   Most likely a non-JSON-response was returned with the default `responseHandler` "JSON",
         *   or an error occurred while executing a custom `responseHandler`.
         * */
        return {
          title: "Ungültige Antwort",
          description:
            "Die Antwort des Servers konnte nicht verarbeitet werden.",
        };
      case "CUSTOM_ERROR":
        /**
         * * `"CUSTOM_ERROR"`:
         *   A custom error type that you can return from your `queryFn` where another error might not make sense.
         * */
        return {
          title: error.error,
          description: "",
        };
      default:
        return {
          title: error.status.toString(),
          description: "Unerwarteter Fehler",
        };
    }
  }

  // Handle "SerializedError" from RTK Query.
  return {
    title: "Unbekannter Fehler",
    description: error.message ?? "",
  };
}

export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  if (error === undefined || error === null || typeof error !== "object") {
    return false;
  }
  return "status" in error;
}

export function is400WithMessage(
  error: unknown,
): error is { data: { message: string } } {
  if (error === undefined || error === null || typeof error !== "object") {
    return false;
  }
  if (!("data" in error)) {
    return false;
  }
  const { data } = error;
  if (data === null || typeof data !== "object") {
    return false;
  }
  return "message" in data;
}

export function is400WithMsg(
  error: unknown,
): error is { data: { msg: string } } {
  if (error === undefined || error === null || typeof error !== "object") {
    return false;
  }
  if (!("data" in error)) {
    return false;
  }
  const { data } = error;
  if (data === null || typeof data !== "object") {
    return false;
  }
  return "msg" in data;
}

export function is404(error: unknown): error is { status: number } {
  if (!error) {
    return false;
  }

  if (!isFetchBaseQueryError(error)) {
    return false;
  }

  return error.status === 404;
}
