import { Kind } from "@/services/backend/samples/sample/kind";
import { EntityId } from "@/shared/nidavellir/types/entity-id";
import { ArtefactNamespace } from "@/shared/service-manager/artefact/artefact-namespace";

/**
 * Sample is a base interface for all samples. It should not be used directly.
 */
export interface Sample {
  id: string;
  artefactId: EntityId;
  artefactNamespace: ArtefactNamespace | null;
  number: string;
  kind: Kind;
  location: string;
  spot: string;
  labId: EntityId;
  comment: string;
  createdAt: string | null;
}

export interface BaseSamplePatchRequest {
  id: string;
  number?: string;
  labId?: string;
  comment?: string;
}

export function newSample(
  id: string,
  number: string,
  kind: Kind,
  artefactId: EntityId,
  artefactNamespace: ArtefactNamespace | null,
): Sample {
  return {
    id,
    artefactId,
    artefactNamespace,
    number,
    kind,
    location: "",
    spot: "",
    labId: null,
    comment: "",
    createdAt: new Date().toISOString(),
  };
}

// TODO, eventually replace this, after actual solution is implemented
export function generateSampleNumber(): string {
  const date = new Date();
  const random = Math.floor(Math.random() * 1_000);
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}.${random}`;
}

export function updateSampleArtefact<T extends Sample>(
  sample: T,
  id: EntityId,
  namespace: ArtefactNamespace,
): T {
  return {
    ...sample,
    artefactId: id,
    artefactNamespace: namespace,
  };
}

export function updateSampleNumber<T extends Sample>(
  sample: T,
  number: string,
): T {
  return {
    ...sample,
    number,
  };
}

export function updateSampleLab<T extends Sample>(
  sample: T,
  labId: EntityId,
): T {
  return {
    ...sample,
    labId,
  };
}

export function updateSampleComment<T extends Sample>(
  sample: T,
  comment: string,
): T {
  return {
    ...sample,
    comment,
  };
}

export function updateSampleCreatedAt<T extends Sample>(
  sample: T,
  createdAt: string | null,
): T {
  return {
    ...sample,
    createdAt,
  };
}

export function updateLocation<T extends { location: string }>(
  sample: T,
  location: string,
): T {
  return {
    ...sample,
    location,
  };
}

export function updateSpot<T extends { spot: string }>(
  sample: T,
  spot: string,
): T {
  return {
    ...sample,
    spot,
  };
}
