import { Address } from "@/services/backend/addresses/address/address";
import t from "@/lang/lang";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { rtkErrIsValidationError } from "@/shared/app-lib/errors/validation-error";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { TextInput } from "@/shared/components/form/text-input";

type Mutation<Req, Res> = Readonly<
  () => [
    (entity: Req) => Promise<{ data: Res } | { error: unknown }> | void,
    {
      isLoading: boolean;
      error: unknown;
      isSuccess: boolean;
      reset: () => void;
    },
  ]
>;

export interface PostalAddressCardProps {
  address: Address;
  onAddressChange: (address: Address) => void;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
  disabled?: boolean;
}

export function PostalAddressCard({
  address,
  onAddressChange,
  fieldErrors = undefined,
  resetFieldError = undefined,
  title = t("Postadresse"),
  description = t("Adresse des Kunden"),
  disabled = false,
}: PostalAddressCardProps) {
  const errorMessages = (field: string): string[] => {
    if (!field || field === "") {
      return [];
    }

    return fieldErrors ? fieldErrors[field] ?? [] : [];
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-4 sm:grid-cols-2">
        <TextInput
          label={t("Firma")}
          field="company"
          text={address.company}
          onTextChange={(company) => {
            if (resetFieldError) resetFieldError("company");
            onAddressChange({ ...address, company });
          }}
          errors={errorMessages("company")}
          disabled={disabled}
        />
        <TextInput
          label={t("Abteilung")}
          field="department"
          text={address.department}
          onTextChange={(department) => {
            if (resetFieldError) resetFieldError("department");
            onAddressChange({ ...address, department });
          }}
          errors={errorMessages("department")}
          disabled={disabled}
        />
        <TextInput
          label={t("Ansprechpartner")}
          field="contact"
          text={address.contact}
          onTextChange={(contact) => {
            if (resetFieldError) resetFieldError("contact");
            onAddressChange({ ...address, contact });
          }}
          errors={errorMessages("contact")}
          disabled={disabled}
        />
        <TextInput
          label={t("Straße & Hausnummer")}
          field="street"
          text={address.street}
          onTextChange={(street) => {
            if (resetFieldError) resetFieldError("street");
            onAddressChange({ ...address, street });
          }}
          errors={errorMessages("street")}
          disabled={disabled}
        />
        <div className="grid grid-cols-4 gap-4">
          <TextInput
            label={t("PLZ")}
            field="postalCode"
            text={address.postalCode}
            onTextChange={(postalCode) => {
              if (resetFieldError) resetFieldError("postalCode");
              onAddressChange({ ...address, postalCode });
            }}
            errors={errorMessages("postalCode")}
            disabled={disabled}
          />
          <TextInput
            label={t("Stadt")}
            field="city"
            text={address.city}
            onTextChange={(city) => {
              if (resetFieldError) resetFieldError("city");
              onAddressChange({ ...address, city });
            }}
            errors={errorMessages("city")}
            disabled={disabled}
            className="col-span-3"
          />
        </div>
        <TextInput
          label={t("Land")}
          field="country"
          text={address.country}
          onTextChange={(country) => {
            if (resetFieldError) resetFieldError("country");
            onAddressChange({ ...address, country });
          }}
          errors={errorMessages("country")}
          disabled={disabled}
        />
      </CardContent>
    </Card>
  );
}

export function PostalAddressCardWithMutation<
  Req extends { id: string; postalAddress: Address },
  Res,
>({
  id,
  address,
  mutation,
  title = t("Postadresse"),
  description = t(
    "Adresse des Kunden, wie sie auf dem Angebot erscheinen soll",
  ),
  fieldErrors = undefined,
  resetFieldError = undefined,
  disabled = false,
}: {
  id: string;
  address: Address;
  mutation: Mutation<Req, Res>;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
  disabled?: boolean;
}) {
  // @ts-expect-error - I do not understand the error. But it works.
  const [request, setRequest] = useState<Req>({ id, postalAddress: address });
  // @ts-expect-error - I do know how to define an always fitting type for RTK query mutations.
  const [update, { isLoading, error, isSuccess, reset }] = mutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
  );

  const joinedFieldErrors = {
    ...fieldErrors,
    ...(rtkErrIsValidationError(error) ? error.data.errors : {}),
  } as { [key: string]: string[] };

  return (
    <PostalAddressCard
      address={request.postalAddress}
      // @ts-expect-error - I do not understand the error. But it works.
      onAddressChange={(a) => setRequest({ id, postalAddress: a })}
      title={title}
      description={description}
      fieldErrors={joinedFieldErrors}
      resetFieldError={resetFieldError}
      disabled={disabled}
    />
  );
}
