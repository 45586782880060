import { useParams } from "react-router-dom";
import {
  useVbsConfirmationShowQuery,
  useVbsConfirmationUpdatePostalAddressMutation,
  useVbsConfirmationUpdateServiceAddressMutation,
} from "@/services/backend/vbs/confirmations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { ServiceAddressCardWithMutation } from "@/shared/components/domain/addresses/service-address-card";
import {
  fieldErrorsForPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { Card } from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { PostalAddressCardWithMutation } from "@/shared/components/domain/addresses/postal-address-card";

export function ConfirmationCustomerAddressRoute() {
  const { confirmationId } = useParams();
  const {
    data: confirmation,
    isLoading,
    error,
  } = useVbsConfirmationShowQuery({
    id: confirmationId!,
  });

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (!confirmation) {
    return <div />;
  }

  return (
    <div className="space-y-2">
      <PostalAddressCardWithMutation
        id={confirmation!.id}
        address={confirmation!.postalAddress}
        mutation={useVbsConfirmationUpdatePostalAddressMutation}
        fieldErrors={fieldErrorsForPrefix(notReadyError, "postalAddress.")}
        resetFieldError={(field) =>
          resetNotReadyErrorField(`postalAddress.${field}`)
        }
      />
      <ServiceAddressCardWithMutation
        id={confirmation!.id}
        address={confirmation!.serviceAddress}
        mutation={useVbsConfirmationUpdateServiceAddressMutation}
        fieldErrors={fieldErrorsForPrefix(notReadyError, "serviceAddress.")}
        resetFieldError={(field) =>
          resetNotReadyErrorField(`serviceAddress.${field}`)
        }
      />
    </div>
  );
}
