import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useVbsOfferShowQuery } from "@/services/backend/vbs/offers/service";
import { useParams } from "react-router-dom";
import { Offer } from "@/services/backend/vbs/offers/offer";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import t from "@/lang/lang";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  ErrArtefactNotReady,
  ErrArtefactNotReadyContextProvider,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { DeleteOfferDialog } from "@/routes/gesec/processes/[processId]/vbs/offers/[offerId]/_components/delete-offer-dialog";
import { Card } from "@/shared/components/ui/card";
import { OfferPdf } from "@/routes/gesec/processes/[processId]/vbs/offers/[offerId]/_components/offer-pdf";
import { OfferImmutablePage } from "@/routes/gesec/processes/[processId]/vbs/offers/[offerId]/_components/offer-immutable-page";
import {
  ArtefactPage,
  ArtefactPageActions,
  ArtefactPageContent,
  ArtefactPageHeader,
  ArtefactPageNavigation,
  ArtefactPageNavigationLink,
  ArtefactPageNavigationOutlet,
} from "@/shared/service-manager/default-ui/artefact-page/artefact-page";

export function OfferRoute() {
  const { offerId } = useParams();

  const {
    data: offer,
    isLoading,
    error,
  } = useVbsOfferShowQuery({
    id: offerId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (offer!.immutable) {
    return <OfferImmutablePage offer={offer!} />;
  }

  return <OfferPage offer={offer!} />;
}

function OfferPage(props: { offer: Offer }) {
  const { offer } = props;

  return (
    <PersistenceContextProvider>
      <ErrArtefactNotReadyContextProvider>
        <Page offer={offer} />
      </ErrArtefactNotReadyContextProvider>
    </PersistenceContextProvider>
  );
}

function Page({ offer }: { offer: Offer }) {
  const { notReadyError } = useErrArtefactNotReady();

  const links = [
    {
      to: "data",
      label: t("Angebot"),
      hasValidationError: hasOfferError(notReadyError),
    },
    { to: "treatment", label: t("Leistungen") },
    { to: "calculation", label: t("Kalkulation") },
    {
      to: "customer-address",
      label: t("Anschrift"),
      hasValidationError: hasAddressError(notReadyError),
    },
    {
      to: "representative",
      label: t("Betreuer"),
      hasValidationError: hasRepresentativeError(notReadyError),
    },
    { to: "contract", label: t("Vertrag") },
  ];

  return (
    <ArtefactPage>
      <ArtefactPageHeader>
        <ArtefactPageNavigation>
          {links.map((link) => (
            <ArtefactPageNavigationLink
              key={link.to}
              to={link.to}
              hasValidationError={link.hasValidationError}
            >
              {link.label}
            </ArtefactPageNavigationLink>
          ))}
        </ArtefactPageNavigation>
        <ArtefactPageActions>
          <PersistenceStateUsingContext className="mr-2" />
          <DeleteOfferDialog offerId={offer.id} />
          <OfferPdf offer={offer} />
        </ArtefactPageActions>
      </ArtefactPageHeader>
      <ArtefactPageContent>
        <ArtefactPageNavigationOutlet />
      </ArtefactPageContent>
    </ArtefactPage>
  );
}

function hasOfferError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = [
    "date",
    "treatment.offerPrice",
    "pricesForExtras.ratePerAccessPanel",
    "pricesForExtras.hourlyRate",
  ];

  return fields.some((field) => field in error.errors);
}

function hasAddressError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = [
    "serviceAddress.company",
    "serviceAddress.street",
    "serviceAddress.postalCode",
    "serviceAddress.city",
    "customerAddress.company",
    "customerAddress.street",
    "customerAddress.postalCode",
    "customerAddress.city",
  ];

  return fields.some((field) => field in error.errors);
}

function hasRepresentativeError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = [
    "representative.email",
    "representative.mobilephone",
    "representative.name",
  ];

  return fields.some((field) => field in error.errors);
}
