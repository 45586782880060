import {
  Artefact,
  Immutability,
} from "@/shared/service-manager/artefact/artefact";
import { Process } from "@/services/backend/processes/process/process";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import {
  newPositionsFrom,
  Position,
} from "@/services/backend/htz/position/position";
import { v4 } from "uuid";
import {
  Address,
  newAddress,
} from "@/services/backend/addresses/address/address";
import { Contact } from "@/services/backend/contacts/contact/contact";
import { Representative } from "@/services/backend/representatives/representative/representative";
import { Order } from "@/services/backend/htz/orders/order";
import { Offer } from "@/services/backend/htz/offers/offer";
import { Common } from "@/services/backend/artefacts/common";

export interface Confirmation extends Artefact, Immutability, Common {
  date: string | null;
  orderId: string | null;
  orderDate: string | null;
  offerId: string | null;
  offerDate: string | null;
  positions: Position[];
  specialAgreements: string;
  postalAddress: Address;
  serviceAddress: Address;
  contact: Contact;
  representative: Representative;
}

export function newConfirmation(process: Process): Confirmation {
  return {
    id: v4(),
    artefactKind: ArtefactKind.Confirmation,
    processId: process.id,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    immutable: false,
    immutabilityReason: "",
    immutabilitySetAt: null,
    immutabilitySetBy: "",
    companyId: process.companyId,
    branchId: process.branchId,
    customerId: process.customerId,
    serviceObjectId: process.customerId, // TODO: set the object id after implementation
    date: new Date().toISOString(),
    orderId: null,
    orderDate: null,
    offerId: null,
    offerDate: null,
    positions: [],
    specialAgreements: "",
    postalAddress: newAddress(),
    serviceAddress: newAddress(),
    contact: {
      salutation: "",
      title: "",
      name: "",
      function: "",
      email: "",
      phone: "",
      mobilephone: "",
    } as Contact,
    representative: {
      name: "",
      email: "",
      phone: "",
      mobilephone: "",
    } as Representative,
  };
}

export function updateDate(
  confirmation: Confirmation,
  date: string | null,
): Confirmation {
  return {
    ...confirmation,
    date,
  };
}

/**
 * updateOrderReference will update the confirmation with the
 * date form the order and the offer. The offer must be the
 * offer referenced in the order. If there is no such reference
 * it will be ignored and null should be passed.
 * Between the offer and the order, the order data takes precedence.
 *
 * @param confirmation
 * @param order
 * @param offer The offer referenced in the order.
 */
export function updateOrderReference(
  confirmation: Confirmation,
  order: Order | null,
  offer: Offer | null,
): Confirmation {
  if (order === null) {
    // keep the rest of the data.
    return {
      ...confirmation,
      orderId: null,
    };
  }

  if (order.offerId !== offer?.id) {
    throw Error("offerId of order must be equal to offer id");
  }

  // At first wit copy the data from the offer.
  // Then we overwrite with the data from the order.
  // This may seem redundant, but keep in mind that
  // the offer may contain data the order does not.
  // Simply re-using the updateOfferReference function
  // is more stable compared to explicitly updating
  // the fields that differ.

  const offerUpdate =
    order.offerId !== null
      ? updateOfferReference(confirmation, offer)
      : confirmation;

  return {
    ...offerUpdate,
    orderId: order.id,
    orderDate: order.date,
    positions: newPositionsFrom(order.positions),
    postalAddress: order.postalAddress,
    serviceAddress: order.serviceAddress,
    representative: order.representative,
  };
}

export function updateOrderDate(
  confirmation: Confirmation,
  orderDate: string | null,
): Confirmation {
  // If the reference to the order is set,
  // the data should not be changed.
  if (confirmation.orderId !== null) {
    return confirmation;
  }

  return {
    ...confirmation,
    orderDate,
  };
}

export function updateOfferReference(
  confirmation: Confirmation,
  offer: Offer | null,
): Confirmation {
  // If the reference to the order is set,
  // the data should not be changed.
  if (confirmation.orderId !== null) {
    return confirmation;
  }

  if (offer === null) {
    // keep the rest of the data as is
    return {
      ...confirmation,
      offerId: null,
    };
  }

  return {
    ...confirmation,
    offerId: offer.id,
    offerDate: offer.date,
    positions: newPositionsFrom(offer.positions),
    specialAgreements: offer.specialAgreements,
    postalAddress: offer.postalAddress,
    serviceAddress: offer.serviceAddress,
    contact: offer.contact,
    representative: offer.representative,
  };
}

export function updateSpecialAgreements(
  confirmation: Confirmation,
  agreements: string,
): Confirmation {
  return {
    ...confirmation,
    specialAgreements: agreements,
  };
}

export function updatePostalAddress(
  confirmation: Confirmation,
  address: Address,
): Confirmation {
  return {
    ...confirmation,
    postalAddress: address,
  };
}

export function updateServiceAddress(
  confirmation: Confirmation,
  address: Address,
): Confirmation {
  return {
    ...confirmation,
    serviceAddress: address,
  };
}

export function updateRepresentative(
  confirmation: Confirmation,
  representative: Representative,
): Confirmation {
  return {
    ...confirmation,
    representative,
  };
}
