import { Outlet, useParams } from "react-router-dom";
import { useVbsOrderShowQuery } from "@/services/backend/vbs/orders/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Order } from "@/services/backend/vbs/orders/order";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { DeleteOrderDialog } from "@/routes/gesec/processes/[processId]/vbs/orders/[orderId]/_components/delete-order-dialog";
import { OrderCardSkeleton } from "@/routes/gesec/processes/[processId]/vbs/orders/[orderId]/data/_components/order-card";
import {
  ErrArtefactNotReady,
  ErrArtefactNotReadyContextProvider,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import t from "@/lang/lang";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";
import {
  ArtefactPage,
  ArtefactPageActions,
  ArtefactPageContent,
  ArtefactPageHeader,
  ArtefactPageNavigation,
  ArtefactPageNavigationLink,
} from "@/shared/service-manager/default-ui/artefact-page/artefact-page";

export function OrderRoute() {
  const { orderId } = useParams();

  const {
    data: order,
    isLoading,
    error,
  } = useVbsOrderShowQuery({
    id: orderId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <PageSkeleton />;
  }

  return <Page order={order!} />;
}

export function Page({ order }: { order: Order }) {
  return (
    <PersistenceContextProvider>
      <ErrArtefactNotReadyContextProvider>
        <ArtefactPage>
          <ArtefactPageHeader>
            <TabNavigation />
            <ArtefactPageActions>
              <PersistenceStateUsingContext className="mr-2" />
              <DeleteOrderDialog orderId={order.id} />
            </ArtefactPageActions>
          </ArtefactPageHeader>
          <ArtefactPageContent>
            <Outlet />
          </ArtefactPageContent>
        </ArtefactPage>
      </ErrArtefactNotReadyContextProvider>
    </PersistenceContextProvider>
  );
}

export function PageSkeleton() {
  return (
    <div className="animate-pulse">
      <ArtefactPage>
        <ArtefactPageHeader>
          <TabNavigation />
          <ArtefactPageActions>
            <PersistenceStateUsingContext className="mr-2" />
            <Button variant="outline" disabled>
              <Trash2 className="h-4 w-4" />
            </Button>
          </ArtefactPageActions>
        </ArtefactPageHeader>
        <ArtefactPageContent>
          <OrderCardSkeleton />
        </ArtefactPageContent>
      </ArtefactPage>
    </div>
  );
}

function TabNavigation() {
  const { notReadyError } = useErrArtefactNotReady();

  const links = [
    {
      to: "data",
      label: t("Auftragseingang"),
      hasValidationError: hasOrderError(notReadyError),
    },
    { to: "treatment", label: t("Leistungen") },
    {
      to: "customer-address",
      label: t("Anschrift"),
      hasValidationError: hasAddressError(notReadyError),
    },
    {
      to: "representative",
      label: t("Betreuer"),
      hasValidationError: hasRepresentativeError(notReadyError),
    },
  ];

  return (
    <ArtefactPageNavigation>
      {links.map((link) => (
        <ArtefactPageNavigationLink
          key={link.to}
          to={link.to}
          hasValidationError={link.hasValidationError}
        >
          {link.label}
        </ArtefactPageNavigationLink>
      ))}
    </ArtefactPageNavigation>
  );
}

function hasOrderError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = [
    "date",
    "treatment.offerPrice",
    "pricesForExtras.ratePerAccessPanel",
    "pricesForExtras.hourlyRate",
  ];

  return fields.some((field) => field in error.errors);
}

function hasAddressError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = [
    "serviceAddress.company",
    "serviceAddress.street",
    "serviceAddress.postalCode",
    "serviceAddress.city",
    "customerAddress.company",
    "customerAddress.street",
    "customerAddress.postalCode",
    "customerAddress.city",
  ];

  return fields.some((field) => field in error.errors);
}

function hasRepresentativeError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = [
    "representative.email",
    "representative.mobilephone",
    "representative.name",
  ];

  return fields.some((field) => field in error.errors);
}
