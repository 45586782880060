import { newOfferFrom, Offer } from "@/services/backend/htz/offers/offer";
import { Button } from "@/shared/components/ui/button";
import { Copy } from "lucide-react";
import { useHtzOfferPutMutation } from "@/services/backend/htz/offers/service";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";

interface CopyOfferButtonProps {
  offer: Offer;
  newProcessId: string;
  onCopy: (newOfferId: string) => void;
}

export function CopyOfferButton({
  offer,
  newProcessId,
  onCopy,
}: CopyOfferButtonProps) {
  const [put, { isLoading, error, data, reset }] = useHtzOfferPutMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
    }
  }, [error, toast]);

  if (data) {
    onCopy(data.id);
    reset();
  }

  const doCopy = () => {
    if (isLoading) {
      return;
    }

    const newOffer = newOfferFrom(
      offer,
      newProcessId,
      offer.companyId,
      offer.branchId,
      offer.customerId,
    );

    put(newOffer);
  };

  return (
    <Button size="sm" onClick={doCopy} disabled={isLoading}>
      <Copy className="h-4 w-4" />
    </Button>
  );
}
