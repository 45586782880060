import { Report } from "@/services/backend/htz/report/report";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { AirHandlingUnitComponentName } from "@/shared/components/domain/htz/ahu/air-handling-unit-name";
import { AssessmentResultSelect } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/microbial/_components/assessment-result-select";

export function VisualAssessmentsTable({ report }: { report: Report }) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Komponente")}</TableHead>
          <TableHead>{t("Mangel")}</TableHead>
          <TableHead>{t("Handlungsempfehlung")}</TableHead>
          <TableHead>{t("Bemerkung")}</TableHead>
          <TableHead>{t("Ergebnis")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {report.visualAssessments.map((assessment) => (
          <TableRow key={assessment.id}>
            <TableCell>
              <AirHandlingUnitComponentName
                id={assessment.airHandlingUnitId!}
                componentId={assessment.componentId}
              />
            </TableCell>
            <TableCell>{assessment.deficiency}</TableCell>
            <TableCell>{assessment.recommendation}</TableCell>
            <TableCell>{assessment.comment}</TableCell>
            <TableCell>
              <AssessmentResultSelect
                report={report}
                assessment={assessment}
                errorKey={`microbialAssessments.${assessment.id}.result`}
                disabled={report.immutable}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
