import { Card } from "@/shared/components/ui/card";
import { OrderTable } from "@/routes/gesec/processes/[processId]/rlt/orders/_components/order-table";
import { useParams } from "react-router-dom";
import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/service-manager/default-ui/milestone-page/milestone-page";
import t from "@/lang/lang";
import { CreateOrderButton } from "@/routes/gesec/processes/[processId]/rlt/orders/_components/create-order-button";

export function OrdersIndexRoute() {
  const { processId } = useParams();

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Auftragseingänge")}</MilestonePageTitle>
        <MilestonePageActions>
          <CreateOrderButton processId={processId!} />
        </MilestonePageActions>
      </MilestonePageHeader>
      <MilestonePageContent>
        <Card>
          <OrderTable processId={processId!} />
        </Card>
      </MilestonePageContent>
    </MilestonePage>
  );
}
