import t from "@/lang/lang";
import {
  Label,
  useProcessesStatePutMutation,
} from "@/services/backend/processes/state/state";
import { Button } from "@/shared/components/ui/button";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/shared/components/ui/sidebar";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Archive } from "lucide-react";
import { useState } from "react";

export function CloseProcessSidebarMenu({ processId }: { processId: string }) {
  const {
    data: process,
    isLoading,
    error,
  } = useProcessesProcessShowQuery({
    id: processId ?? "",
  });

  if (isLoading) {
    return null;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <SidebarMenu>
      {![Label.Archived, Label.Done].includes(process!.state) && (
        <ArchiveSidebarMenuItem processId={processId} />
      )}
      {process!.state !== Label.Done && (
        <DoneSidebarMenuItem processId={processId} />
      )}
    </SidebarMenu>
  );
}

function ArchiveSidebarMenuItem({ processId }: { processId: string }) {
  const [open, setOpen] = useState(false);
  const [put, { isLoading, error, isSuccess, reset }] =
    useProcessesStatePutMutation();

  if (isSuccess) {
    setOpen(false);
    reset();
  }

  const onClick = () => {
    if (isLoading) {
      return;
    }

    put({
      processId,
      state: Label.Archived,
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <SidebarMenuItem>
          <SidebarMenuButton>
            <Archive className="mr-2 h-5 w-5" />
            {t("Archivieren")}
          </SidebarMenuButton>
        </SidebarMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Vorgang archivieren")}</DialogTitle>
          <DialogDescription>
            {t(
              "Der Vorgang kann archiviert werden. Das macht deutlich, dass er nicht mehr in Bearbeitung ist, aber eventuell später noch benötigt wird. Er kann einfach wieder geöffnet werden.",
            )}
          </DialogDescription>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} className="mt-4" />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={onClick} disabled={isLoading}>
            {t("Vorgang archivieren")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function DoneSidebarMenuItem({ processId }: { processId: string }) {
  const [open, setOpen] = useState(false);
  const [put, { isLoading, error, isSuccess, reset }] =
    useProcessesStatePutMutation();

  if (isSuccess) {
    setOpen(false);
    reset();
  }

  const onClick = () => {
    if (isLoading) {
      return;
    }

    put({
      processId,
      state: Label.Done,
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <SidebarMenuItem>
          <SidebarMenuButton>
            <Archive className="mr-2 h-5 w-5" />
            {t("Beenden")}
          </SidebarMenuButton>
        </SidebarMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Vorgang beenden")}</DialogTitle>
          <DialogDescription>
            {t(
              "Der Vorgang kann beendet werden. Das macht deutlich, dass er final abgeschlossen ist. Er kann nicht einfach wieder geöffnet werden.",
            )}
          </DialogDescription>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} className="mt-4" />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={onClick} disabled={isLoading}>
            {t("Vorgang beenden")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
