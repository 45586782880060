import { CustomerAddressCard } from "@/shared/components/domain/addresses/customer-address-card";
import { ServiceAddressCard } from "@/shared/components/domain/addresses/service-address-card";
import { useConfirmationContext } from "@/routes/gesec/processes/[processId]/htz/confirmations/[confirmationId]/_components/confirmation-context";
import { Address } from "@/services/backend/addresses/address/address";
import {
  updatePostalAddress,
  updateServiceAddress,
} from "@/services/backend/htz/confirmations/confirmation";
import t from "@/lang/lang";
import {
  fieldErrorsForPrefix,
  resetField,
} from "@/shared/app-lib/errors/validation-error";

export function AddressesRoute() {
  const {
    confirmation,
    onConfirmationChange,
    validationError,
    setValidationError,
  } = useConfirmationContext();

  const resetValidationErrorField = (prefix: string) => (field: string) => {
    const prefixedField = `${prefix}${field}`;
    setValidationError(resetField(validationError, prefixedField));
  };

  const onCustomerAddressChange = (customerAddress: Address) => {
    onConfirmationChange(updatePostalAddress(confirmation, customerAddress));
  };

  const onServiceAddressChange = (serviceAddress: Address) => {
    onConfirmationChange(updateServiceAddress(confirmation, serviceAddress));
  };

  return (
    <div className="space-y-4">
      <CustomerAddressCard
        customerAddress={confirmation.postalAddress}
        onCustomerAddressChange={onCustomerAddressChange}
        description={t(
          "Adresse an welche das Auftragsbestätugung geschickt werden soll.",
        )}
        fieldErrors={fieldErrorsForPrefix(validationError, "postalAddress.")}
        resetFieldError={resetValidationErrorField("postalAddress.")}
        disabled={confirmation.immutable}
      />
      <ServiceAddressCard
        address={confirmation.serviceAddress}
        onAddressChange={onServiceAddressChange}
        fieldErrors={fieldErrorsForPrefix(validationError, "serviceAddress.")}
        resetFieldError={resetValidationErrorField("serviceAddress.")}
        disabled={confirmation.immutable}
      />
    </div>
  );
}
