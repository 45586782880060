import { EntityId } from "@/shared/nidavellir/types/entity-id";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import t from "@/lang/lang";
import { useSamplesLaboratoryListQuery } from "@/services/backend/samples/lab/service";

export function LaboratorySelect({
  labId,
  onLabIdChange,
  className,
  disabled,
}: {
  labId: EntityId;
  onLabIdChange: (labId: EntityId) => void;
  className?: string;
  disabled?: boolean;
}) {
  const { data } = useSamplesLaboratoryListQuery({});
  if (!data) {
    return null;
  }

  const value = labId ?? "";
  const onValueChange = (v: string) => onLabIdChange(v === "" ? null : v);

  return (
    <Select value={value} onValueChange={onValueChange} disabled={disabled}>
      <SelectTrigger className={className}>
        <SelectValue placeholder={t("Labor wählen")} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {data.labs.map((lab) => (
            <SelectItem value={lab.id} key={lab.id}>
              {lab.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
