import {
  Artefact,
  Immutability,
} from "@/shared/service-manager/artefact/artefact";
import { Address } from "@/services/backend/addresses/address/address";
import { Representative } from "@/services/backend/representatives/representative/representative";
import { Common } from "@/services/backend/artefacts/common";

export interface Order extends Artefact, Immutability, Common {
  offerId: string;
  treatmentId: string;
  calculationId: string;
  date: string;
  channel: string;
  customerReference: string;
  comment: string;
  files: OrderFile[];
  postalAddress: Address;
  serviceAddress: Address;
  representative: Representative;
}

export interface OrderFile {
  id: string;
  name: string;
}

export enum Channel {
  EMAIL = "email",
  PHONE = "phone",
  POST = "post",
  FAX = "fax",
}
