import { ComponentProps, ReactNode } from "react";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarTrigger,
} from "@/shared/components/ui/sidebar";
import { NavLink } from "react-router-dom";
import { LucideIcon } from "lucide-react";
import { cn } from "@/shared/lib/utils";

/**
 * ProcessPage is the parent node used to build the default process page.
 * The content should be composed using the other components in this file.
 */
export function ProcessPage({
  children,
  ...props
}: ComponentProps<typeof SidebarProvider>) {
  return <SidebarProvider {...props}>{children}</SidebarProvider>;
}

/**
 * ProcessPageSidebar is the parent node of the sidebar components.
 * It should be used as a direct child of ProcessPage. Its content
 * may or may not contain a footer and header. Most likely
 * ProcessPageSidebarNavigation should be used to define the content.
 */
export function ProcessPageSidebar({
  children,
  ...props
}: ComponentProps<typeof Sidebar>) {
  return (
    <Sidebar collapsible="icon" {...props}>
      {children}
    </Sidebar>
  );
}

/**
 * ProcessPageSidebarHeader allows to define a header in the sidebar,
 * which will behave like the Shadcn SidebarHeader.
 */
export function ProcessPageSidebarHeader({
  children,
  ...props
}: ComponentProps<typeof SidebarHeader>) {
  return <SidebarHeader {...props}>{children}</SidebarHeader>;
}

export type NavigationGroup = {
  label: string;
  items: NavigationItem[];
};

export type NavigationItem = {
  label: string;
  icon: LucideIcon;
  to: string;
};

/**
 * ProcessPageNavigation defines a SidebarContent containing groups
 * of navigation links. This will typically be included on every process page.
 */
export function ProcessPageSidebarNavigation({
  groups,
  ...props
}: {
  groups: NavigationGroup[];
} & ComponentProps<typeof SidebarContent>) {
  return (
    <SidebarContent {...props}>
      {groups.map((group) => (
        <SidebarGroup key={group.label}>
          <SidebarGroupLabel>{group.label}</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              {group.items.map((item) => (
                <SidebarMenuItem key={item.to}>
                  <NavLink to={item.to}>
                    {({ isActive }) => (
                      <SidebarMenuButton isActive={isActive}>
                        <item.icon className="mr-2 h-5 w-5" />
                        {item.label}
                      </SidebarMenuButton>
                    )}
                  </NavLink>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      ))}
    </SidebarContent>
  );
}

/**
 * ProcessPageSidebarFooter allows to define a footer in the sidebar,
 * which will behave like the Shadcn SidebarFooter.
 */
export function ProcessPageSidebarFooter({
  children,
  ...props
}: ComponentProps<typeof SidebarFooter>) {
  return <SidebarFooter {...props}>{children}</SidebarFooter>;
}

/**
 * ProcessPageMain is the parent node for the main content of a process
 * displayed next to the sidebar.
 */
export function ProcessPageMain({
  children,
  ...props
}: ComponentProps<typeof SidebarInset>) {
  return <SidebarInset {...props}>{children}</SidebarInset>;
}

/**
 * ProcessPageMainHeader defines a header for the main page. The header
 * will stick and always be visible. It should only contain information and
 * actions that are useful to the user at all times. E.g. Breadcrumbs including
 * information on the process customer, process number, artefact etc.
 * By convention the ProcessPageMainHeader should include the
 * ProcessPageSidebarTrigger.
 *
 * Be aware of the shirking height when the sidebar is collapsed.
 */
export function ProcessPageMainHeader({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <header
      className={cn(
        // The w-full will extend the header over the screen. To compensate,
        // the pr is dynamically calculated based on the sidebar width.
        "fixed flex h-16 w-full shrink-0 items-center gap-2 border-b bg-background px-4 pr-[calc(var(--sidebar-width)_+_theme(spacing.4))] transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12 group-has-[[data-collapsible=icon]]/sidebar-wrapper:pr-[calc(var(--sidebar-width-icon)_+_theme(spacing.4))]",
        className,
      )}
    >
      {children}
    </header>
  );
}

export function ProcessSidebarTrigger({
  className = "",
}: {
  className?: string;
}) {
  return <SidebarTrigger className={className} />;
}

export function ProcessPageMainContent({ children }: { children: ReactNode }) {
  return (
    <div className="mt-16 flex flex-1 flex-col gap-4 p-4 pl-5 group-has-[[data-collapsible=icon]]/sidebar-wrapper:mt-12">
      {children}
    </div>
  );
}
