import { MonitoringAreas } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/monitoring/monitoring-areas";
import { RequirementAreaSelector } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/monitoring/requirement-area-selector";
import { Requirement } from "@/services/backend/qpm/requirements/types";
import { AreaList, Level } from "@/services/backend/qpm/buildings/types";
import { useQpmRequirementAreaListQuery } from "@/services/backend/qpm/requirements/service";
import { useEffect, useState } from "react";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Skeleton } from "@/shared/components/ui/skeleton";

export type RequirementLevelComponentProps = {
  buildingName?: string;
  buildingId: string;
  selectedLevel: Level;
  requirement: Requirement;
};

export function RequirementLevelComponent({
  buildingName,
  buildingId,
  selectedLevel,
  requirement,
}: RequirementLevelComponentProps) {
  const [availableAreas, setAvailableAreas] = useState<AreaList>([]);

  const {
    data: selectedAreasList,
    error: errorRequirementAreas,
    isLoading: isLoadingRequirementAreas,
  } = useQpmRequirementAreaListQuery({
    artefactMetaId: {
      active: true,
      values: [requirement.artefactData.id],
    },
    buildingId: {
      active: true,
      values: [buildingId],
    },
    levelId: {
      active: true,
      values: [selectedLevel.base.id],
    },
  });

  useEffect(() => {
    const hasValidSelectedLevel =
      selectedLevel && selectedLevel.base?.children != null;
    const hasValidSelectedAreasList =
      selectedAreasList && selectedAreasList.requirementAreas;

    if (!hasValidSelectedLevel) {
      setAvailableAreas([]);
      return;
    }

    if (!hasValidSelectedAreasList) {
      setAvailableAreas(selectedLevel.base.children);
      return;
    }

    const [newAvailableAreas] = selectedLevel.base.children.reduce(
      (acc, a) => {
        const [available] = acc;
        if (
          !selectedAreasList.requirementAreas.find(
            (reqArea) => reqArea.areaId === a.base.id,
          )
        ) {
          available.push(a);
        }
        return acc;
      },
      [[]] as [AreaList],
    );

    setAvailableAreas(newAvailableAreas);
  }, [selectedAreasList, selectedLevel, setAvailableAreas]);

  if (errorRequirementAreas) {
    return <RTKQueryErrorAlert error={errorRequirementAreas} />;
  }

  if (isLoadingRequirementAreas) {
    return (
      <div className="flex flex-col space-y-3 p-2">
        <Skeleton className="h-12 w-full" />
        <div className="space-y-2">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-full" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-col space-y-4">
        {selectedLevel && selectedAreasList && (
          <MonitoringAreas
            buildingName={buildingName}
            buildingId={buildingId}
            levelId={selectedLevel.base.id}
            levelName={selectedLevel.base.name}
            requirement={requirement}
            selectedAreas={selectedAreasList}
          />
        )}

        <RequirementAreaSelector
          requirement={requirement}
          areas={availableAreas}
          levelId={selectedLevel?.base.id}
        />
      </div>
    </div>
  );
}
