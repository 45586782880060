import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { useState } from "react";
import { Button } from "@/shared/components/ui/button";
import { Check, ChevronsDown, Edit, Plus } from "lucide-react";
import t from "@/lang/lang";
import { Label } from "@/shared/components/ui/label";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  fieldErrors,
  rtkErrIsValidationError,
} from "@/shared/app-lib/errors/validation-error";
import { v4 } from "uuid";
import {
  addItem,
  Item,
  newItem,
  updateItem,
} from "@/services/backend/htz/inspection/vacatalog/visual-assessment-catalog";
import {
  useHtzVisualAssessmentCatalogPutMutation,
  useHtzVisualAssessmentCatalogShowQuery,
} from "@/services/backend/htz/inspection/vacatalog/visual-assessment-catalog-service";
import { cn } from "@/shared/lib/utils";
import { Textarea } from "@/shared/components/ui/textarea";
import { useHtzAhuComponentKindListQuery } from "@/services/backend/htz/ahu/component-kind-service";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/shared/components/ui/command";
import { Result, ResultSelect } from "@/services/backend/htz/inspection/result";

export function PutVisualAssessmentCatalogItemDialog({
  catalogId,
  item = undefined,
}: {
  catalogId: string;
  item?: Item;
}) {
  const { data: catalog } = useHtzVisualAssessmentCatalogShowQuery({
    id: catalogId,
  });
  const [open, setOpen] = useState(false);
  const [updatedItem, setUpdatedItem] = useState<Item>(
    item ?? { ...newItem(v4(), catalogId) },
  );

  const [put, { isLoading, error, isSuccess, reset }] =
    useHtzVisualAssessmentCatalogPutMutation();

  const validationError = rtkErrIsValidationError(error) ? error.data : null;
  const errorsFor = (field: string): string[] => {
    const prefixedField = `items.${updatedItem.id}.${field}`;
    const errors = fieldErrors(validationError, prefixedField);
    return errors ?? [];
  };

  if (isSuccess) {
    setOpen(false);
    if (!item) {
      setUpdatedItem(newItem(v4(), catalogId));
    }
    reset();
  }

  if (!catalog) {
    return item ? (
      <Button variant="outline" size="sm" disabled>
        <Edit className="h-5 w-5" />
      </Button>
    ) : (
      <Button size="sm" disabled>
        <Plus className="mr-2 h-5 w-5" />
        <span>{t("Vorlage")}</span>
      </Button>
    );
  }

  const onClick = () => {
    if (isLoading || !catalog) {
      return;
    }

    const updatedCatalog = item
      ? updateItem(catalog, updatedItem)
      : addItem(catalog, updatedItem);

    put(updatedCatalog);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {item ? (
          <Button variant="outline" size="sm">
            <Edit className="h-5 w-5" />
          </Button>
        ) : (
          <Button size="sm">
            <Plus className="mr-2 h-5 w-5" />
            <span>{t("Vorlage")}</span>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {item ? t("Vorlage bearbeiten") : t("Vorlage hinzufügen")}
          </DialogTitle>
          <DialogDescription>
            {t(
              "Vorlagen werden genutzt um Beurteilungen schneller ausfüllen zu können.",
            )}
          </DialogDescription>
        </DialogHeader>
        <ComponentKindsSelect
          componentKinds={updatedItem.componentKinds}
          onComponentKindsChange={(componentKinds) =>
            setUpdatedItem({ ...updatedItem, componentKinds })
          }
          errors={errorsFor("componentKinds")}
        />
        <ResultInput
          result={updatedItem.result}
          onResultChange={(result) =>
            setUpdatedItem({ ...updatedItem, result })
          }
          errors={errorsFor("result")}
        />
        <DeficiencyInput
          deficiency={updatedItem.deficiency}
          onDeficiencyChange={(deficiency) =>
            setUpdatedItem({ ...updatedItem, deficiency })
          }
          errors={errorsFor("deficiency")}
        />
        <RecommendationInput
          recommendation={updatedItem.recommendation}
          onRecommendationChange={(recommendation) =>
            setUpdatedItem({ ...updatedItem, recommendation })
          }
          errors={errorsFor("recommendation")}
        />
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={onClick} disabled={isLoading}>
            {t("Speichern")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function ComponentKindsSelect({
  componentKinds,
  onComponentKindsChange,
  errors,
}: {
  componentKinds: string[];
  onComponentKindsChange: (cks: string[]) => void;
  errors: string[];
}) {
  const [open, setOpen] = useState(false);
  const { data: list, isLoading, error } = useHtzAhuComponentKindListQuery({});

  if (isLoading) {
    return null;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  const onSelect = (name: string) => {
    if (!list) {
      return;
    }

    const kindId = list?.kinds.find((k) => k.name.toLowerCase() === name)!.id;
    if (componentKinds.some((k) => k === kindId)) {
      onComponentKindsChange(componentKinds.filter((k) => k !== kindId));
    } else {
      onComponentKindsChange([...componentKinds, kindId]);
    }
  };

  return (
    <div className="grid gap-1.5">
      <Label>{t("Relevante Arten von Komponenten")}</Label>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="justify-between"
          >
            <span>
              {t("Komponentenarten")} ({componentKinds?.length ?? 0})
            </span>
            <ChevronsDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0">
          <Command>
            <CommandInput placeholder={t("Suchen")} />
            <CommandEmpty>{t("Nicht gefunden.")}</CommandEmpty>
            <CommandGroup>
              {list?.kinds.map((kind) => (
                <CommandItem
                  key={kind.id}
                  value={kind.name}
                  onSelect={onSelect}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      componentKinds?.includes(kind.id)
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                  />
                  {kind.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
      <span className="text-xs text-muted-foreground">
        {t(
          "Die Vorlage wird nur bei den ausgewählten Komponentenarten angezeigt.",
        )}
      </span>
      <InputErrorMessages errors={errors} />
    </div>
  );
}

function DeficiencyInput({
  deficiency,
  onDeficiencyChange,
  errors,
}: {
  deficiency: string;
  onDeficiencyChange: (element: string) => void;
  errors: string[];
}) {
  return (
    <div className="w-full items-center space-y-1.5">
      <Label>{t("Mangel")}</Label>
      <Textarea
        placeholder={t("Mangel")}
        value={deficiency}
        onChange={(e) => onDeficiencyChange(e.target.value)}
      />
      <InputErrorMessages errors={errors} />
    </div>
  );
}

function ResultInput({
  result,
  onResultChange,
  errors,
}: {
  result: Result;
  onResultChange: (result: Result) => void;
  errors: string[];
}) {
  return (
    <div className="w-full items-center space-y-1.5">
      <Label>{t("Beurteilung")}</Label>
      <ResultSelect result={result} onResultChange={onResultChange} />
      <InputErrorMessages errors={errors} />
    </div>
  );
}

function RecommendationInput({
  recommendation,
  onRecommendationChange,
  errors,
}: {
  recommendation: string;
  onRecommendationChange: (element: string) => void;
  errors: string[];
}) {
  return (
    <div className="w-full items-center space-y-1.5">
      <Label>{t("Handlungsempfehlung")}</Label>
      <Textarea
        placeholder={t("Handlungsempfehlung")}
        value={recommendation}
        onChange={(e) => onRecommendationChange(e.target.value)}
      />
      <InputErrorMessages errors={errors} />
    </div>
  );
}
