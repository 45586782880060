import { Report } from "@/services/backend/htz/report/report";
import { BaseDataCard } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/data/_components/base-data-card";
import { ObjectAddressCard } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/data/_components/object-address-card";
import { CreatedForCard } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/data/_components/created-for-card";
import { CardSkeleton } from "@/shared/components/ui/card-skeleton";
import { CreatedByCompanyCard } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/data/_components/created-by-company-card";
import { LaboratoriesCard } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/data/_components/laboratories-card";

export function ReportData({ report }: { report: Report }) {
  return (
    <div className="space-y-2">
      <BaseDataCard report={report} />
      <div className="grid grid-cols-2 gap-2">
        <LaboratoriesCard report={report} />
        <CreatedByCompanyCard report={report} />
        <ObjectAddressCard report={report} />
        <CreatedForCard report={report} />
      </div>
    </div>
  );
}

export function ReportDataSkeleton() {
  return <CardSkeleton />;
}
