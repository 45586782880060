import { backendApi } from "@/services/backend/api";
import { LabData, Report, VDI6022 } from "@/services/backend/htz/report/report";
import { Filter } from "@/shared/lib/filter/filter";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";
import {
  downloadResponseHandler,
  previewResponseHandler,
} from "@/services/backend/pdf-helpers";
import { Address } from "@/services/backend/addresses/address/address";
import { Result } from "@/services/backend/htz/inspection/result";
import { Attachment } from "@/shared/service-manager/attachment/attachment";

const reportApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["HtzReport", "HtzReportAttachment"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      htzReportCreate: build.mutation<Report, CreateRequest>({
        query: (request) => ({
          url: "/htz/report",
          method: "POST",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzReport", id: request.id },
          "HtzReport",
        ],
      }),
      htzReportShow: build.query<Report, ShowRequest>({
        query: (request) => ({
          url: "/htz/report",
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("HtzReport"),
      }),
      htzReportPdfPreview: build.query<{ objectURL: string }, PDFRequest>({
        query: (request) => ({
          url: "/htz/report",
          method: "GET",
          params: request,
          headers: {
            Accept: "application/pdf",
          },
          responseHandler: previewResponseHandler,
          // IMPORTANT. Since this query will download the PDF under no circumstances
          // should the results be cached. This could end up using the users memory.
          cache: "no-cache",
        }),
        providesTags: (_r, _e, request) => [
          { type: "HtzReport", id: request.reportId },
        ],
      }),
      htzReportList: build.query<List, ListRequest>({
        query: (request) => ({
          url: "/htz/report/list",
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) => providesList(result?.reports, "HtzReport"),
      }),
      htzReportCopyDataFromWorkOrderPosition: build.mutation<
        Report,
        CopyDataFromWorkOrderPositionRequest
      >({
        query: (request) => ({
          url: "/htz/report/work-order-position",
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzReport", id: request.id },
          "HtzReport",
        ],
      }),
      htzReportUpdateBaseData: build.mutation<Report, UpdateBaseDataRequest>({
        query: (request) => ({
          url: "/htz/report/base-data",
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzReport", id: request.id },
          "HtzReport",
        ],
      }),
      htzReportUpdateVDI6022: build.mutation<Report, UpdateVDI6022Request>({
        query: (request) => ({
          url: "/htz/report/vdi6022",
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzReport", id: request.id },
          "HtzReport",
        ],
      }),
      htzReportUpdateCreatedByCompany: build.mutation<
        Report,
        UpdateCreatedByCompanyRequest
      >({
        query: (request) => ({
          url: "/htz/report/created-by-company",
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzReport", id: request.id },
          "HtzReport",
        ],
      }),
      htzReportUpdateLaboratories: build.mutation<
        Report,
        UpdateLaboratoriesRequest
      >({
        query: (request) => ({
          url: "/htz/report/laboratories",
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzReport", id: request.id },
          "HtzReport",
        ],
      }),
      htzReportUpdateObjectAddress: build.mutation<
        Report,
        UpdateObjectAddressRequest
      >({
        query: (request) => ({
          url: "/htz/report/object-address",
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzReport", id: request.id },
          "HtzReport",
        ],
      }),
      htzReportUpdateCreatedFor: build.mutation<
        Report,
        UpdateCreatedForRequest
      >({
        query: (request) => ({
          url: "/htz/report/created-for",
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzReport", id: request.id },
          "HtzReport",
        ],
      }),
      htzReportUpdateItemRecommendations: build.mutation<
        Report,
        UpdateItemRecommendations
      >({
        query: (request) => ({
          url: "/htz/report/item/recommendations",
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzReport", id: request.id },
          "HtzReport",
        ],
      }),
      htzReportUpdateAssessmentResult: build.mutation<
        Report,
        UpdateAssessmentResultRequest
      >({
        query: (request) => ({
          url: "/htz/report/assessment-result",
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzReport", id: request.id },
          "HtzReport",
        ],
      }),
      htzReportUploadAttachment: build.mutation<
        Report,
        UploadAttachmentRequest
      >({
        query: (request) => {
          const body = new FormData();
          body.append("id", request.id);
          body.append("name", request.name);
          body.append("data", request.data);
          return {
            url: "/htz/report/attachment",
            method: "PATCH",
            body,
            formData: true,
          };
        },
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzReport", id: request.id },
          "HtzReportAttachment",
          "HtzReport",
        ],
      }),
      htzReportShowAttachment: build.query<Attachment, ShowAttachmentRequest>({
        query: (request) => ({
          url: "/htz/report/attachment",
          method: "GET",
          params: request,
          // IMPORTANT. Since this query will download the PDF under no circumstances
          // should the results be cached. This could end up using the users memory.
          cache: "no-cache",
        }),
        providesTags: (_result, _e, request) => [
          { type: "HtzReport", id: request.id },
          "HtzReportAttachment",
        ],
      }),
      htzReportDeleteAttachment: build.mutation<
        Report,
        DeleteAttachmentRequest
      >({
        query: (request) => ({
          url: "/htz/report/attachment",
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzReport", id: request.id },
          "HtzReportAttachment",
          "HtzReport",
        ],
      }),
      htzReportUploadAhuImage: build.mutation<Report, UploadAhuImageReqeust>({
        query: (request) => {
          const body = new FormData();
          body.append("id", request.id);
          body.append("data", request.data);
          return {
            url: "htz/report/ahu-image",
            method: "PATCH",
            body,
            formData: true,
          };
        },
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzReport", id: request.id },
          "HtzReport",
        ],
      }),
      htzReportPdfDownload: build.mutation<
        { filename: string; objectURL: string },
        PDFRequest
      >({
        query: (request) => ({
          url: "/htz/report/download-pdf",
          method: "PATCH",
          body: {
            ...request,
            download: true,
          },
          responseHandler: downloadResponseHandler,
          // IMPORTANT. Since this query will download the PDF under no circumstances
          // should the results be cached. This could end up using the users memory.
          cache: "no-cache",
        }),
      }),
      htzReportDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: "/htz/report",
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzReport", id: request.id },
          "HtzReport",
        ],
      }),
    }),
  });

export const {
  useHtzReportCreateMutation,
  useHtzReportShowQuery,
  useHtzReportPdfPreviewQuery,
  useHtzReportListQuery,
  useHtzReportCopyDataFromWorkOrderPositionMutation,
  useHtzReportUpdateBaseDataMutation,
  useHtzReportUpdateVDI6022Mutation,
  useHtzReportUpdateCreatedByCompanyMutation,
  useHtzReportUpdateLaboratoriesMutation,
  useHtzReportUpdateObjectAddressMutation,
  useHtzReportUpdateCreatedForMutation,
  useHtzReportUpdateItemRecommendationsMutation,
  useHtzReportUpdateAssessmentResultMutation,
  useHtzReportUploadAttachmentMutation,
  useHtzReportShowAttachmentQuery,
  useHtzReportDeleteAttachmentMutation,
  useHtzReportUploadAhuImageMutation,
  useHtzReportPdfDownloadMutation,
  useHtzReportDeleteMutation,
} = reportApi;

export interface CreateRequest {
  id: string;
  processId: string;
}

export interface ShowRequest {
  id: string;
}

export interface PDFRequest {
  reportId: string;
  document: ReportDocument;
  download?: boolean;
  downloadBy?: string;
}

export enum ReportDocument {
  ALL = "all",
  REPORT = "report",
}

export interface List {
  reports: Report[];
  count: number;
  offset: number;
  limit: number;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  process?: Filter<string>;
  reportDate?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface CopyDataFromWorkOrderPositionRequest {
  id: string;
  positionId: string;
}

export interface UpdateBaseDataRequest {
  id: string;
  date: string | null;
  createdBy: string;
  inspectionDate: string | null;
  inspectionConductedBy: string;
  nextInspectionDate: string | null;
}

export interface UpdateVDI6022Request {
  id: string;
  vdi6022: VDI6022;
}

export interface UpdateCreatedByCompanyRequest {
  id: string;
  createdBy: Address;
}

export interface UpdateLaboratoriesRequest {
  id: string;
  laboratories: LabData[];
}

export interface UpdateObjectAddressRequest {
  id: string;
  objectAddress: Address;
}

export interface UpdateCreatedForRequest {
  id: string;
  createdFor: Address;
}

export interface UpdateItemRecommendations {
  id: string;
  itemId: string;
  recommendations: string[];
}

export interface UpdateAssessmentResultRequest {
  id: string;
  assessmentId: string;
  result: Result;
}

export interface UploadAttachmentRequest {
  id: string;
  name: string;
  data: File;
}

export interface ShowAttachmentRequest {
  id: string;
  attachmentId: string;
}

export interface DeleteAttachmentRequest {
  id: string;
  attachmentId: string;
}

export interface UploadAhuImageReqeust {
  id: string;
  data: string;
}

export interface DeleteRequest {
  id: string;
}
