import { MonitorRecordList } from "@/services/backend/qpm/monitors/types";
import { useEffect, useState } from "react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/shared/components/ui/collapsible";
import { ChevronDown, ChevronRight, Loader2 } from "lucide-react";
import { MonitoringComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/monitoring/monitoring-component";
import { RiskAnalysisComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/monitoring/risk-analysis-component";
import {
  MonitorTypeKey,
  QPMSearchParams,
} from "@/services/backend/qpm/shared/enums";
import { useSearchParams } from "react-router-dom";
import { useQpmRequirementSelectedMonitorSummaryQuery } from "@/services/backend/qpm/requirements/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { RequirementArea } from "@/services/backend/qpm/requirements/types";

export type MonitorAreaSegmentProps = {
  requirementId: string;
  index: number;
  area: RequirementArea;
  initialMonitors: MonitorRecordList;
  buildingName: string;
  buildingId: string;
  levelId: string;
  levelName: string;
};

export function MonitoringAreaSegmentCollapsible({
  requirementId,
  index,
  area,
  initialMonitors,
  buildingName,
  buildingId,
  levelId,
  levelName,
}: MonitorAreaSegmentProps) {
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState<boolean>(true);

  const {
    data: monitorSummary,
    error: errorMonitorSummary,
    isLoading: isLoadingMonitorSummary,
  } = useQpmRequirementSelectedMonitorSummaryQuery({
    artefactMetaId: {
      active: true,
      values: [requirementId],
    },
    buildingId: {
      active: buildingId != null,
      values: buildingId ? [buildingId] : [],
    },
    levelId: {
      active: levelId != null,
      values: levelId ? [levelId] : [],
    },
  });

  useEffect(() => {
    const collapsibleOpen = searchParams.get(
      QPMSearchParams.CollapsibleAreasOpen,
    );
    if (collapsibleOpen && collapsibleOpen === "false") {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [searchParams]);

  const truncateString = (input: string): string => {
    if (input.length <= 12) {
      return input;
    }
    return `${input.slice(0, 5)}...${input.slice(-5)}`;
  };

  if (errorMonitorSummary) {
    return <RTKQueryErrorAlert error={errorMonitorSummary} />;
  }

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div className="overflow-auto-x">
        <div className="flex w-full flex-col justify-between bg-muted/50 md:flex-row">
          <div className="flex w-full items-center justify-between pe-2">
            <CollapsibleTrigger>
              <div className="flex items-center text-sm font-bold uppercase">
                <div className="py-3 pl-6">
                  {open ? (
                    <ChevronDown className="h-5 w-5" />
                  ) : (
                    <ChevronRight className="h-5 w-5" />
                  )}
                </div>
                <div className="flex-grow py-3 pl-3 pr-6">
                  {truncateString(buildingName)} - {truncateString(levelName)} -{" "}
                  {area.area.base.name}
                </div>
              </div>
            </CollapsibleTrigger>
            <RiskAnalysisComponent
              reqArea={area}
              requirementId={requirementId}
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-1">
              <span className="p-1 font-bold">PP:</span>
              {isLoadingMonitorSummary && <Loader2 className="animate-spin" />}
              {!isLoadingMonitorSummary && (
                <span className="m-1 min-w-[50px] bg-white p-1 text-end">
                  {monitorSummary?.typeSummary[MonitorTypeKey.CheckPoint] || 0}
                </span>
              )}
            </div>
            <div className="flex items-center gap-1">
              <span className="p-1 font-bold">FIV:</span>
              {isLoadingMonitorSummary && <Loader2 className="animate-spin" />}
              {!isLoadingMonitorSummary && (
                <span className="m-1 min-w-[50px] bg-white p-1 text-end">
                  {monitorSummary?.typeSummary[
                    MonitorTypeKey.FlyExterminator
                  ] || 0}
                </span>
              )}
            </div>
          </div>
        </div>
        <CollapsibleContent>
          <MonitoringComponent
            requirementId={requirementId}
            areaIndex={index}
            buildingId={buildingId}
            levelId={area.area.levelId}
            areaId={area.area.base.id}
            monitors={initialMonitors}
          />
        </CollapsibleContent>
      </div>
    </Collapsible>
  );
}
