import { Item } from "@/services/backend/htz/inspection/vacatalog/visual-assessment-catalog";
import { EntityId, UUID } from "@/shared/nidavellir/types/entity-id";
import { Result } from "@/services/backend/htz/inspection/result";

export interface VisualAssessment {
  id: UUID;
  airHandlingUnitId: EntityId;
  componentId: string;
  deficiency: string;
  recommendation: string;
  comment: string;
  createdAt: string;
  result: Result;
}

export function newVisual(
  id: UUID,
  airHandlingUnitId: UUID,
  componentId: UUID,
): VisualAssessment {
  return {
    id,
    airHandlingUnitId,
    componentId,
    deficiency: "",
    recommendation: "",
    comment: "",
    createdAt: new Date().toISOString(),
    result: Result.ZERO,
  };
}

export function fromCatalogItem(
  id: string,
  airHandlingUnitId: EntityId,
  componentId: string,
  item: Item,
): VisualAssessment {
  return {
    ...item,
    id,
    airHandlingUnitId,
    componentId,
    comment: "",
    createdAt: new Date().toISOString(),
  };
}
