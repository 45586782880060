import { MicrobialAssessment } from "@/services/backend/htz/inspection/microbial-assessment";
import { Result, ResultSelect } from "@/services/backend/htz/inspection/result";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useHtzReportUpdateAssessmentResultMutation } from "@/services/backend/htz/report/service";
import { Report } from "@/services/backend/htz/report/report";
import { VisualAssessment } from "@/services/backend/htz/inspection/visual-assessment";
import { StructuralAssessment } from "@/services/backend/htz/inspection/structural-assessment";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { hasFieldError } from "@/shared/app-lib/errors/validation-error";

export function AssessmentResultSelect({
  report,
  assessment,
  disabled,
  errorKey,
}: {
  report: Report;
  assessment: MicrobialAssessment | StructuralAssessment | VisualAssessment;
  errorKey: string;
  disabled?: boolean;
}) {
  const [put, { isLoading, error, reset }] =
    useHtzReportUpdateAssessmentResultMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  });

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  const setResult = (result: Result) => {
    if (isLoading) return;

    resetNotReadyErrorField(errorKey);

    put({
      id: report.id,
      assessmentId: assessment.id,
      result,
    });
  };

  const isDisabled = disabled || isLoading;

  return (
    <ResultSelect
      result={assessment.result}
      onResultChange={setResult}
      className="h-8"
      disabled={isDisabled}
      hasError={hasFieldError(notReadyError, errorKey)}
    />
  );
}
