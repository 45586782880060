import { NavLink, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { Button } from "@/shared/components/ui/button";
import { ArrowLeft, RefreshCw, Trash } from "lucide-react";
import t from "@/lang/lang";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { Item } from "@/services/backend/rlt/work-orders/documentation";
import { Textarea } from "@/shared/components/ui/textarea";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { useToast } from "@/shared/hooks/use-toast";
import { WorkOrder } from "@/services/backend/rlt/work-orders/work-order";
import { cn } from "@/shared/lib/utils";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import imageCompression from "browser-image-compression";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useRltWorkOrderShowQuery } from "@/services/backend/rlt/work-orders/service";
import {
  UpdateItemRequest,
  useRltDocumentationDeleteImageMutation,
  useRltDocumentationShowImageQuery,
  useRltDocumentationShowQuery,
  useRltDocumentationUploadImageMutation,
  useRltDocumentUpdateItemMutation,
} from "@/services/backend/rlt/work-orders/documentation-service";
import {
  useRltTreatmentShowProcessTreatmentQuery,
  useRltTreatmentShowQuery,
} from "@/services/backend/rlt/treatments/service";
import { v4 } from "uuid";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";

export function WorkOrderDocumentationItemRoute() {
  const { workOrderId, documentationId, itemId } = useParams();
  const {
    data: documentation,
    isLoading,
    error,
  } = useRltDocumentationShowQuery({
    id: documentationId!,
  });

  const {
    data: workOrder,
    isLoading: isLoadingWorkOrder,
    error: errorWorkOrder,
  } = useRltWorkOrderShowQuery({
    id: workOrderId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (errorWorkOrder) {
    return <RTKQueryErrorAlert error={errorWorkOrder} />;
  }

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (isLoadingWorkOrder) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  const item = documentation!.items.find((i) => i.id === itemId);
  if (!item) {
    return <RTKQueryErrorAlert error={{ status: 404 }} />;
  }

  return <WorkOrderDocumentationItemCard workOrder={workOrder!} item={item} />;
}

export function WorkOrderDocumentationItemCard(props: {
  workOrder: WorkOrder;
  item: Item;
}) {
  const { workOrder, item } = props;

  const [request, setRequest] = useState<UpdateItemRequest>({
    id: workOrder.documentationId,
    itemId: item.id,
    positionId: item.positionId ?? "",
    title: item.title,
    description: item.description,
    isInternal: item.isInternal,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useRltDocumentUpdateItemMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
  );
  const { data: processTreatment } = useRltTreatmentShowProcessTreatmentQuery({
    processId: workOrder.processId,
  });
  const { data: treatment } = useRltTreatmentShowQuery({
    id: processTreatment?.treatmentId ?? "",
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <NavLink to=".." className="inline-flex">
            <Button variant="outline" className="space-x-2">
              <ArrowLeft className="h-5 w-5" />
              <span>{t("Zur Übersicht")}</span>
            </Button>
          </NavLink>
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="items-start md:flex md:space-x-8">
          <div className="grid w-32 items-center gap-1.5">
            <Label htmlFor="number">{t("Nr.")}</Label>
            <Input
              disabled
              readOnly
              type="number"
              id="number"
              placeholder="Nummer"
              value={item.number}
            />
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="title">{t("Titel")}</Label>
            <Input
              type="text"
              id="title"
              placeholder="Titel"
              value={request.title}
              onChange={(e) =>
                setRequest({ ...request, title: e.target.value })
              }
            />
            <InputValidationErrors error={error} field="title" />
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="requirementId">
              {t("Arbeit Zuordnen (Optional)")}
            </Label>
            <Select
              value={request.positionId ?? ""}
              onValueChange={(newVal) =>
                setRequest({ ...request, positionId: newVal })
              }
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder={t("Position auswählen")} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>{t("Positionen")}</SelectLabel>
                  {treatment?.positions.map((position) => (
                    <SelectItem value={position.id} key={position.id}>
                      <span>{position.number}</span>
                      <span> - </span>
                      <span>{t(position.component.kind)}</span>
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <InputValidationErrors error={error} field="positionId" />
          </div>
        </div>
        <div className="grid w-full gap-1.5">
          <Label htmlFor="description">{t("Beschreibung")}</Label>
          <Textarea
            placeholder={t("Beschreibung der Verschmutzung")}
            id="description"
            value={request.description}
            onChange={(e) =>
              setRequest({ ...request, description: e.target.value })
            }
          />
          <InputValidationErrors error={error} field="description" />
        </div>
        <div className="grid w-full gap-1.5">
          <Label>{t("Interne Dokumentation")}</Label>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="terms1"
              checked={request.isInternal}
              onCheckedChange={(newVal) => {
                setRequest({ ...request, isInternal: newVal as boolean });
              }}
            />
            <p className="text-sm text-muted-foreground">
              {t("Dem Kunden wird diese Dokumentation nicht anzeigen.")}
            </p>
          </div>
        </div>
        <div className="w-full md:grid md:grid-cols-2 md:gap-8">
          <div className="space-y-2">
            <div className="text-lg font-medium">{t("Vorher")}</div>
            <ImageUpload
              documentationId={workOrder.documentationId}
              itemId={item.id}
              beforeOrAfter="before"
            />
            <div className="space-y-2">
              <Label>{t("Hochgeladene Bilder:")}</Label>
              <div className="w-full space-y-4">
                {item.beforeImageIDs.map((url) => (
                  <Image
                    key={url}
                    documentationId={workOrder.documentationId}
                    itemId={item.id}
                    imageId={url}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="space-y-2">
            <div className="text-lg font-medium">{t("Nachher")}</div>
            <ImageUpload
              documentationId={workOrder.documentationId}
              itemId={item.id}
              beforeOrAfter="after"
            />
            <div className="space-y-2">
              <Label>{t("Hochgeladene Bilder:")}</Label>
              <div className="w-full space-y-4">
                {item.afterImageIDs.map((url) => (
                  <Image
                    key={url}
                    documentationId={workOrder.documentationId}
                    itemId={item.id}
                    imageId={url}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function ImageUpload(props: {
  documentationId: string;
  itemId: string;
  beforeOrAfter: "before" | "after";
}) {
  const { beforeOrAfter, documentationId, itemId } = props;
  const [upload, { isLoading, error, isSuccess, reset }] =
    useRltDocumentationUploadImageMutation();

  const { toast } = useToast();

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: t("Bild hochgeladen"),
        description: t("Das Bild wurde erfolgreich hochgeladen."),
        variant: "success",
      });
      reset();
    }
  }, [isSuccess, reset, toast]);

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  return (
    <Label
      className={cn(
        "inline-flex w-full items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
        "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        "h-9 rounded-md px-3",
      )}
    >
      <Input
        type="file"
        className="hidden"
        accept="image/jpeg,image/png"
        onChange={async (e) => {
          if (e.target.files && e.target.files[0]) {
            // compress image
            const options = {
              maxSizeMB: 0.3,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            try {
              const compressedFile = await imageCompression(
                e.target.files[0],
                options,
              );
              // upload compressed image
              upload({
                id: documentationId,
                itemId,
                beforeOrAfter,
                imageId: v4(),
                image: compressedFile,
              });
              e.target.value = "";
              e.target.files = null;
            } catch (err) {
              const parse = parseRTKQueryError(err as FetchBaseQueryError);
              toast({
                title: parse?.title,
                description: parse?.description,
                variant: "destructive",
              });
            }
          }
        }}
        disabled={isLoading}
      />
      {isLoading && <RefreshCw className="mr-2 h-4 w-4 animate-spin" />}
      <span>{t("Foto hinzufügen")}</span>
    </Label>
  );
}

function Image(props: {
  documentationId: string;
  itemId: string;
  imageId: string;
}) {
  const { documentationId, itemId, imageId } = props;
  const {
    data: image,
    isLoading,
    error,
  } = useRltDocumentationShowImageQuery({
    id: documentationId,
    itemId,
    imageId,
  });

  if (error) {
    return (
      <div className="relative">
        <RTKQueryErrorAlert error={error} />
        <DeleteImageButton
          documentationId={documentationId}
          itemId={itemId}
          imageId={imageId}
        />
      </div>
    );
  }

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    <div className="relative">
      <img className="h-auto rounded-lg" src={image!.objectURL} alt="" />
      <DeleteImageButton
        documentationId={documentationId}
        itemId={itemId}
        imageId={imageId}
      />
    </div>
  );
}

function DeleteImageButton(props: {
  documentationId: string;
  itemId: string;
  imageId: string;
}) {
  const { documentationId, itemId, imageId } = props;
  const [open, setOpen] = useState(false);
  const [deleteImage, { isLoading, error, isSuccess, reset }] =
    useRltDocumentationDeleteImageMutation();

  const onClick = () => {
    if (isLoading) {
      return;
    }
    deleteImage({
      id: documentationId,
      itemId,
      imageId,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      setOpen(false);
    }
  }, [isSuccess, setOpen, reset]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="absolute right-4 top-4">
          <Trash className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>{t("Bild wirklich löschen?")}</DialogTitle>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <Button onClick={onClick} variant="destructive">
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
