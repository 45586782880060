import { Report } from "@/services/backend/htz/report/report";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { Kind } from "@/services/backend/samples/sample/kind";
import { useState } from "react";
import { Input } from "@/shared/components/ui/input";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  useSamplesSurfaceMicrobialSampleSetPutMutation,
  useSamplesSurfaceMicrobialSampleSetShowQuery,
} from "@/services/backend/samples/sample/surface-microbial-service";
import { SurfaceMicrobialSampleSet } from "@/services/backend/samples/sample/surface-microbial-sample";
import { LaboratorySelect } from "@/shared/components/domain/samples/laboratory-select";
import { MicrobialAssessment } from "@/services/backend/htz/inspection/microbial-assessment";
import { AssessmentResultSelect } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/microbial/_components/assessment-result-select";
import { MoldDifferentiationDialog } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/microbial/_components/air-microbial-samples-table";

export function SurfaceMicrobialSamplesTable({ report }: { report: Report }) {
  const assessments = report.microbialAssessments.filter(
    (assessment) => assessment.kind === Kind.SurfaceMicrobial,
  );

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="pl-3">{t("Nummer")}</TableHead>
          <TableHead>{t("Labor")}</TableHead>
          <TableHead className="w-36">{t("Einheit")}</TableHead>
          <TableHead className="w-28">{t("Caso")}</TableHead>
          <TableHead className="w-28">{t("Malz")}</TableHead>
          <TableHead className="w-28">{t("DG18")}</TableHead>
          <TableHead className="w-28">{t("Gesamt")}</TableHead>
          <TableHead className="w-28">{t("Schimmelpilze")}</TableHead>
          <TableHead className="w-28">{t("Ergebnis")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {assessments.map(
          (assessment) =>
            assessment.sampleId && (
              <SampleRowLoader
                key={assessment.id}
                report={report}
                assessment={assessment}
              />
            ),
        )}
        {assessments.length === 0 && (
          <TableRow>
            <TableCell colSpan={7} className="text-center">
              {t("Keine Oberflächenkeimproben")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

function SampleRowLoader({
  report,
  assessment,
}: {
  report: Report;
  assessment: MicrobialAssessment;
}) {
  const {
    data: sample,
    isLoading,
    error,
  } = useSamplesSurfaceMicrobialSampleSetShowQuery({
    id: assessment.sampleId ?? "",
  });

  if (isLoading) {
    return null;
  }

  if (error) {
    return null;
  }

  return <SampleRow report={report} assessment={assessment} sample={sample!} />;
}

function SampleRow({
  report,
  assessment,
  sample,
}: {
  report: Report;
  assessment: MicrobialAssessment;
  sample: SurfaceMicrobialSampleSet;
}) {
  const [request, setRequest] = useState({ ...sample });
  const [put, { isLoading, error, isSuccess, reset }] =
    useSamplesSurfaceMicrobialSampleSetPutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  return (
    <TableRow>
      <TableCell className="py-1.5 pl-3">{sample!.number}</TableCell>
      <TableCell className="py-1.5">
        <LaboratorySelect
          labId={sample!.labId}
          onLabIdChange={(labId) => setRequest({ ...request, labId })}
          className="h-8"
          disabled={report.immutable}
        />
      </TableCell>
      <TableCell className="py-1.5">
        <Input
          type="text"
          className="h-8"
          value={request.unit}
          onChange={(e) => setRequest({ ...request, unit: e.target.value })}
          disabled={report.immutable}
        />
      </TableCell>
      <TableCell className="py-1.5">
        {sample!.caso ? (
          <Input
            type="text"
            className="h-8"
            value={request.caso?.value}
            onChange={(e) =>
              setRequest({
                ...request,
                caso: { ...request.caso!, value: e.target.value },
              })
            }
            disabled={report.immutable}
          />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell className="py-1.5">
        {sample!.malt ? (
          <Input
            type="text"
            className="h-8"
            value={request.malt?.value}
            onChange={(e) =>
              setRequest({
                ...request,
                malt: { ...request.malt!, value: e.target.value },
              })
            }
            disabled={report.immutable}
          />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell className="py-1.5">
        {sample!.dg18 ? (
          <Input
            type="text"
            className="h-8"
            value={request.dg18?.value}
            onChange={(e) =>
              setRequest({
                ...request,
                dg18: { ...request.dg18!, value: e.target.value },
              })
            }
            disabled={report.immutable}
          />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell className="py-1.5">
        <Input
          type="text"
          className="h-8"
          value={request.value}
          onChange={(e) => setRequest({ ...request, value: e.target.value })}
          disabled={report.immutable}
        />
      </TableCell>
      <TableCell className="py-1.5">
        <MoldDifferentiationDialog
          moldDifferentiations={request.moldDifferentiation}
          onMoldDifferentiationsChange={(moldDifferentiation) =>
            setRequest({ ...request, moldDifferentiation })
          }
          disabled={report.immutable}
        />
      </TableCell>
      <TableCell className="py-1.5">
        <AssessmentResultSelect
          report={report}
          assessment={assessment}
          errorKey={`microbialAssessments.${assessment.id}.result`}
          disabled={report.immutable}
        />
      </TableCell>
    </TableRow>
  );
}
