import { Label } from "@/shared/components/ui/label";
import { cn } from "@/shared/lib/utils";
import { Search, UploadCloud } from "lucide-react";
import t from "@/lang/lang";
import { ReactNode } from "react";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/shared/components/ui/sheet";
import { Button } from "@/shared/components/ui/button";
import { Attachment } from "@/shared/service-manager/attachment/attachment";

export function Attachments({ children }: { children: ReactNode }) {
  return <div className="grid grid-cols-2 gap-4">{children}</div>;
}

export function AttachmentDropdownInput({
  onChange,
  isLoading,
  className,
  accept,
  disabled,
}: {
  onChange: (files: File) => void;
  isLoading?: boolean;
  className?: string;
  accept?: string;
  disabled?: boolean;
}) {
  const defaultMimeTypes = "application/pdf";
  const mimeTypes = accept || defaultMimeTypes;

  return (
    <Label
      className={cn(
        "flex min-h-48 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-muted-foreground/40 bg-muted hover:border-solid hover:bg-muted/50",
        className,
      )}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        if (disabled) {
          return;
        }
        e.preventDefault();
        if (!e.dataTransfer.items || e.dataTransfer.items.length <= 0) {
          return;
        }

        const item = e.dataTransfer.items[0];
        if (item.kind !== "file" || !mimeTypes.includes(item.type)) {
          return;
        }

        const file = item.getAsFile();
        if (!file) {
          return;
        }

        onChange(file);
      }}
    >
      <UploadCloud
        className={cn("h-12 w-12", isLoading ? "animate-bounce" : "")}
      />
      <>
        <span className="mt-3">{t("Datei hochladen")}</span>
        <span className="mt-3 font-normal text-muted-foreground">
          {t("Klick oder Drag & Drop")}
        </span>
      </>
      <input
        type="file"
        hidden
        accept={mimeTypes}
        onChange={(e) => {
          if (!e.target.files || e.target.files.length === 0) {
            return;
          }

          onChange(e.target.files[0]);
          e.target.value = "";
          e.target.files = null;
        }}
        disabled={isLoading || disabled}
      />
    </Label>
  );
}

export function AttachmentList({ children }: { children: ReactNode }) {
  return (
    <div className="min-w-60">
      <div className="grid divide-y">{children}</div>
    </div>
  );
}

export function AttachmentListItem({ children }: { children: ReactNode[] }) {
  return (
    <div className="grid grid-cols-2 py-1">
      <div className="flex items-center">{children[0] ?? null}</div>
      <div className="flex items-center justify-end space-x-2">
        {...children.slice(1)}
      </div>
    </div>
  );
}

export function AttachmentName({ children }: { children: ReactNode }) {
  return <div className="whitespace-nowrap">{children}</div>;
}

export function AttachmentSheet({ attachment }: { attachment: Attachment }) {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" size="sm">
          <Search className="h-4 w-4" />
        </Button>
      </SheetTrigger>
      <SheetContent side="bottom" className="max-h-[95vh]">
        <SheetHeader>
          <SheetTitle>
            <span className="mr-4">{attachment.name}</span>
          </SheetTitle>
        </SheetHeader>
        <object
          type="application/pdf"
          className="mt-6 h-[80vh] w-full rounded-lg"
          data={`${attachment.dataUrl}#toolbar=1`}
        >
          {t("Datei konnte nicht geladen werden")}
        </object>
      </SheetContent>
    </Sheet>
  );
}
