import { Label } from "@/shared/components/ui/label";
import { DateStringPicker } from "@/shared/components/ui/date-picker";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";

interface DateInputProps {
  label: string;
  date: string | null;
  onDateChange: (date: string | null) => void;
  errors?: string[];
  disabled?: boolean;
}

export function DateInput({
  label,
  date,
  onDateChange,
  errors,
  disabled,
}: DateInputProps) {
  return (
    <div className="grid content-start gap-1.5">
      <Label>{label}</Label>
      <DateStringPicker
        date={date}
        onDateChange={onDateChange}
        markError={errors !== undefined && errors.length > 0}
        disabled={disabled}
      />
      <InputErrorMessages errors={errors ?? []} />
    </div>
  );
}
