import t from "@/lang/lang";
import { Username } from "@/shared/components/domain/username/username";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Check,
  checkRequiresChildren,
  LastMinuteRiskAnalysis,
} from "@/services/backend/rlt/work-orders/last-minute-risk-analysis";
import { useRltLMRAUnconfirmMutation } from "@/services/backend/rlt/work-orders/lmra-service";
import { Button } from "@/shared/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { Card, CardContent } from "@/shared/components/ui/card";
import { CheckCircle } from "lucide-react";

export function ConfirmedLMRAComponent(props: {
  lmra: LastMinuteRiskAnalysis;
}) {
  const { lmra } = props;
  const [unconfirm, { isLoading, error }] = useRltLMRAUnconfirmMutation();

  const onClick = () => {
    if (isLoading) {
      return;
    }

    unconfirm({ id: lmra.id });
  };

  return (
    <div className="space-y-2">
      <Card>
        <CardContent className="py-4">
          <div className="flex items-center justify-between">
            <div className="flex">
              <CheckCircle className="mr-4 text-green-700" />
              <span className="mr-1">{t("LMRA wurde durchgeführt von: ")}</span>
              <Username userId={lmra.conductedBy} />
            </div>
            <Button
              variant="outline"
              onClick={onClick}
              size="sm"
              disabled={isLoading}
            >
              {t("LMRA zurücksetzen")}
            </Button>
          </div>
          <RTKQueryErrorAlert error={error} className="mt-3" />
        </CardContent>
      </Card>
      <CheckListComponent checks={lmra.checks} />
    </div>
  );
}

function CheckListComponent(props: { checks: Check[] }) {
  const { checks } = props;

  return (
    <Card>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t("Nr")}</TableHead>
            <TableHead>{t("Fragestellung")}</TableHead>
            <TableHead>{t("Ergebnis")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {[...checks].sort(byNumber).map((check) => (
            <TableRow key={check.id}>
              <TableCell className="align-top">{check.number}</TableCell>
              <TableCell className="align-top">
                <div>{check.description}</div>
                {checkRequiresChildren(check) && (
                  <div className="mt-4">
                    <ChildCheckListComponent checks={check.children} />
                  </div>
                )}
              </TableCell>
              <TableCell className="align-top">
                {check.options[check.result]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
}

function byNumber(a: Check, b: Check) {
  return a.number - b.number;
}

function ChildCheckListComponent(props: { checks: Check[] }) {
  const { checks } = props;
  return (
    <div className="relative overflow-x-auto">
      <table className="w-full table-auto border-collapse text-left text-sm text-gray-500">
        <TableBody>
          {checks.map((check) => (
            <TableRow key={check.id}>
              <TableCell className="align-top">{check.number}</TableCell>
              <TableCell className="align-top">{check.description}</TableCell>
              <TableCell className="align-top">
                {check.options[check.result]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </table>
    </div>
  );
}
