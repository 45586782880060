import { Filter } from "@/shared/lib/filter/filter";
import { Address } from "@/services/backend/addresses/address/address";
import { Representative } from "@/services/backend/representatives/representative/representative";
import { backendApi } from "../../api";
import { Confirmation, IncidentalCost } from "./confirmation";
import t from "../../../../lang/lang";

const confirmationApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    rltConfirmationCreate: build.mutation<Confirmation, CreateRequest>({
      query: (request) => ({
        url: `/rlt/confirmation`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: () => [
        { type: "RltConfirmation", id: "LIST" },
        "RltConfirmation",
        "RltConfirmationCurrent",
      ],
    }),
    rltConfirmationCreateFromOld: build.mutation<
      Confirmation,
      CreateFromOldRequest
    >({
      query: (request) => ({
        url: "/rlt/confirmation/create-from-old",
        method: "POST",
        body: request,
      }),
      invalidatesTags: () => [
        { type: "RltConfirmation", id: "LIST" },
        "RltConfirmation",
        "RltConfirmationCurrent",
      ],
    }),
    rltConfirmationCreateFromOffer: build.mutation<
      Confirmation,
      CreateFromOfferRequest
    >({
      query: (request) => ({
        url: "/rlt/confirmation/create-from-offer",
        method: "POST",
        body: request,
      }),
      invalidatesTags: () => [
        { type: "RltConfirmation", id: "LIST" },
        "RltConfirmation",
        "RltConfirmationCurrent",
      ],
    }),
    rltConfirmationShow: build.query<Confirmation, ShowRequest>({
      query: (request) => ({
        url: `/rlt/confirmation`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "RltConfirmation", id: result.id }] : [],
    }),
    rltConfirmationList: build.query<List, Partial<ListRequest>>({
      query: (request) => ({
        url: `/rlt/confirmation/list`,
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) =>
        result ? [{ type: "RltConfirmation", id: "LIST" }] : [],
    }),
    rltConfirmationUpdateDate: build.mutation<Confirmation, UpdateDateRequest>({
      query: (request) => ({
        url: `/rlt/confirmation/confirmation-date`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, e, request) => [
        { type: "RltConfirmation", id: request.id },
        "RltConfirmation",
      ],
    }),
    rltConfirmationUpdateOrderReference: build.mutation<
      Confirmation,
      UpdateOrderReferenceRequest
    >({
      query: (request) => ({
        url: `/rlt/confirmation/order-reference`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, _e, request) => [
        { type: "RltConfirmation", id: request.id },
        { type: "RltCalculation", id: result?.calculationId },
        { type: "RltTreatment", id: result?.treatmentId },
        "RltConfirmation",
      ],
    }),
    rltConfirmationUpdateOrderDate: build.mutation<
      Confirmation,
      UpdateOrderDateRequest
    >({
      query: (request) => ({
        url: `/rlt/confirmation/order-date`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, e, request) => [
        { type: "RltConfirmation", id: request.id },
        "RltConfirmation",
      ],
    }),
    rltConfirmationUpdateOfferReference: build.mutation<
      Confirmation,
      UpdateOfferReferenceRequest
    >({
      query: (request) => ({
        url: `/rlt/confirmation/offer-reference`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, _e, request) => [
        { type: "RltConfirmation", id: request.id },
        { type: "RltCalculation", id: result?.calculationId },
        { type: "RltTreatment", id: result?.treatmentId },
        "RltConfirmation",
      ],
    }),
    rltConfirmationUpdateTreatmentIgnoreOfferDiscounts: build.mutation<
      Confirmation,
      UpdateTreatmentIgnoreOfferDiscountRequest
    >({
      query: (request) => ({
        url: `/rlt/confirmation/treatment/ignore-offer-discounts`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltConfirmation", id: request.id },
        "RltConfirmation",
        "RltCalculation",
        "RltTreatment",
      ],
    }),
    rltConfirmationUpdateTreatmentBasePrice: build.mutation<
      Confirmation,
      UpdateTreatmentBasePriceRequest
    >({
      query: (request) => ({
        url: `/rlt/confirmation/treatment/base-price`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltConfirmation", id: request.id },
        "RltConfirmation",
      ],
    }),
    rltConfirmationUpdateTreatmentUseCalculation: build.mutation<
      Confirmation,
      UpdateTreatmentUseCalculationRequest
    >({
      query: (request) => ({
        url: `/rlt/confirmation/treatment-use-calculation`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltConfirmation", id: request.id },
        "RltConfirmation",
      ],
    }),
    rltConfirmationUpdateFinalDiscount: build.mutation<
      Confirmation,
      UpdateFinalDiscountRequest
    >({
      query: (request) => ({
        url: `/rlt/confirmation/treatment/final-discount`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, e, request) => [
        { type: "RltConfirmation", id: request.id },
        "RltConfirmation",
      ],
    }),
    rltConfirmationUpdateIncidentalCost: build.mutation<
      Confirmation,
      UpdateIncidentalCostRequest
    >({
      query: (request) => ({
        url: `/rlt/confirmation/incidental-cost`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, e, request) => [
        { type: "RltConfirmation", id: request.id },
        "RltConfirmation",
      ],
    }),
    rltConfirmationUpdateIncidentalCostUseCalculation: build.mutation<
      Confirmation,
      UpdateIncidentalCostUseCalculationRequest
    >({
      query: (request) => ({
        url: `/rlt/confirmation/incidental-cost-use-calculation`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, e, request) => [
        { type: "RltConfirmation", id: request.id },
        "RltConfirmation",
      ],
    }),
    rltConfirmationUpdateRatePerAccessPanel: build.mutation<
      Confirmation,
      UpdateRatePerAccessPanelRequest
    >({
      query: (request) => ({
        url: `/rlt/confirmation/prices-for-extras/rate-per-access-panel`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, e, request) => [
        { type: "RltConfirmation", id: request.id },
        "RltConfirmation",
      ],
    }),
    rltConfirmationUpdateHourlyRate: build.mutation<
      Confirmation,
      UpdateHourlyRateRequest
    >({
      query: (request) => ({
        url: `/rlt/confirmation/prices-for-extras/hourly-rate`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, e, request) => [
        { type: "RltConfirmation", id: request.id },
        "RltConfirmation",
      ],
    }),
    rltConfirmationUpdatePostalAddress: build.mutation<
      Confirmation,
      UpdatePostalAddressRequest
    >({
      query: (request) => ({
        url: `/rlt/confirmation/postal-address`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltConfirmation", id: request.id },
        "RltConfirmation",
      ],
    }),
    rltConfirmationUpdateServiceAddress: build.mutation<
      Confirmation,
      UpdateServiceAddressRequest
    >({
      query: (request) => ({
        url: `/rlt/confirmation/service-address`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltConfirmation", id: request.id },
        "RltConfirmation",
      ],
    }),
    rltConfirmationUpdateRepresentative: build.mutation<
      Confirmation,
      UpdateRepresentativeRequest
    >({
      query: (request) => ({
        url: `/rlt/confirmation/representative`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltConfirmation", id: request.id },
        "RltConfirmation",
      ],
    }),
    rltConfirmationPreviewPDF: build.query<
      { objectURL: string },
      PreviewPDFRequest
    >({
      query: (request) => ({
        url: `/rlt/confirmation/preview-pdf`,
        method: "GET",
        params: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          return {
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this query will download the PDF under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
      providesTags: (_r, _e, request) => [
        { type: "RltConfirmation", id: request.id },
      ],
    }),
    rltConfirmationDownloadPDF: build.mutation<
      { filename: string; objectURL: string },
      DownloadPDFRequest
    >({
      query: (request) => ({
        url: `/rlt/confirmation/download-pdf`,
        method: "PATCH",
        body: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          // attempt to retrieve the filename from content-disposition header
          const contentDisposition = response.headers.get(
            "Content-Disposition",
          );
          let filename = t("Auftragsbestätigung.pdf");
          if (
            contentDisposition &&
            contentDisposition.indexOf("attachment") !== -1
          ) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return {
            filename,
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this mutation will download the PDF under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
      invalidatesTags: (result, e, request) => [
        { type: "RltConfirmation", id: request.id },
        "RltConfirmation",
      ],
    }),
    rltConfirmationDelete: build.mutation<Confirmation, DeleteRequest>({
      query: (request) => ({
        url: `/rlt/confirmation`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (result, e, request) => [
        { type: "RltConfirmation", id: request.id },
        "RltConfirmation",
        "RltConfirmationCurrent",
      ],
    }),
    rltConfirmationShowCurrent: build.query<Current, ShowCurrentRequest>({
      query: (request) => ({
        url: `/rlt/confirmation/current`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result
          ? [{ type: "RltConfirmationCurrent", id: result.processId }]
          : [],
    }),
    rltConfirmationSetCurrent: build.mutation<Current, Current>({
      query: (request) => ({
        url: `/rlt/confirmation/current`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "RltConfirmationCurrent", id: request.processId },
      ],
    }),
  }),
});

export const {
  useRltConfirmationShowQuery,
  useRltConfirmationCreateMutation,
  useRltConfirmationCreateFromOfferMutation,
  useRltConfirmationCreateFromOldMutation,
  useRltConfirmationListQuery,
  useRltConfirmationDeleteMutation,
  useRltConfirmationUpdateDateMutation,
  useRltConfirmationUpdateOrderReferenceMutation,
  useRltConfirmationUpdateOrderDateMutation,
  useRltConfirmationUpdateOfferReferenceMutation,
  useRltConfirmationUpdateTreatmentIgnoreOfferDiscountsMutation,
  useRltConfirmationUpdateTreatmentBasePriceMutation,
  useRltConfirmationUpdateTreatmentUseCalculationMutation,
  useRltConfirmationUpdateFinalDiscountMutation,
  useRltConfirmationUpdateIncidentalCostMutation,
  useRltConfirmationUpdateIncidentalCostUseCalculationMutation,
  useRltConfirmationUpdateRatePerAccessPanelMutation,
  useRltConfirmationUpdateHourlyRateMutation,
  useRltConfirmationUpdatePostalAddressMutation,
  useRltConfirmationUpdateServiceAddressMutation,
  useRltConfirmationUpdateRepresentativeMutation,
  useRltConfirmationPreviewPDFQuery,
  useRltConfirmationDownloadPDFMutation,
  useRltConfirmationShowCurrentQuery,
  useRltConfirmationSetCurrentMutation,
} = confirmationApi;

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  process?: Filter<string>;
  confirmationDate?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface List {
  confirmations: Confirmation[];
  count: number;
}

export interface CreateRequest {
  id: string;
  processId: string;
}

export interface CreateFromOfferRequest {
  id: string;
  processId: string;
  offerId: string;
}

export interface CreateFromOldRequest {
  oldConfirmationId: string;
  newConfirmationId: string;
  newProcessId: string;
}

export interface DeleteRequest {
  id: string;
}

export interface UpdateDateRequest {
  id: string;
  date: string;
}

export interface UpdateOrderReferenceRequest {
  id: string;
  orderId: string;
}

export interface UpdateOrderDateRequest {
  id: string;
  date: string;
}

export interface UpdateOfferReferenceRequest {
  id: string;
  offerId: string;
}

export interface UpdateTreatmentUseCalculationRequest {
  id: string;
  value: boolean;
}

export interface UpdateTreatmentIgnoreOfferDiscountRequest {
  id: string;
  value: boolean;
}

export interface UpdateTreatmentBasePriceRequest {
  id: string;
  price: number;
}

export interface UpdateFinalDiscountRequest {
  id: string;
  finalDiscount: number;
}

export interface UpdateIncidentalCostRequest {
  id: string;
  incidentalCost: IncidentalCost;
}

export interface UpdateIncidentalCostUseCalculationRequest {
  id: string;
  value: boolean;
}

export interface UpdateRatePerAccessPanelRequest {
  id: string;
  ratePerAccessPanel: number;
}

export interface UpdateHourlyRateRequest {
  id: string;
  hourlyRate: number;
}

export interface UpdatePostalAddressRequest {
  id: string;
  postalAddress: Address;
}

export interface UpdateServiceAddressRequest {
  id: string;
  serviceAddress: Address;
}

export interface UpdateRepresentativeRequest {
  id: string;
  representative: Representative;
}

export interface PreviewPDFRequest {
  id: string;
  document: ConfirmationDocument;
}

export interface DownloadPDFRequest {
  id: string;
  document: ConfirmationDocument;
  userId: string;
}

export enum ConfirmationDocument {
  All = "all",
  Letter = "letter",
  Treatment = "treatment",
}

export interface Current {
  processId: string;
  confirmationId: string;
}

export interface ShowCurrentRequest {
  processId: string;
}
