import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { useQpmRequirementListQuery } from "@/services/backend/qpm/requirements/service";
import { FilterMode } from "@/shared/lib/filter/filter";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { NavLink } from "react-router-dom";
import { Button } from "@/shared/components/ui/button";
import { Copy, Loader2, Search } from "lucide-react";
import { ArtefactMeta } from "@/services/backend/qpm/artefact-meta/types";
import { v4 } from "uuid";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useQpmCalculationLoadRequirementMutation } from "@/services/backend/qpm/calculations/service";
import { LoadRequirementRequest } from "@/services/backend/qpm/calculations/types";

export function CreateFromRequirement({
  calculationId,
  processId,
  onCreated,
}: {
  calculationId?: string;
  processId: string;
  onCreated?: (calculationId: string) => void;
}) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {t("Daten auf Basis einer Bestandsaufnahme laden")}
        </CardTitle>
        <CardDescription>
          {t("Kopiert die Daten der gewählten Bestandsaufnahme.")}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <RequirementsTable
          calculationId={calculationId}
          processId={processId}
          onCreated={onCreated}
        />
      </CardContent>
    </Card>
  );
}

function RequirementsTable({
  calculationId,
  processId,
  onCreated,
}: {
  calculationId?: string;
  processId: string;
  onCreated?: (calculationId: string) => void;
}) {
  const {
    data: list,
    isLoading,
    error,
  } = useQpmRequirementListQuery({
    process: {
      active: true,
      values: [processId],
    },
    owner: {
      active: true,
      mode: FilterMode.IsNull,
    },
    parent: {
      active: true,
      mode: FilterMode.IsNull,
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Table className="animate-pulse">
        <TableHeader>
          <TableRow>
            <TableHead className="w-24">{t("Aktuelle")}</TableHead>
            <TableHead>{t("Nummer")}</TableHead>
            <TableHead>{t("Erstellt am")}</TableHead>
            <TableHead className="w-56">{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead>{t("Erstellt am")}</TableHead>
          <TableHead className="w-56">{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {[...(list?.artefacts ?? [])].sort(byCreatedAt).map((requirement) => (
          <TableRow key={requirement.id}>
            <TableCell>
              <ArtefactNumberById artefactId={requirement.id} />
            </TableCell>
            <TableCell>
              {new Date(requirement.createdAt).toLocaleDateString()}
            </TableCell>
            <TableCell>
              <div className="flex space-x-2">
                <NavLink to={`../requirements/${requirement.id}`}>
                  <Button variant="outline" size="sm">
                    <Search className="h-4 w-4" />
                  </Button>
                </NavLink>
                <CreateCalculationFromRequirementButton
                  requirementId={requirement.id}
                  onCreated={onCreated}
                  calculationId={calculationId}
                />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function byCreatedAt(a: ArtefactMeta, b: ArtefactMeta) {
  return a.createdAt.localeCompare(b.createdAt);
}

export type CreateFromRequirementButtonProps = {
  requirementId: string;
  calculationId?: string;
  onCreated?: (calculationId: string) => void;
};

function CreateCalculationFromRequirementButton({
  requirementId,
  onCreated,
  calculationId,
}: CreateFromRequirementButtonProps) {
  const { toast } = useToast();
  const [applyRequirementData, { error, isLoading, reset }] =
    useQpmCalculationLoadRequirementMutation();

  const onClick = () => {
    const calculationRequestId: string = calculationId || v4();
    const request: LoadRequirementRequest = {
      calculationArtefactId: calculationRequestId,
      requirementArtefactId: requirementId,
    };

    applyRequirementData(request)
      .unwrap()
      .then(() => {
        if (onCreated) {
          onCreated(calculationRequestId);
        }
      });
  };

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error), variant: "destructive" });
      reset();
    }
  }, [error, toast, reset]);

  return (
    <Button onClick={onClick} disabled={isLoading} size="sm">
      {isLoading && <Loader2 className="h-4 w-4 animate-spin" />}
      {!isLoading && (
        <>
          <Copy className="mr-3 h-4 w-4" />
          <span>{t("Daten Übernehmen")}</span>
        </>
      )}
    </Button>
  );
}
