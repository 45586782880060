import { Item } from "@/services/backend/htz/report/report";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { AirHandlingUnitComponentName } from "@/shared/components/domain/htz/ahu/air-handling-unit-name";
import { ResultDot } from "@/services/backend/htz/inspection/result";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { hasFieldError } from "@/shared/app-lib/errors/validation-error";
import { useRef, useState } from "react";
import { useTextareaAutoResize } from "@/shared/hooks/use-textarea-auto-resize";
import {
  UpdateItemRecommendations,
  useHtzReportUpdateItemRecommendationsMutation,
} from "@/services/backend/htz/report/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { cn } from "@/shared/lib/utils";

export function ItemsTable({
  items,
  disabled,
}: {
  items: Item[];
  disabled: boolean;
}) {
  const { notReadyError } = useErrArtefactNotReady();

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Komponente")}</TableHead>
          <TableHead>{t("Konstruktiv")}</TableHead>
          <TableHead>{t("Visuell")}</TableHead>
          <TableHead>{t("Mikrobiologisch")}</TableHead>
          <TableHead className="w-full">{t("Handlungsempfehlung")}</TableHead>
          <TableHead>{t("Ergebnis")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.id}>
            <TableCell className="whitespace-nowrap py-1.5">
              <AirHandlingUnitComponentName
                id={item.airHandlingUnitId!}
                componentId={item.componentId!}
              />
            </TableCell>
            <TableCell className="py-1.5">
              <ResultDot
                result={item.structuralInspectionResult}
                hasError={hasFieldError(
                  notReadyError,
                  `items.${item.id}.structuralInspectionResult`,
                )}
              />
            </TableCell>
            <TableCell className="py-1.5">
              <ResultDot
                result={item.visualInspectionResult}
                hasError={hasFieldError(
                  notReadyError,
                  `items.${item.id}.visualInspectionResult`,
                )}
              />
            </TableCell>
            <TableCell className="py-1.5">
              <ResultDot
                result={item.microbialInspectionResult}
                hasError={hasFieldError(
                  notReadyError,
                  `items.${item.id}.microbialInspectionResult`,
                )}
              />
            </TableCell>
            <TableCell className="py-1.5">
              <RecommendationsInput item={item} disabled={disabled} />
            </TableCell>
            <TableCell className="py-1.5">
              <ResultDot
                result={item.result}
                hasError={hasFieldError(
                  notReadyError,
                  `items.${item.id}.result`,
                )}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function RecommendationsInput({
  item,
  disabled,
}: {
  item: Item;
  disabled: boolean;
}) {
  const [request, setRequest] = useState<UpdateItemRecommendations>({
    id: item.reportId,
    itemId: item.id,
    recommendations: item.recommendations,
  });
  const [patch, { isLoading, error, isSuccess, reset }] =
    useHtzReportUpdateItemRecommendationsMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    patch,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    { toastError: true },
  );

  const value = request.recommendations.join("\n");
  const onValueChange = (v: string) => {
    setRequest({ ...request, recommendations: v.split("\n") });
  };

  const ref = useRef<HTMLTextAreaElement>(null);
  useTextareaAutoResize(ref, value);

  const { notReadyError } = useErrArtefactNotReady();

  const hasError = hasFieldError(
    notReadyError,
    `items.${item.id}.recommendations`,
  );

  return (
    <textarea
      ref={ref}
      value={value}
      rows={2}
      className={cn(
        "flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        hasError && "border-red-500 shadow-md",
      )}
      onChange={(e) => onValueChange(e.target.value)}
      disabled={disabled}
    />
  );
}
