import { useParams } from "react-router-dom";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  RepresentativeCardSkeleton,
  RepresentativeCardWithMutation,
} from "@/shared/components/domain/representatives/representative-card";
import {
  fieldErrorsForPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import {
  useRltOrderShowQuery,
  useRltOrderUpdateRepresentativeMutation,
} from "@/services/backend/rlt/orders/service";

export function OrderRepresentativeRoute() {
  const { orderId } = useParams();

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  const {
    data: order,
    isLoading,
    error,
  } = useRltOrderShowQuery({
    id: orderId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <RepresentativeCardSkeleton />;
  }

  return (
    <RepresentativeCardWithMutation
      id={order!.id}
      representative={order!.representative}
      mutation={useRltOrderUpdateRepresentativeMutation}
      fieldErrors={fieldErrorsForPrefix(notReadyError, "representative.")}
      resetFieldError={(field) =>
        resetNotReadyErrorField(`representative.${field}`)
      }
    />
  );
}
