import { MonitorTypeKey } from "@/services/backend/qpm/shared/enums";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { useQpmRequirementSelectedMonitorSummaryQuery } from "@/services/backend/qpm/requirements/service";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";

interface MonitorDetailSummaryProps {
  requirementId: string;
  buildingId?: string;
  levelId?: string;
  title?: string;
}

export function MonitorDetailSummaryComponent({
  requirementId,
  buildingId = undefined,
  levelId = undefined,
  title = t("Gesamtübersicht"),
}: MonitorDetailSummaryProps) {
  const {
    data: monitorSummary,
    error: errorMonitorSummary,
    isLoading: isLoadingMonitorSummary,
  } = useQpmRequirementSelectedMonitorSummaryQuery({
    artefactMetaId: {
      active: true,
      values: [requirementId],
    },
    buildingId: {
      active: buildingId != null,
      values: buildingId ? [buildingId] : [],
    },
    levelId: {
      active: levelId != null,
      values: levelId ? [levelId] : [],
    },
  });

  if (errorMonitorSummary) {
    return <RTKQueryErrorAlert error={errorMonitorSummary} />;
  }

  if (!monitorSummary) {
    return <div>{t("Keine Gesamtübersicht vorhanden!")}</div>;
  }

  if (isLoadingMonitorSummary) {
    return (
      <div className="flex items-center justify-between gap-2 p-2">
        <Skeleton className="h-12 w-full" />
        <Skeleton className="h-12 w-full" />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <span className="mb-2 text-xl font-bold">{title}</span>
      <Table className="border">
        <TableHeader className="bg-secondary">
          <TableRow>
            <TableHead>{t("Monitortyp")}</TableHead>
            <TableHead className="text-end">Gesamtanzahl</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {[
            MonitorTypeKey.CheckPoint,
            MonitorTypeKey.FlyExterminator,
            MonitorTypeKey.ALoRa,
          ].map((key) => (
            <TableRow key={`${key}_row`}>
              <TableCell>{t(key)}</TableCell>
              <TableCell className="text-end">
                {monitorSummary.typeSummary[key] || 0}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {[
        MonitorTypeKey.CheckPoint,
        MonitorTypeKey.FlyExterminator,
        MonitorTypeKey.ALoRa,
      ].map((key) => (
        <Table key={key} className="border">
          <TableHeader className="bg-secondary">
            <TableRow>
              <TableHead>{t(key)}</TableHead>
              <TableHead className="text-end">Gesamtanzahl</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {monitorSummary?.monitorSummaryList.monitors
              .filter((monitor) => monitor.monitor.type.key === key)
              .map((monitor) => (
                <TableRow key={monitor.monitor.id}>
                  <TableCell>{monitor.monitor.name}</TableCell>
                  <TableCell className="text-end">
                    {monitor.totalAmount}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      ))}
    </div>
  );
}
