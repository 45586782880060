import { ArtefactKind } from "@/services/backend/artefacts/kind";
import {
  BadgeEuro,
  Calculator,
  Camera,
  Clipboard,
  ClipboardCheck,
  ClipboardList,
  FileInput,
  FileOutput,
  ListChecks,
  ShieldCheck,
} from "lucide-react";
import { cn } from "@/shared/lib/utils";

export interface ArtefactIconProps {
  artefactKind: ArtefactKind;
  className?: string;
}

export const ArtefactIconCalculation = Calculator;
export const ArtefactIconCertificate = ShieldCheck;
export const ArtefactIconConfirmation = FileOutput;
export const ArtefactIconContract = ShieldCheck;
export const ArtefactIconDocumentation = Camera;
export const ArtefactIconLastMinuteRiskAnalysis = ListChecks;
export const ArtefactIconOffer = BadgeEuro;
export const ArtefactIconOrder = FileInput;
export const ArtefactIconReport = ClipboardList;
export const ArtefactIconRequirement = Clipboard;
export const ArtefactIconTreatment = Clipboard;
export const ArtefactIconWorkOrder = ClipboardCheck;

export function ArtefactIcon({
  artefactKind,
  className = "",
}: ArtefactIconProps) {
  switch (artefactKind) {
    case ArtefactKind.Calculation:
      return <ArtefactIconCalculation className={cn("h-5 w-5", className)} />;
    case ArtefactKind.Certificate:
      return <ArtefactIconCertificate className={cn("h-5 w-5", className)} />;
    case ArtefactKind.Confirmation:
      return <ArtefactIconConfirmation className={cn("h-5 w-5", className)} />;
    case ArtefactKind.Contract:
      return <ArtefactIconContract className={cn("h-5 w-5", className)} />;
    case ArtefactKind.Documentation:
      return <ArtefactIconDocumentation className={cn("h-5 w-5", className)} />;
    case ArtefactKind.LastMinuteRiskAnalysis:
      return (
        <ArtefactIconLastMinuteRiskAnalysis
          className={cn("h-5 w-5", className)}
        />
      );
    case ArtefactKind.Offer:
      return <ArtefactIconOffer className={cn("h-5 w-5", className)} />;
    case ArtefactKind.Order:
      return <ArtefactIconOrder className={cn("h-5 w-5", className)} />;
    case ArtefactKind.Report:
      return <ArtefactIconReport className={cn("h-5 w-5", className)} />;
    case ArtefactKind.Requirement:
      return <ArtefactIconRequirement className={cn("h-5 w-5", className)} />;
    case ArtefactKind.Treatment:
      return <ArtefactIconTreatment className={cn("h-5 w-5", className)} />;
    case ArtefactKind.WorkOrder:
      return <ArtefactIconWorkOrder className={cn("h-5 w-5", className)} />;
    default:
      return <span>{artefactKind}</span>;
  }
}
