import { backendApi } from "../api";

const processKindMatchApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    showProcessKindMatch: build.query<Match, ShowRequest>({
      query: (request) => ({
        url: `/dynamics/process-kind-match`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result
          ? [
              { type: "ProcessKindMatch", id: result.processKind },
              "ProcessKindMatch",
            ]
          : [],
    }),
    putProcessKindMatch: build.mutation<Match, PutRequest>({
      query: (request) => ({
        url: `/dynamics/process-kind-match`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: (result, error, request) => [
        { type: "ProcessKindMatch", id: request.processKind },
        "ProcessKindMatch",
      ],
    }),
    deleteProcessKindMatch: build.mutation<Match, DeleteRequest>({
      query: (request) => ({
        url: `/dynamics/process-kind-match`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (result, error, request) => [
        { type: "ProcessKindMatch", id: request.processKind },
        "ProcessKindMatch",
      ],
    }),
    listAxDienstleistung: build.query<
      AxDienstleistungList,
      ListAxDienstleistungRequest
    >({
      query: (request) => ({
        url: `/dynamics/process-kind-match/list-ax-dienstleistung`,
        method: "GET",
        params: request,
      }),
    }),
  }),
});

export const {
  useShowProcessKindMatchQuery,
  usePutProcessKindMatchMutation,
  useDeleteProcessKindMatchMutation,
  useListAxDienstleistungQuery,
} = processKindMatchApi;

export interface Match {
  processKind: string;
  axDienstleistungValue: number;
}

export interface ShowRequest {
  processKind: string;
}

export interface PutRequest {
  processKind: string;
  axDienstleistungValue: number;
}

export interface DeleteRequest {
  processKind: string;
}

export interface AxDienstleistungList {
  axDienstleistungen: AxDienstleistung[];
  count: number;
}

export interface AxDienstleistung {
  value: number;
  label: string;
}

export interface ListAxDienstleistungRequest {}
