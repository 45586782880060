import { Report } from "@/services/backend/htz/report/report";
import { useState } from "react";
import {
  UpdateObjectAddressRequest,
  useHtzReportUpdateObjectAddressMutation,
} from "@/services/backend/htz/report/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { AddressCard } from "@/shared/components/domain/addresses/address-card";
import t from "@/lang/lang";
import {
  fieldErrorsForPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";

export function ObjectAddressCard({ report }: { report: Report }) {
  const [request, setRequest] = useState<UpdateObjectAddressRequest>({
    id: report.id,
    objectAddress: report.objectAddress,
  });
  const [patch, { isLoading, error, isSuccess, reset }] =
    useHtzReportUpdateObjectAddressMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    patch,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  return (
    <AddressCard
      title={t("Objektadresse")}
      description={t("Adresse des Objekts der Inspektion")}
      address={request.objectAddress}
      onAddressChange={(objectAddress) =>
        setRequest({ id: request.id, objectAddress })
      }
      fieldErrors={fieldErrorsForPrefix(notReadyError, "objectAddress.")}
      resetFieldError={(field) =>
        resetNotReadyErrorField(`objectAddress.${field}`)
      }
      disabled={report.immutable}
    />
  );
}
