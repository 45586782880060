import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { useState } from "react";
import { PutRequest } from "@/services/backend/htz/ahu/service";
import { v4 } from "uuid";
import { RefreshCw } from "lucide-react";
import { H4 } from "@/shared/components/ui/typography";
import { usePutAirHandlingUnit } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/air-handling-unit-hooks";
import { newAirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";

export function CreateAhuInput({ customerId }: { customerId: string }) {
  const [request, setRequest] = useState<PutRequest>(
    newAirHandlingUnit(v4(), customerId),
  );
  const [put, { isLoading, error }] = usePutAirHandlingUnit();

  const doCreate = () => {
    if (isLoading) {
      return;
    }

    put({ ...request, id: v4() });
    setRequest(newAirHandlingUnit(v4(), customerId));
  };

  return (
    <div className="grid grid-cols-1 gap-2">
      <H4 className="text-base">{t("Neue Anlage erstellen:")}</H4>
      <div className="grid content-start gap-1.5">
        <Input
          value={request?.name}
          placeholder={t("Bezeichnung")}
          onChange={(e) => setRequest({ ...request, name: e.target.value })}
        />
        <InputValidationErrors error={error} field="name" />
      </div>
      <Button
        onClick={doCreate}
        disabled={isLoading}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            doCreate();
          }
        }}
      >
        {isLoading && <RefreshCw className="mr-2 h-4 w-4 animate-spin" />}
        {t("Erstellen")}
      </Button>
    </div>
  );
}
