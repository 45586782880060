import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useParams } from "react-router-dom";
import {
  useRltOfferShowQuery,
  useRltOfferUpdatePostalAddressMutation,
  useRltOfferUpdateServiceAddressMutation,
} from "@/services/backend/rlt/offers/service";
import {
  fieldErrorsForPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { Card } from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { PostalAddressCardWithMutation } from "@/shared/components/domain/addresses/postal-address-card";
import { ServiceAddressCardWithMutation } from "@/shared/components/domain/addresses/service-address-card";

export function OfferCustomerAddressRoute() {
  const { offerId } = useParams();
  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();
  const {
    data: offer,
    isLoading,
    error,
  } = useRltOfferShowQuery({
    id: offerId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    <div className="space-y-4">
      <PostalAddressCardWithMutation
        id={offer!.id}
        address={offer!.postalAddress}
        mutation={useRltOfferUpdatePostalAddressMutation}
        fieldErrors={fieldErrorsForPrefix(notReadyError, "postalAddress.")}
        resetFieldError={(field) =>
          resetNotReadyErrorField(`postalAddress.${field}`)
        }
      />
      <ServiceAddressCardWithMutation
        id={offer!.id}
        address={offer!.serviceAddress}
        mutation={useRltOfferUpdateServiceAddressMutation}
        fieldErrors={fieldErrorsForPrefix(notReadyError, "serviceAddress.")}
        resetFieldError={(field) =>
          resetNotReadyErrorField(`serviceAddress.${field}`)
        }
      />
    </div>
  );
}
