import {
  Artefact,
  Immutability,
} from "@/shared/service-manager/artefact/artefact";
import { EntityId, UUID } from "@/shared/nidavellir/types/entity-id";
import { Address } from "@/services/backend/addresses/address/address";
import { Result } from "@/services/backend/htz/inspection/result";
import { StructuralAssessment } from "@/services/backend/htz/inspection/structural-assessment";
import { VisualAssessment } from "@/services/backend/htz/inspection/visual-assessment";
import { MicrobialAssessment } from "@/services/backend/htz/inspection/microbial-assessment";
import { Photo } from "@/services/backend/htz/inspection/photo";
import { Attachment } from "@/shared/service-manager/attachment/attachment";
import { Common } from "@/services/backend/artefacts/common";

export interface Report extends Artefact, Immutability, Common {
  number: string;
  date: string | null;
  createdBy: string;
  createdByCompany: Address;
  objectAddress: Address;
  createdFor: Address;
  workOrderId: EntityId;
  workOrderPositionId: EntityId;
  airHandlingUnitId: EntityId;
  laboratories: LabData[];
  inspectionDate: string | null;
  inspectionConductedBy: string;
  nextInspectionDate: string | null;
  vdi6022: VDI6022;
  items: Item[];
  structuralAssessments: StructuralAssessment[];
  visualAssessments: VisualAssessment[];
  microbialAssessments: MicrobialAssessment[];
  photos: Photo[];
  ahuImageFilePrefix: string;
  ahuImageFileId: string;
  attachments: Attachment[];
}

export enum VDI6022 {
  Zero = "",
  Passed = "passed",
  Failed = "failed",
}

export function vdi6022FromString(value: string): VDI6022 {
  switch (value) {
    case VDI6022.Passed:
      return VDI6022.Passed;
    case VDI6022.Failed:
      return VDI6022.Failed;
    default:
      return VDI6022.Zero;
  }
}

export interface Item {
  id: string;
  reportId: string;
  order: number;
  workOrderPositionId: EntityId;
  airHandlingUnitId: EntityId;
  componentId: EntityId;
  microbialInspectionResult: Result;
  structuralInspectionResult: Result;
  visualInspectionResult: Result;
  recommendations: string[];
  result: Result;
}

export interface LabData {
  reportId: UUID;
  labId: UUID;
  name: string;
  street: string;
  postalCode: string;
  city: string;
  phone: string;
}
