import { useParams } from "react-router-dom";
import { useHtzOrderShowQuery } from "@/services/backend/htz/orders/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Order } from "@/services/backend/htz/orders/order";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { DeleteOrderDialog } from "@/routes/gesec/processes/[processId]/htz/orders/[orderId]/_components/delete-order-dialog";
import { OrderCardSkeleton } from "@/routes/gesec/processes/[processId]/htz/orders/[orderId]/data/_components/order-card";
import {
  ErrArtefactNotReadyContextProvider,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import t from "@/lang/lang";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";
import { hasFieldError } from "@/shared/app-lib/errors/validation-error";
import {
  ArtefactPage,
  ArtefactPageActions,
  ArtefactPageContent,
  ArtefactPageHeader,
  ArtefactPageNavigation,
  ArtefactPageNavigationLink,
  ArtefactPageNavigationOutlet,
} from "@/shared/service-manager/default-ui/artefact-page/artefact-page";

export function OrderRoute() {
  const { orderId } = useParams();

  const {
    data: order,
    isLoading,
    error,
  } = useHtzOrderShowQuery({
    id: orderId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <PageSkeleton />;
  }

  return <Page order={order!} />;
}

export function Page({ order }: { order: Order }) {
  return (
    <PersistenceContextProvider>
      <ErrArtefactNotReadyContextProvider>
        <ArtefactPage>
          <ArtefactPageHeader>
            <TabNavigation />
            <ArtefactPageActions>
              <PersistenceStateUsingContext className="mr-2" />
              <DeleteOrderDialog orderId={order.id} />
            </ArtefactPageActions>
          </ArtefactPageHeader>
          <ArtefactPageContent>
            <ArtefactPageNavigationOutlet />
          </ArtefactPageContent>
        </ArtefactPage>
      </ErrArtefactNotReadyContextProvider>
    </PersistenceContextProvider>
  );
}

export function PageSkeleton() {
  return (
    <div className="animate-pulse">
      <ArtefactPage>
        <ArtefactPageHeader>
          <TabNavigation />
          <ArtefactPageActions>
            <PersistenceStateUsingContext className="mr-2" />
            <Button variant="outline" disabled>
              <Trash2 className="h-4 w-4" />
            </Button>
          </ArtefactPageActions>
        </ArtefactPageHeader>
        <ArtefactPageContent>
          <OrderCardSkeleton />
        </ArtefactPageContent>
      </ArtefactPage>
    </div>
  );
}

function TabNavigation() {
  const { notReadyError } = useErrArtefactNotReady();

  const hasDataError = hasFieldError(notReadyError, "date");
  const hasPosError = hasFieldError(notReadyError, "positions");
  const hasAddrError = hasFieldError(
    notReadyError,
    "postalAddress",
    "serviceAddress",
  );
  const hasRepError = hasFieldError(notReadyError, "representative");

  const links = [
    { to: "data", label: t("Auftragseingang"), hasError: hasDataError },
    { to: "positions", label: t("Leistungen"), hasError: hasPosError },
    { to: "addresses", label: t("Adressen"), hasError: hasAddrError },
    { to: "representative", label: t("Betreuer"), hasError: hasRepError },
  ];

  return (
    <ArtefactPageNavigation>
      {links.map((link) => (
        <ArtefactPageNavigationLink
          key={link.to}
          to={link.to}
          hasValidationError={link.hasError}
        >
          {link.label}
        </ArtefactPageNavigationLink>
      ))}
    </ArtefactPageNavigation>
  );
}
