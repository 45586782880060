import { CustomerAddressCard } from "@/shared/components/domain/addresses/customer-address-card";
import { ServiceAddressCard } from "@/shared/components/domain/addresses/service-address-card";
import { useOfferContext } from "@/routes/gesec/processes/[processId]/htz/offers/_components/offer-context";
import { Address } from "@/services/backend/addresses/address/address";
import {
  updatePostalAddress,
  updateServiceAddress,
} from "@/services/backend/htz/offers/offer";
import t from "@/lang/lang";
import {
  fieldErrorsForPrefix,
  resetField,
} from "@/shared/app-lib/errors/validation-error";

export function AddressesRoute() {
  const { offer, onOfferChange, validationError, setValidationError } =
    useOfferContext();

  const resetValidationErrorField = (prefix: string) => (field: string) => {
    const prefixedField = `${prefix}${field}`;
    setValidationError(resetField(validationError, prefixedField));
  };

  const onCustomerAddressChange = (customerAddress: Address) => {
    onOfferChange(updatePostalAddress(offer, customerAddress));
  };

  const onServiceAddressChange = (serviceAddress: Address) => {
    onOfferChange(updateServiceAddress(offer, serviceAddress));
  };

  return (
    <div className="space-y-4">
      <CustomerAddressCard
        customerAddress={offer.postalAddress}
        onCustomerAddressChange={onCustomerAddressChange}
        description={t("Adresse an welche das Angebot geschickt werden soll.")}
        fieldErrors={fieldErrorsForPrefix(validationError, "postalAddress.")}
        resetFieldError={resetValidationErrorField("postalAddress.")}
        disabled={offer.immutable}
      />
      <ServiceAddressCard
        address={offer.serviceAddress}
        onAddressChange={onServiceAddressChange}
        fieldErrors={fieldErrorsForPrefix(validationError, "serviceAddress.")}
        resetFieldError={resetValidationErrorField("serviceAddress.")}
        disabled={offer.immutable}
      />
    </div>
  );
}
