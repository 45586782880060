import { backendApi } from "@/services/backend/api";
import { Component, Kind } from "@/services/backend/vbs/treatments/component";
import { SegmentKind } from "@/services/backend/vbs/treatments/segmentKind";
import { Treatment } from "@/services/backend/vbs/treatments/treatment";
import { ProcessTreatment } from "@/services/backend/vbs/treatments/process-treatment";
import { Filter } from "@/shared/lib/filter/filter";
import { Formula } from "@/services/backend/vbs/treatments/formula";

const treatmentApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    vbsTreatmentCreate: build.mutation<Treatment, CreateRequest>({
      query: (request) => ({
        url: "/vbs/treatment",
        method: "POST",
        body: request,
      }),
      invalidatesTags: [{ type: "VbsTreatment", id: "LIST" }],
    }),
    vbsTreatmentCreateFromOld: build.mutation<Treatment, CreateFromOldRequest>({
      query: (request) => ({
        url: "/vbs/treatment/create-from-old",
        method: "POST",
        body: request,
      }),
      invalidatesTags: [{ type: "VbsTreatment", id: "LIST" }],
    }),
    vbsTreatmentShow: build.query<Treatment, ShowRequest>({
      query: (request) => ({
        url: `/vbs/treatment`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "VbsTreatment", id: result.id }] : [],
    }),
    vbsTreatmentShowProcessTreatment: build.query<
      ProcessTreatment,
      ShowProcessTreatmentRequest
    >({
      query: (request) => ({
        url: `/vbs/treatment/process-treatment`,
        method: "GET",
        params: request,
      }),
    }),
    vbsTreatmentList: build.query<List, Partial<ListRequest>>({
      query: (request) => ({
        url: "/vbs/treatment/list",
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) =>
        result ? [{ type: "VbsTreatment", id: "LIST" }] : [],
    }),
    vbsTreatmentListAvailableComponentKinds: build.query<
      AvailableComponentKinds,
      ListAvailableComponentKindsRequest
    >({
      query: (request) => ({
        url: "/vbs/treatment/list-available-component-kinds",
        method: "GET",
        params: request,
      }),
    }),
    vbsTreatmentCopyBaseData: build.mutation<Treatment, CopyBaseDataRequest>({
      query: (request) => ({
        url: "/vbs/treatment/copy-base-data",
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: request.destinationId },
        "VbsCalculation",
        "VbsOffer",
      ],
    }),
    vbsTreatmentAddArea: build.mutation<Treatment, AddAreaRequest>({
      query: (request) => ({
        url: `/vbs/treatment/area`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: request.id },
        "VbsCalculation",
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
      ],
    }),
    vbsTreatmentRenameArea: build.mutation<Treatment, RenameAreaRequest>({
      query: (request) => ({
        url: `/vbs/treatment/area`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: request.id },
        "VbsCalculation",
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
      ],
    }),
    vbsTreatmentDeleteArea: build.mutation<Treatment, DeleteAreaRequest>({
      query: (request) => ({
        url: `/vbs/treatment/area`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: request.id },
        "VbsCalculation",
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
      ],
    }),
    vbsTreatmentAddPosition: build.mutation<Treatment, AddPositionRequest>({
      query: (request) => ({
        url: `/vbs/treatment/position`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: request.id },
        "VbsCalculation",
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
      ],
    }),
    vbsTreatmentDeletePosition: build.mutation<
      Treatment,
      DeletePositionRequest
    >({
      query: (request) => ({
        url: `/vbs/treatment/position`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: request.id },
        "VbsCalculation",
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
      ],
    }),
    vbsTreatmentUpdateComponent: build.mutation<
      Treatment,
      UpdateComponentRequest
    >({
      query: (request) => ({
        url: `/vbs/treatment/position/component`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: request.id },
        "VbsCalculation",
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
      ],
    }),
    vbsTreatmentUpdateCycle: build.mutation<Treatment, UpdateCycleRequest>({
      query: (request) => ({
        url: `/vbs/treatment/position/cycle`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: request.id },
        "VbsCalculation",
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
      ],
    }),
    vbsTreatmentUpdateFormula: build.mutation<Treatment, UpdateFormulaRequest>({
      query: (request) => ({
        url: `/vbs/treatment/position/formula`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: request.id },
        "VbsCalculation",
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
      ],
    }),
    vbsTreatmentUpdateComment: build.mutation<Treatment, UpdateCommentRequest>({
      query: (request) => ({
        url: `/vbs/treatment/position/comment`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: request.id },
        "VbsCalculation",
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
      ],
    }),
    vbsTreatmentUpdateTipCommission: build.mutation<
      Treatment,
      UpdateTipCommissionRequest
    >({
      query: (request) => ({
        url: `/vbs/treatment/position/tip-commission`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: request.id },
        "VbsCalculation",
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
      ],
    }),
    vbsTreatmentUpdateTipCommissionAll: build.mutation<
      Treatment,
      UpdateTipCommissionAllRequest
    >({
      query: (request) => ({
        url: `/vbs/treatment/tip-commission-all`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: () => ["VbsTreatment", "VbsCalculation", "VbsOffer"],
    }),
    vbsTreatmentUpdateCycleDiscount: build.mutation<
      Treatment,
      UpdateCycleDiscountRequest
    >({
      query: (request) => ({
        url: `/vbs/treatment/position/cycle-discount`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: request.id },
        "VbsCalculation",
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
      ],
    }),
    vbsTreatmentAssignWorkOrder: build.mutation<
      Treatment,
      AssignWorkOrderRequest
    >({
      query: (request) => ({
        url: `/vbs/treatment/position/assigned-work-order`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: request.id },
        "VbsCalculation",
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
      ],
    }),
    vbsTreatmentUpdatePositionCompletion: build.mutation<
      Treatment,
      UpdatePositionCompletion
    >({
      query: (request) => ({
        url: `/vbs/treatment/position/completion`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: request.id },
        "VbsCalculation",
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
      ],
    }),
    vbsTreatmentUpdateActuallyTreated: build.mutation<
      Treatment,
      UpdateActuallyTreatedRequest
    >({
      query: (request) => ({
        url: `/vbs/treatment/position/actually-treated`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: request.id },
        "VbsCalculation",
      ],
    }),
    vbsTreatmentSetImmutable: build.mutation<Treatment, SetImmutableRequest>({
      query: (request) => ({
        url: `/vbs/treatment/immutable`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: request.id },
        "VbsCalculation",
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
      ],
    }),
    vbsTreatmentDelete: build.mutation<Treatment, DeleteRequest>({
      query: (request) => ({
        url: `/vbs/treatment}`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsTreatment", id: "LIST" },
        { type: "VbsTreatment", id: request.id },
        "VbsCalculation",
      ],
    }),
  }),
});

export const {
  useVbsTreatmentCreateMutation,
  useVbsTreatmentCreateFromOldMutation,
  useVbsTreatmentShowQuery,
  useVbsTreatmentShowProcessTreatmentQuery,
  useVbsTreatmentListQuery,
  useVbsTreatmentListAvailableComponentKindsQuery,
  useVbsTreatmentCopyBaseDataMutation,
  useVbsTreatmentAddAreaMutation,
  useVbsTreatmentRenameAreaMutation,
  useVbsTreatmentDeleteAreaMutation,
  useVbsTreatmentAddPositionMutation,
  useVbsTreatmentDeletePositionMutation,
  useVbsTreatmentUpdateComponentMutation,
  useVbsTreatmentUpdateCycleMutation,
  useVbsTreatmentUpdateFormulaMutation,
  useVbsTreatmentUpdateCommentMutation,
  useVbsTreatmentUpdateTipCommissionMutation,
  useVbsTreatmentUpdateTipCommissionAllMutation,
  useVbsTreatmentUpdateCycleDiscountMutation,
  useVbsTreatmentAssignWorkOrderMutation,
  useVbsTreatmentUpdatePositionCompletionMutation,
  useVbsTreatmentUpdateActuallyTreatedMutation,
  useVbsTreatmentSetImmutableMutation,
  useVbsTreatmentDeleteMutation,
} = treatmentApi;

export interface CreateRequest {
  id: string;
  processId: string;
  ownerType: string;
  ownerId: string;
}

export interface CreateFromOldRequest {
  oldId: string;
  newId: string;
  processId: string;
}

export interface ShowRequest {
  id: string;
}

export interface ShowProcessTreatmentRequest {
  processId: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  process?: Filter<string>;
  ownerType?: Filter<string>;
  owner?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface List {
  treatments: Treatment[];
  count: number;
}

export interface ListAvailableComponentKindsRequest {}

export interface AvailableComponentKinds {
  [segmentKind: string]: Kind[];
}

export interface CopyBaseDataRequest {
  destinationId: string;
  sourceId: string;
}

export interface AddAreaRequest {
  id: string;
  segment: SegmentKind;
  area: string;
}

export interface RenameAreaRequest {
  id: string;
  segment: SegmentKind;
  old: string;
  new: string;
}

export interface DeleteAreaRequest {
  id: string;
  segment: SegmentKind;
  area: string;
}

export interface AddPositionRequest {
  id: string;
  positionId: string;
  segment: SegmentKind;
  area: string;
  componentKind: Kind;
}

export interface DeletePositionRequest {
  id: string;
  positionId: string;
}

export interface UpdateComponentRequest {
  id: string;
  positionId: string;
  component: Component;
}

export interface UpdateCycleRequest {
  id: string;
  positionId: string;
  cycle: number;
}

export interface UpdateFormulaRequest {
  id: string;
  positionId: string;
  formula: Formula;
}

export interface UpdateCommentRequest {
  id: string;
  positionId: string;
  comment: string;
}

export interface UpdateTipCommissionRequest {
  id: string;
  positionId: string;
  rate: number;
}

export interface UpdateTipCommissionAllRequest {
  id: string;
  rate: number;
}

export interface UpdateCycleDiscountRequest {
  id: string;
  positionId: string;
  discount: number;
}

export interface AssignWorkOrderRequest {
  id: string;
  positionId: string;
  workOrderId: string;
}

export interface UpdateActuallyTreatedRequest {
  id: string;
  positionId: string;
  component: Component;
}

export interface UpdatePositionCompletion {
  id: string;
  positionId: string;
  completed: boolean;
  forceCompletion: boolean;
}

export interface SetImmutableRequest {
  id: string;
  reason: string;
}

export interface DeleteRequest {
  id: string;
}
