import t from "@/lang/lang";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";
import { Address } from "@/services/backend/addresses/address/address";

interface AddressCardProps {
  address: Address;
  onAddressChange: (address: Address) => void;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
  disabled?: boolean;
}

export function AddressCard({
  address,
  onAddressChange,
  fieldErrors = undefined,
  resetFieldError = undefined,
  title = t("Adresse"),
  description = t("Adresse"),
  disabled = false,
}: AddressCardProps) {
  const errorMessages = (field: string): string[] => {
    if (!field || field === "") {
      return [];
    }

    return fieldErrors ? fieldErrors[field] ?? [] : [];
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-4 sm:grid-cols-2">
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="company">{t("Firma")}</Label>
          <Input
            type="text"
            id="company"
            className={
              errorMessages("company").length > 0
                ? "border-red-500 shadow-md"
                : ""
            }
            placeholder={t("Firma")}
            value={address.company}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("company");
              }
              onAddressChange({
                ...address,
                company: e.target.value,
              });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("company")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="department">{t("Abteilung")}</Label>
          <Input
            type="text"
            id="department"
            className={
              errorMessages("department").length > 0
                ? "border-red-500 shadow-md"
                : ""
            }
            placeholder={t("Abteilung")}
            value={address.department}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("department");
              }
              onAddressChange({
                ...address,
                department: e.target.value,
              });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("department")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="contact">{t("Ansprechpartner")}</Label>
          <Input
            type="text"
            id="contact"
            className={
              errorMessages("contact").length > 0
                ? "border-red-500 shadow-md"
                : ""
            }
            placeholder={t("Ansprechpartner")}
            value={address.contact}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("contact");
              }
              onAddressChange({
                ...address,
                contact: e.target.value,
              });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("contact")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="street">{t("Straße")}</Label>
          <Input
            type="text"
            id="street"
            className={
              errorMessages("street").length > 0
                ? "border-red-500 shadow-md"
                : ""
            }
            placeholder={t("Straße")}
            value={address.street}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("street");
              }
              onAddressChange({
                ...address,
                street: e.target.value,
              });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("street")} />
        </div>
        <div className="flex space-x-4">
          <div className="grid max-w-xs items-center gap-1.5">
            <Label htmlFor="postalCode">{t("PLZ")}</Label>
            <Input
              type="text"
              id="postalCode"
              className={
                errorMessages("postalCode").length > 0
                  ? "border-red-500 shadow-md"
                  : ""
              }
              placeholder={t("PLZ")}
              value={address.postalCode}
              onChange={(e) => {
                if (resetFieldError) {
                  resetFieldError("postalCode");
                }
                onAddressChange({
                  ...address,
                  postalCode: e.target.value,
                });
              }}
              disabled={disabled}
            />
            <InputErrorMessages errors={errorMessages("postalCode")} />
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="city">{t("Stadt")}</Label>
            <Input
              type="text"
              id="city"
              className={
                errorMessages("city").length > 0
                  ? "border-red-500 shadow-md"
                  : ""
              }
              placeholder={t("Stadt")}
              value={address.city}
              onChange={(e) => {
                if (resetFieldError) {
                  resetFieldError("city");
                }
                onAddressChange({
                  ...address,
                  city: e.target.value,
                });
              }}
              disabled={disabled}
            />
            <InputErrorMessages errors={errorMessages("city")} />
          </div>
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="country">{t("Land")}</Label>
          <Input
            type="text"
            id="country"
            className={
              errorMessages("country").length > 0
                ? "border-red-500 shadow-md"
                : ""
            }
            placeholder={t("Land")}
            value={address.country}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("country");
              }
              onAddressChange({
                ...address,
                country: e.target.value,
              });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("country")} />
        </div>
      </CardContent>
    </Card>
  );
}
