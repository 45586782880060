import { useCallback, useEffect, useState } from "react";
import { MonitorDetailSummaryComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/monitoring/monitor-summary-component";
import {
  useQpmBuildingAddressListQuery,
  useQpmBuildingListQuery,
} from "@/services/backend/qpm/buildings/service";
import {
  Building,
  BuildingAddress,
  Level,
} from "@/services/backend/qpm/buildings/types";
import { BuildingSelector } from "@/routes/gemex/processes/qpm/shared/components/building-selector-component";
import { LevelSelector } from "@/routes/gemex/processes/qpm/shared/components/level-selector-component";
import { Skeleton } from "@/shared/components/ui/skeleton";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Requirement } from "@/services/backend/qpm/requirements/types";
import { RequirementLevelComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/monitoring/requirement-level-component";
import { AlertWarning } from "@/shared/components/ui/alert-warning";
import { Loader, MoreVertical } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Button } from "@/shared/components/ui/button";
import { useParams, useSearchParams } from "react-router-dom";
import { URLParams } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/create-requirement";
import { Separator } from "@/shared/components/ui/separator";
import { useQpmRequirementGetQuery } from "@/services/backend/qpm/requirements/service";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";

export type MaterialInventoryProps = {
  requirement: Requirement;
  addressId: string;
  customerId: string;
};

export function MaterialRequirementRoute() {
  const { processId, requirementId } = useParams<URLParams>();
  const [currentAddress, setCurrentAddress] = useState<BuildingAddress>();

  const {
    data: process,
    error: processDataError,
    isLoading: processDataIsLoading,
  } = useProcessesProcessShowQuery({
    id: processId ?? "",
  });

  const {
    data: requirement,
    error: errorRequirement,
    isLoading: isLoadingRequirement,
  } = useQpmRequirementGetQuery({
    artefactMetaId: requirementId ?? "",
  });

  const {
    data: addressList,
    error,
    isLoading: addressIsLoading,
  } = useQpmBuildingAddressListQuery(
    {
      customerId: process!.customerId,
    },
    { skip: !process },
  );

  useEffect(() => {
    if (addressList && addressList.addresses.length > 0) {
      setCurrentAddress(addressList.addresses[0]);
    }
  }, [addressList, setCurrentAddress]);

  if (processDataIsLoading) {
    return <Loader />;
  }

  if (processDataError || !process) {
    return <div>Fehler beim Laden der Vorgangsdaten</div>;
  }

  if (addressIsLoading) {
    return <Loader />;
  }

  if (error || !currentAddress) {
    return <div>Fehler beim Laden der Addresse</div>;
  }

  if (errorRequirement) {
    return <RTKQueryErrorAlert error={errorRequirement} />;
  }

  if (isLoadingRequirement) {
    return (
      <div className="mt-2">
        <Skeleton className="mb-2 h-24 w-full" />
        <Skeleton className="mb-2 h-24 w-full" />
        <Skeleton className="h-24 w-full" />
      </div>
    );
  }

  return (
    requirement &&
    process && (
      <MaterialRequirement
        requirement={requirement}
        addressId={currentAddress.id}
        customerId={process.customerId}
      />
    )
  );
}

export function MaterialRequirement({
  requirement,
  addressId,
  customerId,
}: MaterialInventoryProps) {
  const [, setSearchParams] = useSearchParams();
  const [selectedBuilding, setSelectedBuilding] = useState<Building>();
  const [selectedLevel, setSelectedLevel] = useState<Level>();
  const [currentBuildingId, setCurrentBuildingId] = useState<string>();
  const [currentLevelId, setCurrentLevelId] = useState<string>();

  const {
    data: buildingList,
    error: errorBuildings,
    isLoading: isLoadingBuildings,
  } = useQpmBuildingListQuery({ customerId });

  useEffect(() => {
    if (
      !selectedBuilding &&
      buildingList &&
      buildingList.buildings.length > 0
    ) {
      const initialBuilding = buildingList.buildings[0];
      setSelectedBuilding(initialBuilding);
      setCurrentBuildingId(initialBuilding.id);
      if (initialBuilding.components.length > 0) {
        setSelectedLevel(initialBuilding.components[0]);
      }
    }
  }, [buildingList, selectedBuilding]);

  useEffect(() => {
    if (buildingList) {
      const building = buildingList.buildings.find(
        (b) => b.id === currentBuildingId,
      );
      if (building) {
        setSelectedBuilding(building);
        setCurrentBuildingId(building.id);
        const currentLevel = building.components.find(
          (level) => level.base.id === currentLevelId,
        );
        if (currentLevel) {
          setSelectedLevel(currentLevel);
        } else if (building.components.length > 0) {
          setSelectedLevel(building.components[0]);
        }
      }
    }
  }, [buildingList, currentBuildingId, currentLevelId]);

  const handleNewBuildingCreateCallback = (building: Building) => {
    setSelectedBuilding(building);
    setCurrentBuildingId(building.id);
    setSelectedLevel(undefined);
    setCurrentLevelId(undefined);
  };

  const handleBuildingSelect = (buildingId: string) => {
    const building = buildingList?.buildings.find((b) => b.id === buildingId);
    if (building && building !== selectedBuilding) {
      setSelectedBuilding(building);
      setCurrentBuildingId(building.id);
      setSelectedLevel(building.components[0]);
      setCurrentLevelId(building.components[0]?.base?.id);
    }
  };

  const handleLevelSelect = (levelId: string) => {
    const level = selectedBuilding?.components.find(
      (l) => l.base.id === levelId,
    );
    if (level && level !== selectedLevel) {
      setSelectedLevel(level);
      setCurrentLevelId(level.base.id);
    }
  };

  const onLevelCreated = (level: Level) => {
    setSelectedLevel(level);
    setCurrentLevelId(level.base.id);
  };

  const setAreaSegmentStateOpen = useCallback(() => {
    setSearchParams({ open: "true" });
  }, [setSearchParams]);

  const setAreaSegmentStateClosed = useCallback(() => {
    setSearchParams({ open: "false" });
  }, [setSearchParams]);

  if (errorBuildings) {
    return <RTKQueryErrorAlert error={errorBuildings} />;
  }

  if (isLoadingBuildings) {
    return (
      <div className="flex items-center justify-between gap-2 p-2">
        <Skeleton className="h-12 w-full" />
        <Skeleton className="h-12 w-full" />
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-3">
      {!buildingList ||
        (buildingList.buildings.length === 0 && (
          <AlertWarning title={t("Hinweis")}>
            <div className="flex flex-col">
              <span>{t("Es wurden noch keine Gebäudedaten hinterlegt.")}</span>
              <span>
                {t(
                  "Um mit der Bestandsaufnahme beginnen zu können, legen Sie bitte zuerst ein Gebäude, dann eine Ebene und schließlich einen Bereich an.",
                )}
              </span>
            </div>
          </AlertWarning>
        ))}
      {selectedBuilding &&
        (!selectedBuilding.components ||
          selectedBuilding.components.length === 0) && (
          <AlertWarning title={t("Hinweis")}>
            <div className="flex flex-col">
              <span>
                {t("Für dieses Gebäude wurde noch keine Ebene hinterlegt.")}
              </span>
              <span>
                {t(
                  "Um mit der Bestandsaufnahme beginnen zu können, legen Sie bitte eine Ebene und schließlich einen Bereich an.",
                )}
              </span>
            </div>
          </AlertWarning>
        )}
      <div className="flex items-center justify-between">
        <div className="my-2 flex gap-2">
          <BuildingSelector
            buildingList={buildingList}
            selectedBuilding={selectedBuilding}
            handleBuildingSelect={handleBuildingSelect}
            customerId={customerId}
            addressId={addressId}
            onBuildingCreateCallback={handleNewBuildingCreateCallback}
          />
          <div className="flex items-center">
            <LevelSelector
              levels={selectedBuilding?.components}
              selectedLevel={selectedLevel}
              handleLevelSelect={handleLevelSelect}
              buildingId={selectedBuilding?.id}
              onLevelCreatedCallback={onLevelCreated}
            />
          </div>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size="icon" variant="outline" className="h-8 w-8">
              <MoreVertical className="h-3.5 w-3.5" />
              <span className="sr-only">More</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={setAreaSegmentStateOpen}>
              {t("Bereiche aufklappen")}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={setAreaSegmentStateClosed}>
              {t("Bereiche zuklappen")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {selectedBuilding && selectedLevel && (
        <RequirementLevelComponent
          buildingId={selectedBuilding.id}
          buildingName={selectedBuilding?.name}
          selectedLevel={selectedLevel}
          requirement={requirement}
        />
      )}
      {selectedBuilding && selectedLevel && (
        <div className="flex flex-col gap-2">
          <Separator />
          <MonitorDetailSummaryComponent
            title={`${t("Gesamtübersicht")} ${selectedBuilding.name} - ${selectedLevel.base.name}`}
            requirementId={requirement.artefactData.id}
            buildingId={selectedBuilding.id}
            levelId={selectedLevel.base.id}
          />
        </div>
      )}
    </div>
  );
}
