import { useParams } from "react-router-dom";
import { Card } from "@/shared/components/ui/card";
import { OfferTable } from "@/routes/gesec/processes/[processId]/vbs/offers/_components/offer-table";
import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/service-manager/default-ui/milestone-page/milestone-page";
import t from "@/lang/lang";
import { CreateOfferDialog } from "@/routes/gesec/processes/[processId]/vbs/offers/_components/create-offer-dialog";

export function OfferIndexRoute() {
  const { processId } = useParams();

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle className="border-none pb-0">
          {t("Angebote")}
        </MilestonePageTitle>
        <MilestonePageActions>
          <CreateOfferDialog processId={processId!} />
        </MilestonePageActions>
      </MilestonePageHeader>
      <MilestonePageContent>
        <Card>
          <OfferTable processId={processId!} />
        </Card>
      </MilestonePageContent>
    </MilestonePage>
  );
}
