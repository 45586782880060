import { Report } from "@/services/backend/htz/report/report";
import { useState } from "react";
import {
  UpdateCreatedByCompanyRequest,
  useHtzReportUpdateCreatedByCompanyMutation,
} from "@/services/backend/htz/report/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { AddressCard } from "@/shared/components/domain/addresses/address-card";
import t from "@/lang/lang";
import {
  fieldErrorsForPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";

export function CreatedByCompanyCard({ report }: { report: Report }) {
  const [request, setRequest] = useState<UpdateCreatedByCompanyRequest>({
    id: report.id,
    createdBy: report.createdByCompany,
  });
  const [patch, { isLoading, error, isSuccess, reset }] =
    useHtzReportUpdateCreatedByCompanyMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    patch,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  return (
    <AddressCard
      title={t("Erstellt durch")}
      description={t("Adresse der Niederlassung, die den Bericht erstellt")}
      address={request.createdBy}
      onAddressChange={(createdBy) => setRequest({ id: request.id, createdBy })}
      fieldErrors={fieldErrorsForPrefix(notReadyError, "createdByCompany.")}
      resetFieldError={(field) =>
        resetNotReadyErrorField(`createdByCompany.${field}`)
      }
      disabled={report.immutable}
    />
  );
}
