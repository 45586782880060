import { useParams } from "react-router-dom";
import { Card } from "@/shared/components/ui/card";
import { WorkOrdersTable } from "@/routes/gesec/processes/[processId]/htz/work-orders/_components/work-orders-table";
import { CreateWorkOrderButton } from "@/routes/gesec/processes/[processId]/htz/work-orders/_components/create-work-order-button";
import t from "@/lang/lang";
import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/service-manager/default-ui/milestone-page/milestone-page";

export function WorkOrderIndexRoute() {
  const { processId } = useParams();

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Arbeitsscheine")}</MilestonePageTitle>
        <MilestonePageActions>
          <CreateWorkOrderButton processId={processId!} />
        </MilestonePageActions>
      </MilestonePageHeader>
      <MilestonePageContent>
        <Card>
          <WorkOrdersTable processId={processId!} />
        </Card>
      </MilestonePageContent>
    </MilestonePage>
  );
}
