import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { Skeleton } from "@/shared/components/ui/skeleton";

export function CardSkeleton({ title }: { title?: string }) {
  return (
    <Card className="animate-pulse">
      <CardHeader>
        <CardTitle>{title ?? <Skeleton className="h-8 w-1/2" />}</CardTitle>
        <Skeleton className="h-4 w-1/4" />
      </CardHeader>
      <CardContent>
        <Skeleton className="h-4 w-1/4" />
        <Skeleton className="h-4 w-1/4" />
        <Skeleton className="h-4 w-1/4" />
        <Skeleton className="h-4 w-1/4" />
      </CardContent>
    </Card>
  );
}
