import {
  generateSampleNumber,
  newSample,
  Sample,
} from "@/services/backend/samples/sample/sample";
import { EntityId } from "@/shared/nidavellir/types/entity-id";
import { ArtefactNamespace } from "@/shared/service-manager/artefact/artefact-namespace";
import { Kind } from "@/services/backend/samples/sample/kind";

export interface DustDensitySample extends Sample {
  unit: string;
  referenceValue: string;
  value: string;
}

export function newDustDensitySample(
  id: string,
  artefactId: EntityId,
  artefactNamespace: ArtefactNamespace | null,
  location: string,
  spot: string,
): DustDensitySample {
  const base = newSample(
    id,
    generateSampleNumber(),
    Kind.DustDensity,
    artefactId,
    artefactNamespace,
  );

  return {
    ...base,
    location,
    spot,
    unit: "g/m²",
    referenceValue: "<8",
    value: "",
  };
}
