import {
  Position,
  updateAhu,
  WorkOrder,
} from "@/services/backend/htz/work-order/work-order";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { NullUUID } from "@/shared/lib/utilities/uuid";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/shared/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { Button } from "@/shared/components/ui/button";
import { useState } from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import t from "@/lang/lang";
import { cn } from "@/shared/lib/utils";
import { CreateAhuInput } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/create-ahu-input";
import { Separator } from "@/shared/components/ui/separator";
import {
  useGetAirHandlingUnit,
  useListAirHandlingUnit,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/air-handling-unit-hooks";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { hasFieldError } from "@/shared/app-lib/errors/validation-error";
import { TableCell } from "@/shared/components/ui/table";

interface AhuSelectProps {
  workOrder: WorkOrder;
  position: Position;
  onPositionChange: (position: Position) => void;
  disabled?: boolean;
}

export function AhuSelectCell({
  workOrder,
  position,
  onPositionChange,
  disabled,
}: AhuSelectProps) {
  const [open, setOpen] = useState(false);
  const { data: airHandlingUnits } = useListAirHandlingUnit(
    workOrder.id,
    workOrder.customerId,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();
  const errorKey = `position.${position.id}.airHandlingUnitId`;
  const hasError = hasFieldError(notReadyError, errorKey);

  const value = position.airHandlingUnitId ?? undefined;
  const onValueChange = (ahuId: string | null) => {
    if (ahuId === NullUUID) {
      onPositionChange(updateAhu(position, null));
    }

    onPositionChange(updateAhu(position, ahuId));
    resetNotReadyErrorField(errorKey);
  };

  return (
    <TableCell className="py-2">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild disabled={disabled}>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn(
              "h-8 w-[200px] justify-between",
              hasError && "shadow-m border-red-500 shadow-md",
            )}
          >
            {value ? <AhuName ahuId={value} /> : t("Auswählen")}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0" align="start">
          <Command filter={filter}>
            <CommandInput placeholder={t("Anlage suchen ...")} />
            <CommandList>
              <CommandEmpty className="p-4">
                <span>{t("Keine Anlage gefunden.")}</span>
              </CommandEmpty>
              <CommandGroup>
                {[...(airHandlingUnits ?? [])].sort(byName).map((ahu) => (
                  <CommandItem
                    key={ahu.id}
                    value={key(ahu)}
                    onSelect={(currentValue) => {
                      const id = getId(currentValue);
                      onValueChange(id === value ? null : id);
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        value === ahu.id ? "opacity-100" : "opacity-0",
                      )}
                    />
                    {ahu.name}
                  </CommandItem>
                ))}
                {airHandlingUnits && airHandlingUnits.length === 0 && (
                  <CommandItem disabled>
                    {t("Keine Anlagen vorhanden.")}
                  </CommandItem>
                )}
              </CommandGroup>
              <Separator className="mb-2" />
              <div className="px-4 pb-4">
                <CreateAhuInput customerId={workOrder.customerId} />
              </div>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </TableCell>
  );
}

function AhuName({ ahuId }: { ahuId: string }) {
  const { data: airHandlingUnits } = useGetAirHandlingUnit(ahuId);

  return <span>{airHandlingUnits?.name}</span>;
}

/**
 * filter trims the id from the "key". This avoids
 * false positive matches based on characters in the id.
 *
 * Note that this will result in two results if two
 * air handling units have the same name. - However,
 * at least they can be uniquely displayed.
 *
 * If at a later point air handling units get
 * another human-readable identifier this problem
 * may be eased.
 *
 * @param value
 * @param search
 */
function filter(value: string, search: string): number {
  if (value.slice(0, value.length - 37).includes(search.toLowerCase())) {
    return 1;
  }
  return 0;
}

/**
 * key builds a unique identifier for the ahu which
 * includes the name. The name is needed for text
 * search. Since the name may not be unique identifying
 * the correct id for a given name value may not be
 * possible.
 */
function key(ahu: AirHandlingUnit): string {
  return `${ahu.name.toLowerCase()}-${ahu.id}`;
}

/**
 * getId extracts the id from the identifier generated
 * by "key".
 * @param keyStr
 */
function getId(keyStr: string): string {
  return keyStr.slice(-36);
}

function byName(a: AirHandlingUnit, b: AirHandlingUnit) {
  return a.name.localeCompare(b.name);
}
