import { H2 } from "@/shared/components/ui/typography";
import t from "@/lang/lang";
import {
  ArtefactIconConfirmation,
  ArtefactIconOffer,
  ArtefactIconOrder,
  ArtefactIconReport,
  ArtefactIconWorkOrder,
} from "@/shared/components/domain/artefact/artefact-icon";
import {
  Milestone,
  MilestoneIcon,
  Milestones,
  MilestoneStatus,
  MilestoneTitle,
} from "@/shared/service-manager/default-ui/process-page/process-page-overview";
import { useHtzOfferListQuery } from "@/services/backend/htz/offers/service";
import { useParams } from "react-router-dom";
import { formatDate } from "@/shared/lib/utilities/date";
import { useHtzOrderListQuery } from "@/services/backend/htz/orders/service";
import { useHtzConfirmationListQuery } from "@/services/backend/htz/confirmations/service";
import { useHtzWorkOrderListQuery } from "@/services/backend/htz/work-order/service";
import { useHtzReportListQuery } from "@/services/backend/htz/report/service";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/shared/components/ui/alert";

export default function OverviewRoute() {
  const { processId } = useParams();

  return (
    <div className="grid grid-cols-1 gap-4">
      <H2 className="border-none">{t("Übersicht")}</H2>
      <Alert>
        <AlertTitle>{t("Hinweis")}</AlertTitle>
        <AlertDescription>
          {t(
            "Die Übersicht ist aktuell noch mehr illustrativ. Es wird lediglich ausgewertet ob ein entsprechendes Dokument erstellt wurde. Eine konkretere Logik wird folgen.",
          )}
        </AlertDescription>
      </Alert>
      <Milestones>
        <OfferMilestone processId={processId!} />
        <OrderMilestone processId={processId!} />
        <ConfirmationMilestone processId={processId!} />
        <WorkOrderMilestone processId={processId!} />
        <ReportMilestone processId={processId!} />
      </Milestones>
    </div>
  );
}

function OfferMilestone({ processId }: { processId: string }) {
  const { data } = useHtzOfferListQuery({
    process: { active: true, values: [processId!] },
    immutable: { active: true, values: [true] },
  });

  const offer = data?.offers[data.offers.length - 1];

  return (
    <Milestone>
      <MilestoneIcon
        icon={ArtefactIconOffer}
        done={(data?.offers.length ?? 0) > 0}
      />
      <MilestoneTitle>{t("Angebot versenden")}</MilestoneTitle>
      {offer && (
        <MilestoneStatus>
          {`Das letzte Angebot wurde am ${formatDate(offer.immutabilitySetAt ?? "")} von ${offer.immutabilitySetBy} versendet.`}
        </MilestoneStatus>
      )}
    </Milestone>
  );
}

function OrderMilestone({ processId }: { processId: string }) {
  const { data } = useHtzOrderListQuery({
    process: { active: true, values: [processId!] },
  });

  const order = data?.orders[data.orders.length - 1];

  return (
    <Milestone>
      <MilestoneIcon
        icon={ArtefactIconOrder}
        done={(data?.orders.length ?? 0) > 0}
      />
      <MilestoneTitle>{t("Auftragseingang")}</MilestoneTitle>
      {order && (
        <MilestoneStatus>
          {`Die letzte Bestellung ging am ${formatDate(order.date ?? "")} ein.`}
        </MilestoneStatus>
      )}
    </Milestone>
  );
}

function ConfirmationMilestone({ processId }: { processId: string }) {
  const { data } = useHtzConfirmationListQuery({
    process: { active: true, values: [processId!] },
    immutable: { active: true, values: [true] },
  });

  const confirmation = data?.confirmations[data.confirmations.length - 1];

  return (
    <Milestone>
      <MilestoneIcon
        icon={ArtefactIconConfirmation}
        done={(data?.confirmations.length ?? 0) > 0}
      />
      <MilestoneTitle>{t("Auftragsbestätigung versendet")}</MilestoneTitle>
      {confirmation && (
        <MilestoneStatus>
          {`Die letzte Bestätigung wurde am ${formatDate(confirmation.date ?? "")} von ${confirmation.immutabilitySetBy} versendet.`}
        </MilestoneStatus>
      )}
    </Milestone>
  );
}

function WorkOrderMilestone({ processId }: { processId: string }) {
  const { data } = useHtzWorkOrderListQuery({
    process: { active: true, values: [processId!] },
    immutable: { active: true, values: [true] },
  });

  const workOrder = data?.workOrders[data.workOrders.length - 1];

  return (
    <Milestone>
      <MilestoneIcon
        icon={ArtefactIconWorkOrder}
        done={(data?.workOrders.length ?? 0) > 0}
      />
      <MilestoneTitle>{t("Arbeitsschein versendet")}</MilestoneTitle>
      {workOrder && (
        <MilestoneStatus>
          {`Die letzte Bestätigung wurde am ${formatDate(workOrder.endDate ?? "")} von ${workOrder.immutabilitySetBy} versendet.`}
        </MilestoneStatus>
      )}
    </Milestone>
  );
}

function ReportMilestone({ processId }: { processId: string }) {
  const { data } = useHtzReportListQuery({
    process: { active: true, values: [processId!] },
    immutable: { active: true, values: [true] },
  });

  const report = data?.reports[data.reports.length - 1];

  return (
    <Milestone>
      <MilestoneIcon
        icon={ArtefactIconReport}
        done={(data?.reports.length ?? 0) > 0}
      />
      <MilestoneTitle>{t("Bericht versendet")}</MilestoneTitle>
      {report && (
        <MilestoneStatus>
          {`Der letzte Bericht wurde am ${formatDate(report.date ?? "")} von ${report.immutabilitySetBy} versendet.`}
        </MilestoneStatus>
      )}
    </Milestone>
  );
}
