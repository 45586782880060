import { Report } from "@/services/backend/htz/report/report";
import t from "@/lang/lang";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { AirHandlingUnitName } from "@/shared/components/domain/htz/ahu/air-handling-unit-name";
import {
  useHtzWorkOrderListPositionQuery,
  useHtzWorkOrderListQuery,
} from "@/services/backend/htz/work-order/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { RefreshCw } from "lucide-react";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useHtzReportCopyDataFromWorkOrderPositionMutation } from "@/services/backend/htz/report/service";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Button } from "@/shared/components/ui/button";
import { ArtefactIcon } from "@/shared/components/domain/artefact/artefact-icon";
import { ArtefactKind } from "@/services/backend/artefacts/kind";

export function CopyDataDropdown({ report }: { report: Report }) {
  const {
    data: workOrderList,
    isLoading,
    error,
  } = useHtzWorkOrderListQuery({
    process: {
      active: true,
      values: [report.processId],
    },
    immutable: {
      active: true,
      values: [true],
    },
  });

  const [patch, { isLoading: patchIsLoading, error: patchError }] =
    useHtzReportCopyDataFromWorkOrderPositionMutation();

  const copyData = (positionId: string) => {
    if (patchIsLoading) return;

    patch({
      id: report.id,
      positionId,
    });
  };

  const { toast } = useToast();
  useEffect(() => {
    if (patchError) {
      toast({
        ...parseRTKQueryError(patchError),
        variant: "destructive",
      });
    }
  }, [patchError, toast]);

  const disabled = isLoading || patchIsLoading || report.immutable;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disabled}>
        <Button variant="outline">{t("Daten kopieren")}</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="min-w-60">
        <DropdownMenuGroup>
          <DropdownMenuLabel>{t("Arbeitsscheine")}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {error && <RTKQueryErrorAlert error={error} />}
          {isLoading && (
            <div>
              <RefreshCw className="mr-2 h-4 w-4" />{" "}
              {t("Lade Arbeitsscheine...")}
            </div>
          )}
          {workOrderList?.workOrders.map((workOrder) => (
            <div key={workOrder.id}>
              <div className="flex px-2 py-1.5">
                <ArtefactIcon
                  artefactKind={ArtefactKind.WorkOrder}
                  className="mr-2"
                />
                <ArtefactNumberById
                  artefactId={workOrder.id}
                  className="pl-2 text-sm"
                />
              </div>
              <DropdownItems workOrderId={workOrder.id} copyData={copyData} />
            </div>
          ))}
          {workOrderList?.workOrders.length === 0 && (
            <DropdownMenuItem>
              {t("Keine festgeschriebenen Arbeitsscheine gefunden")}
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function DropdownItems({
  workOrderId,
  copyData,
}: {
  workOrderId: string;
  copyData: (positionId: string) => void;
}) {
  const {
    data: positionList,
    isLoading,
    error,
  } = useHtzWorkOrderListPositionQuery({
    workOrder: {
      active: true,
      values: [workOrderId],
    },
  });

  return (
    <>
      {error && <RTKQueryErrorAlert error={error} />}
      {isLoading && (
        <div>
          <RefreshCw className="mr-2 h-4 w-4" /> {t("Lade Positionen...")}
        </div>
      )}
      {positionList?.positions.map((position) => (
        <DropdownMenuItem
          key={position.id}
          onClick={() => copyData(position.id)}
        >
          <span className="mr-2">Pos.: {position.number}</span>
          <AirHandlingUnitName id={position.airHandlingUnitId ?? ""} />
        </DropdownMenuItem>
      ))}
    </>
  );
}
