import { Item } from "@/services/backend/htz/inspection/sacatalog/structural-assessment-catalog";
import { EntityId } from "@/shared/nidavellir/types/entity-id";
import { Result } from "@/services/backend/htz/inspection/result";

export interface StructuralAssessment {
  id: string;
  airHandlingUnitId: EntityId;
  componentId: string;
  requirement: string;
  deficiency: string;
  recommendation: string;
  comment: string;
  createdAt: string;
  result: Result;
}

export function newStructural(
  id: string,
  airHandlingUnitId: string,
  componentId: string,
): StructuralAssessment {
  return {
    id,
    airHandlingUnitId,
    componentId,
    requirement: "",
    deficiency: "",
    recommendation: "",
    comment: "",
    createdAt: new Date().toISOString(),
    result: Result.ZERO,
  };
}

export function fromCatalogItem(
  id: string,
  airHandlingUnitId: EntityId,
  componentId: string,
  item: Item,
): StructuralAssessment {
  return {
    ...item,
    id,
    airHandlingUnitId,
    componentId,
    comment: "",
    createdAt: new Date().toISOString(),
  };
}
