import { backendApi } from "@/services/backend/api";
import { UUID } from "@/shared/nidavellir/types/entity-id";
import { Filter } from "@/shared/lib/filter/filter";
import { Delivery } from "@/services/backend/samples/delivery/delivery";
import { Kind } from "@/services/backend/samples/sample/kind";

const sampleDeliveryApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["SamplesDelivery"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      samplesDeliveryShow: build.query<Delivery, ShowRequest>({
        query: (request) => ({
          url: `/samples/delivery`,
          method: "GET",
          params: request,
        }),
        providesTags: ["SamplesDelivery"],
      }),
      samplesDeliveryCreate: build.mutation<Delivery, CreateRequest>({
        query: (request) => ({
          url: `/samples/delivery`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "SamplesDelivery", id: request.id },
          "SamplesDelivery",
        ],
      }),
      samplesDeliveryCancel: build.mutation<Delivery, CancelRequest>({
        query: (request) => ({
          url: `/samples/delivery/cancel`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "SamplesDelivery", id: request.id },
          "SamplesDelivery",
        ],
      }),
      samplesDeliveryTransferResults: build.mutation<
        Delivery,
        TransferResultsRequest
      >({
        query: (request) => ({
          url: "/samples/delivery/transfer-results",
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "SamplesDelivery", id: request.id },
          "SamplesDelivery",
          "HtzReport",
        ],
      }),
      samplesDeliveryList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/samples/delivery/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: ["SamplesDelivery"],
      }),
    }),
  });

export const {
  useSamplesDeliveryShowQuery,
  useSamplesDeliveryCreateMutation,
  useSamplesDeliveryCancelMutation,
  useSamplesDeliveryTransferResultsMutation,
  useSamplesDeliveryListQuery,
} = sampleDeliveryApi;

export interface ShowRequest {
  id: string;
}

export interface CreateRequest {
  id: string;
  artefactId: string;
  artefactNamespace: string;
  trackingId: string;
  description: string;
  labId: string | null;
  sampleIds: { id: string; kind: Kind }[];
}

export interface CancelRequest {
  id: string;
}

export interface TransferResultsRequest {
  id: string;
}

export interface ListRequest {
  artefact?: Filter<UUID>;
  limit?: number;
  offset?: number;
}

export interface List {
  deliveries: Delivery[];
  count: number;
  limit: number;
  offset: number;
}
