import {
  ServiceCost,
  ServiceEntry,
  ServiceEntryList,
  UpdateServiceTimePerMonitorRequest,
  UpdateTargetRevenueRequest,
} from "@/services/backend/qpm/calculations/types";
import { useEffect, useState } from "react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/shared/components/ui/collapsible";
import {
  Check,
  ChevronDown,
  ChevronRight,
  CircleSlash2,
  Edit2,
  Loader2,
  X,
} from "lucide-react";
import t from "@/lang/lang";
import { fMoney } from "@/routes/gesec/processes/_components/ui/number-input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import {
  FloatNumberInput,
  IntNumberInput,
  Value,
} from "@/routes/gemex/processes/_components/ui/number-input";
import { AdditionalCostComponent } from "@/routes/gemex/processes/qpm/calculations/calculation/_components/additional-cost-component";
import {
  CalculationServiceEntryType,
  CalculationServiceType,
} from "@/services/backend/qpm/calculations/enum";
import {
  useQpmCalcServiceCostListQuery,
  useQpmCalcServiceCostServiceTimePerMonitorPostMutation,
  useQpmCalcServiceCostTargetRevenuePostMutation,
} from "@/services/backend/qpm/calculations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { CalculationServiceTypeUtil } from "@/services/backend/qpm/calculations/ServiceTypeUtil";
import {
  TimeNumberInput,
  TimeUnit,
} from "@/routes/gemex/processes/_components/ui/time-number-input";
import { Label } from "@/shared/components/ui/label";
import { CalculationServiceEntryUtil } from "@/services/backend/qpm/calculations/CalculationServiceEntryUtil";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { Button } from "@/shared/components/ui/button";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";

export type ServiceCostsComponentProps = {
  calculationId: string;
  calculationServiceType: CalculationServiceType;
};

export function ServiceCostsComponent({
  calculationId,
  calculationServiceType,
}: ServiceCostsComponentProps) {
  const [open, setOpen] = useState(true);

  const {
    data: serviceCostList,
    error,
    isLoading,
  } = useQpmCalcServiceCostListQuery({
    calculationId: {
      active: true,
      values: [calculationId],
    },
    serviceTypeKey: {
      active: true,
      values: [calculationServiceType],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <Skeleton className="h-8 w-[200px]" />;
  }

  // Order the serviceCostList by createdAt date in descending order
  const sortedServiceCosts = [...(serviceCostList?.list || [])].sort(
    (a, b) =>
      b.serviceEntries.totalAmountOfMonitors -
      a.serviceEntries.totalAmountOfMonitors,
  );

  const processUniqueEntries = (entries: ServiceEntry[]) => {
    const uniqueEntries = new Map<string, ServiceEntry>();
    entries.forEach((entry) => {
      const typeString = CalculationServiceEntryUtil.toString(entry.type);
      if (!uniqueEntries.has(typeString)) {
        uniqueEntries.set(typeString, entry);
      }
    });
    return Array.from(uniqueEntries.values()).sort((a, b) =>
      CalculationServiceEntryUtil.toString(a.type).localeCompare(
        CalculationServiceEntryUtil.toString(b.type),
      ),
    );
  };

  return (
    sortedServiceCosts &&
    sortedServiceCosts.map((serviceCost) => {
      const uniqueEntries = processUniqueEntries(
        serviceCost.serviceEntries.entries,
      );

      return (
        <Collapsible key={serviceCost.id} open={open} onOpenChange={setOpen}>
          <div className="border-t bg-gray-50 px-6 py-3">
            <div className="grid grid-cols-3 text-right font-bold">
              <CollapsibleTrigger>
                <div className="flex items-center space-x-2">
                  {open ? (
                    <ChevronDown className="h-5 w-5" />
                  ) : (
                    <ChevronRight className="h-5 w-5" />
                  )}
                  <span className="font-bold uppercase">
                    {CalculationServiceTypeUtil.toString(
                      calculationServiceType,
                    )}
                  </span>
                  {uniqueEntries.map((entry) => (
                    <span key={entry.id}>
                      - {CalculationServiceEntryUtil.toString(entry.type)}
                    </span>
                  ))}
                </div>
              </CollapsibleTrigger>
              <span className="flex items-center justify-end gap-2">
                <Label
                  htmlFor={`${serviceCost?.id}-${calculationServiceType}-amount-of-measures-input`}
                >
                  {t("Maßnahmen / Jahr")}
                </Label>
                <IntNumberInput
                  id={`${serviceCost?.id}-${calculationServiceType}-amount-of-measures-input`}
                  value={serviceCost?.amountOfMeasures || 0}
                  disabled
                />
              </span>
              <span className="font-bold">
                {fMoney(serviceCost?.totalCost || 0)}
              </span>
            </div>
          </div>
          <CollapsibleContent>
            <div className="border-t">
              {serviceCost && (
                <div className="">
                  <ServicePriceListComponent serviceCost={serviceCost} />
                  <ServiceEntryListComponent
                    serviceEntries={serviceCost.serviceEntries}
                  />
                  <AdditionalCostComponent
                    calculationId={calculationId}
                    serviceCost={serviceCost}
                  />
                </div>
              )}
            </div>
          </CollapsibleContent>
        </Collapsible>
      );
    })
  );
}

export type ServicePriceListComponentProps = {
  serviceCost: ServiceCost;
};

export function ServicePriceListComponent({
  serviceCost,
}: ServicePriceListComponentProps) {
  return (
    <div className="flex flex-col">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="text-left">
              {t("Gesamtanzahl Kontrollstationen")}
            </TableHead>
            <TableHead className="text-left">
              {t("Anzahl Servicepunkte")}
            </TableHead>
            <TableHead className="text-right">
              <div className="flex items-center justify-end gap-2">
                <CircleSlash2 className="h-4 w-4" />
                {t("Arbeitszeit pro Kontrollstation")}
              </div>
            </TableHead>
            <TableHead className="text-right">
              {t("Zusatzzeit pro Kontrollstation")}
            </TableHead>
            <TableHead className="text-right">
              <div className="flex items-center justify-end gap-2">
                <CircleSlash2 className="h-4 w-4" />
                {t("Wert pro Kontrollstation in Euro")}
              </div>
            </TableHead>
            <TableHead className="text-right">
              {t("Umsatz pro Stunde in Euro")}
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell className="text-left font-medium">
              <div className="flex items-center gap-2">
                <Value
                  value={serviceCost.parameters.totalAmountOfMonitors}
                  fractions={0}
                />
              </div>
            </TableCell>
            <TableCell className="text-left font-medium">
              <Value
                value={serviceCost.parameters.totalAmountServiceableMonitors}
                fractions={0}
              />
            </TableCell>
            <TableCell className="text-right font-medium">
              <TimeNumberInput
                className="justify-self-end"
                value={serviceCost.averageServiceTimePerMonitor}
                timeUnit={TimeUnit.Minutes}
                disabled
              />
            </TableCell>
            <TableCell className="text-right font-medium">
              <TimeNumberInput
                className="justify-self-end"
                value={serviceCost.parameters.additionalServiceTimePerMonitor}
                timeUnit={TimeUnit.Minutes}
                disabled
              />
            </TableCell>
            <TableCell className="text-right font-medium">
              <Value value={serviceCost.serviceCostPerMonitor} fractions={2} />
            </TableCell>
            <TableCell className="text-right font-medium">
              <div className="flex items-center gap-2 justify-self-end">
                <Value value={serviceCost.revenuePerHour} fractions={2} />
                <EditRevenueComponent serviceCost={serviceCost} />
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

export type ServiceEntryListComponentProps = {
  serviceEntries: ServiceEntryList;
};

export function ServiceEntryListComponent({
  serviceEntries,
}: ServiceEntryListComponentProps) {
  // Define the custom order for CalculationServiceEntryType
  const customOrder = [
    CalculationServiceEntryType.CheckPoint,
    CalculationServiceEntryType.CheckPointSCB,
    CalculationServiceEntryType.FIV,
    CalculationServiceEntryType.ALoRa,
  ];

  const sortedEntries = [...serviceEntries.entries].sort((a, b) => {
    // First sort by totalAmountOfMeasures ascending
    const measureComparison = a.totalAmountOfMeasures - b.totalAmountOfMeasures;
    if (measureComparison !== 0) {
      return measureComparison;
    }
    // Then sort by custom order of CalculationServiceEntryType
    return customOrder.indexOf(a.type) - customOrder.indexOf(b.type);
  });

  return (
    <div className="flex flex-col">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="text-left">{t("Service Art")}</TableHead>
            <TableHead className="text-right">
              {t("Anzahl Servicepunkte")}
            </TableHead>
            <TableHead className="text-right">
              {t("Arbeitszeit pro Kontrollstation")}
            </TableHead>
            <TableHead className="text-right">
              {t("Wert pro Kontrollstation in Euro")}
            </TableHead>
            <TableHead className="text-right">
              {t("Zusatzzeit pro Kontrollstation")}
            </TableHead>
            <TableHead className="text-right">
              {t("Zusatzkosten pro Kontrollstation in Euro")}
            </TableHead>
            <TableHead className="text-right">
              {t("Umsatz pro Stunde in Euro")}
            </TableHead>
            <TableHead className="text-right">
              {t("Preis pro Maßnahme in Euro")}
            </TableHead>
            <TableHead className="text-right">
              {t("Maßnahmen / Erforderliche Maßnahmen")}
            </TableHead>
            <TableHead className="text-right">
              {t("Gesamtpreis in Euro")}
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedEntries.map((entry) => (
            <ServiceEntryRow key={entry.id} entry={entry} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export type ServiceEntryRowProps = {
  entry: ServiceEntry;
};

export function ServiceEntryRow({ entry }: ServiceEntryRowProps) {
  const [serviceTime, setServiceTime] = useState(entry.serviceTimePerMonitor);
  const [request, setRequest] = useState<UpdateServiceTimePerMonitorRequest>({
    calculationId: entry.calculationId,
    serviceCostId: entry.serviceCostId,
    serviceEntryId: entry.id,
    serviceTimePerMonitor: entry.serviceTimePerMonitor,
  });

  const [setServiceTimePerMonitor, { isLoading, error, reset, isSuccess }] =
    useQpmCalcServiceCostServiceTimePerMonitorPostMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    setServiceTimePerMonitor,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
  );

  useEffect(() => {
    setServiceTime(entry.serviceTimePerMonitor);
  }, [entry.serviceTimePerMonitor]);

  return (
    <TableRow>
      <TableCell className="text-left font-medium">
        {CalculationServiceEntryUtil.toString(entry.type, entry.serviceTypeKey)}
      </TableCell>
      <TableCell className="text-right font-medium">
        <Value value={entry.amountOfMonitors} fractions={0} />
      </TableCell>
      <TableCell className="text-right font-medium">
        <TimeNumberInput
          className="justify-self-end"
          value={serviceTime}
          onChange={(val) => {
            const time = val < 0 ? 0 : val;
            setRequest({
              ...request,
              serviceTimePerMonitor: time,
            });
          }}
          timeUnit={TimeUnit.Minutes}
          disabled={isLoading}
        />
      </TableCell>
      <TableCell className="text-right font-medium">
        <Value value={entry.serviceCostPerMonitor} fractions={2} />
      </TableCell>
      <TableCell className="text-right font-medium">
        <TimeNumberInput
          className="justify-self-end"
          value={entry.additionalTimePerMonitor}
          timeUnit={TimeUnit.Minutes}
          disabled
        />
      </TableCell>
      <TableCell className="text-right font-medium">
        <Value value={entry.additionalCostPerMonitor} fractions={2} />
      </TableCell>
      <TableCell className="text-right font-medium">
        <Value value={entry.revenuePerHour} fractions={2} />
      </TableCell>
      <TableCell className="text-right font-medium">
        <Value value={entry.serviceCostPerMeasure} fractions={2} />
      </TableCell>
      <TableCell className="text-right font-medium">
        <Value value={entry.amountOfMeasures} fractions={0} />
        {" / "}
        <Value value={entry.totalAmountOfMeasures} fractions={0} />
      </TableCell>
      <TableCell className="text-right font-medium">
        <Value value={entry.totalServiceCost} fractions={2} />
      </TableCell>
    </TableRow>
  );
}

export type EditRevenueComponentProps = {
  serviceCost: ServiceCost;
};

function EditRevenueComponent({ serviceCost }: EditRevenueComponentProps) {
  const { toast } = useToast();
  const [open, setOpen] = useState<boolean>(false);
  const [request, setRequest] = useState<UpdateTargetRevenueRequest>({
    calculationId: serviceCost.calculationId,
    serviceCostId: serviceCost.id,
    targetRevenue: serviceCost.targetRevenue,
  });

  const [updateTargetRevenue, { isLoading, error, reset }] =
    useQpmCalcServiceCostTargetRevenuePostMutation();

  const onSubmit = () => {
    updateTargetRevenue(request);
  };

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  return (
    <Popover open={open}>
      <PopoverTrigger asChild>
        <Button
          className="h-fit w-fit p-1"
          onClick={() => setOpen(!open)}
          size="icon"
          variant="outline"
        >
          <Edit2 className="h-5 w-5" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[350px]" side="bottom" align="end">
        <div className="flex flex-col gap-2">
          <div className="grid grid-cols-2 gap-2">
            <Label
              htmlFor={`${serviceCost?.id}-target-revenue`}
              className="text-left font-medium"
            >
              {t("Aktueller Umsatz pro Stunde (berechnet)")}
            </Label>
            <Value
              id={`${serviceCost?.id}-target-revenue`}
              value={serviceCost.revenuePerHour}
              fractions={2}
            />
          </div>
          <div className="grid grid-cols-2 gap-2">
            <Label
              htmlFor={`${serviceCost?.id}-set-revenue`}
              className="text-left font-medium"
            >
              {t("Neues Umsatzziel pro Stunde")}
            </Label>
            <FloatNumberInput
              id={`${serviceCost?.id}-set-revenue`}
              className="justify-self-end"
              value={serviceCost.targetRevenue}
              onChange={(val) => {
                setRequest({
                  ...request,
                  targetRevenue: val,
                });
              }}
              handleEnterKeyDown={(val) => {
                setRequest({ ...request, targetRevenue: val });
                onSubmit();
              }}
              disabled={isLoading}
            />
          </div>
          <div className="flex justify-end gap-2">
            <Button
              className="h-fit w-fit p-1"
              size="icon"
              variant="outline"
              onClick={() => setOpen(false)}
            >
              <X className="h-5 w-5" />
            </Button>
            <Button
              className="h-fit w-fit p-1"
              size="icon"
              variant={isLoading ? "outline" : "default"}
              onClick={onSubmit}
              disabled={isLoading}
            >
              {!isLoading && <Check className="h-5 w-5" />}
              {isLoading && <Loader2 className="h-5 w-5 animate-spin" />}
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
