import { backendApi } from "../api";

const branchMatchApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    showBranchMatch: build.query<Match, ShowRequest>({
      query: (request) => ({
        url: `/dynamics/branch-match`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "BranchMatch", id: result.branchId }] : [],
    }),
    putBranchMatch: build.mutation<Match, PutRequest>({
      query: (request) => ({
        url: `/dynamics/branch-match`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "BranchMatch", id: request.branchId },
        "BranchMatch",
      ],
    }),
    deleteBranchMatch: build.mutation<Match, DeleteRequest>({
      query: (request) => ({
        url: `/dynamics/branch-match`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "BranchMatch", id: request.branchId },
        "BranchMatch",
      ],
    }),
    listAxNiederlassung: build.query<
      AxNiederlassungList,
      ListAxNiederlassungRequest
    >({
      query: (request) => ({
        url: `/dynamics/branch-match/list-ax-niederlassung`,
        method: "GET",
        params: request,
      }),
    }),
  }),
});

export const {
  useShowBranchMatchQuery,
  usePutBranchMatchMutation,
  useDeleteBranchMatchMutation,
  useListAxNiederlassungQuery,
} = branchMatchApi;

export interface Match {
  branchId: string;
  axNiederlassungValue: number;
}

export interface ShowRequest {
  branchId: string;
}

export interface PutRequest {
  branchId: string;
  axNiederlassungValue: number;
}

export interface DeleteRequest {
  branchId: string;
}

export interface AxNiederlassungList {
  axNiederlassungen: AxNiederlassung[];
  count: number;
}

export interface AxNiederlassung {
  value: number;
  label: string;
}

export interface ListAxNiederlassungRequest {}
