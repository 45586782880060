import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { provideBackendToken } from "@/shared/lib/authorization/provide-backend-token";
import { reAuthOn401Wrapper } from "@/services/backend/re-auth-on-401";

export const backendApi = createApi({
  baseQuery: reAuthOn401Wrapper(
    fetchBaseQuery({
      baseUrl: import.meta.env.VITE_BACKEND_URL,
      prepareHeaders: async (headers) => {
        const accessToken: string = await provideBackendToken();
        headers.set("Authorization", `Bearer ${accessToken}`);
        return headers;
      },
    }),
  ),
  endpoints: () => ({}),
  reducerPath: "backendApi",
  tagTypes: [
    "AuthEntraGroup",
    "Branch",
    "BranchMatch",
    "Calculations",
    "CommentsComment",
    "Company",
    "CompanyMatch",
    "ContactKind",
    "Customer",
    "HtzReport", // need globally, since used in htz and samples api
    "Process",
    "ProcessContactKindMatch",
    "ProcessContactRoleMatch",
    "ProcessKindMatch",
    "ProcessState",
    "RltCalculation",
    "RltCalculationCurrent",
    "RltCertificate",
    "RltConfirmation",
    "RltConfirmationCurrent",
    "RltContract",
    "RltDefaults",
    "RltDocumentation",
    "RltLMRA",
    "RltOffer",
    "RltOrder",
    "RltOfferCurrent",
    "RltTreatment",
    "RltWorkOrder",
    "VbsCalculation",
    "VbsCalculationCurrent",
    "VbsCertificate",
    "VbsConfirmation",
    "VbsConfirmationCurrent",
    "VbsContract",
    "VbsDefaults",
    "VbsDocumentation",
    "VbsLMRA",
    "VbsOffer",
    "VbsOfferCurrent",
    "VbsOrder",
    "VbsTreatment",
    "VbsWorkOrder",
  ],
});
