import { Filter } from "@/shared/lib/filter/filter";
import { Address } from "@/services/backend/addresses/address/address";
import { Representative } from "@/services/backend/representatives/representative/representative";
import { Contact } from "@/services/backend/contacts/contact/contact";
import { backendApi } from "../../api";
import { WorkOrder } from "./work-order";
import t from "../../../../lang/lang";

const rltWorkOrdersApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    rltWorkOrderCreate: build.mutation<WorkOrder, CreateRequest>({
      query: (request) => ({
        url: `/rlt/work-order`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltWorkOrder", id: request.id },
        "RltWorkOrder",
      ],
    }),
    rltWorkOrderShow: build.query<WorkOrder, ShowRequest>({
      query: (request) => ({
        url: `/rlt/work-order`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "RltWorkOrder", id: result.id }] : [],
    }),
    rltWorkOrderList: build.query<List, ListRequest>({
      query: (request) => ({
        url: `/rlt/work-order/list`,
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) =>
        result ? [{ type: "RltWorkOrder", id: "LIST" }] : [],
    }),
    rltWorkOrderUpdateBeginDate: build.mutation<
      WorkOrder,
      UpdateBeginDateRequest
    >({
      query: (request) => ({
        url: `/rlt/work-order/begin-date`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltWorkOrder", id: request.id },
        "RltWorkOrder",
      ],
    }),
    rltWorkOrderUpdateEndDate: build.mutation<WorkOrder, UpdateEndDateRequest>({
      query: (request) => ({
        url: `/rlt/work-order/end-date`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltWorkOrder", id: request.id },
        "RltWorkOrder",
      ],
    }),
    rltWorkOrderUpdateTeamLeader: build.mutation<
      WorkOrder,
      UpdateTeamLeaderRequest
    >({
      query: (request) => ({
        url: `/rlt/work-order/team-leader`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltWorkOrder", id: request.id },
        "RltWorkOrder",
      ],
    }),
    rltWorkOrderUpdateComment: build.mutation<WorkOrder, UpdateCommentRequest>({
      query: (request) => ({
        url: `/rlt/work-order/comment`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltWorkOrder", id: request.id },
        "RltWorkOrder",
      ],
    }),
    rltWorkOrderUpdateServiceAddress: build.mutation<
      WorkOrder,
      UpdateServiceAddressRequest
    >({
      query: (request) => ({
        url: `/rlt/work-order/service-address`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltWorkOrder", id: request.id },
        "RltWorkOrder",
      ],
    }),
    rltWorkOrderUpdateServiceContact: build.mutation<
      WorkOrder,
      UpdateServiceContactRequest
    >({
      query: (request) => ({
        url: `/rlt/work-order/service-contact`,
        method: "PATCH",
        body: {
          id: request.id,
          serviceContact: request.contact,
        },
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltWorkOrder", id: request.id },
        "RltWorkOrder",
      ],
    }),
    rltWorkOrderUpdateRepresentative: build.mutation<
      WorkOrder,
      UpdateRepresentativeRequest
    >({
      query: (request) => ({
        url: `/rlt/work-order/representative`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltWorkOrder", id: request.id },
        "RltWorkOrder",
      ],
    }),
    rltWorkOrderSign: build.mutation<WorkOrder, SignRequest>({
      query: (request) => {
        const body = new FormData();
        body.append("file", request.signature);
        return {
          url: `/rlt/work-order/sign`,
          method: "PATCH",
          headers: {
            //  this is needed, otherwise a 415 error is returned
            Accept: "application/json",
          },
          params: {
            id: request.id,
            dataFormat: request.dataFormat,
            nameOfSigner: request.nameOfSigner,
          },
          body,
          formData: true,
        };
      },
      invalidatesTags: (_result, _e, request) => [
        { type: "RltWorkOrder", id: request.id },
        "RltWorkOrder",
      ],
    }),
    rltWorkOrderPreviewPDF: build.query<
      { objectURL: string },
      PreviewPDFRequest
    >({
      query: (request) => ({
        url: `/rlt/work-order/preview-pdf`,
        method: "GET",
        params: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          return {
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this query will download the PDF under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
      providesTags: (_r, _e, request) => [
        { type: "RltWorkOrder", id: request.id },
      ],
    }),
    rltWorkOrderDownloadPDF: build.mutation<
      { filename: string; objectURL: string },
      DownloadPDFRequest
    >({
      query: (request) => ({
        url: `/rlt/work-order/download-pdf`,
        method: "PATCH",
        body: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          // attempt to retrieve the filename from content-disposition header
          const contentDisposition = response.headers.get(
            "Content-Disposition",
          );
          let filename = t("Arbeitsschein.pdf");
          if (
            contentDisposition &&
            contentDisposition.indexOf("attachment") !== -1
          ) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return {
            filename,
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this mutation will download the PDF under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltWorkOrder", id: request.id },
        "RltWorkOrder",
      ],
    }),
    rltWorkOrderDelete: build.mutation<unknown, DeleteRequest>({
      query: (request) => ({
        url: `/rlt/work-order`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltWorkOrder", id: request.id },
        "RltWorkOrder",
      ],
    }),
  }),
});

export const {
  useRltWorkOrderCreateMutation,
  useRltWorkOrderShowQuery,
  useRltWorkOrderListQuery,
  useRltWorkOrderUpdateBeginDateMutation,
  useRltWorkOrderUpdateEndDateMutation,
  useRltWorkOrderUpdateTeamLeaderMutation,
  useRltWorkOrderUpdateCommentMutation,
  useRltWorkOrderUpdateServiceAddressMutation,
  useRltWorkOrderUpdateServiceContactMutation,
  useRltWorkOrderUpdateRepresentativeMutation,
  useRltWorkOrderSignMutation,
  useRltWorkOrderPreviewPDFQuery,
  useRltWorkOrderDownloadPDFMutation,
  useRltWorkOrderDeleteMutation,
} = rltWorkOrdersApi;

export interface CreateRequest {
  id: string;
  processId: string;
}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  process?: Filter<string>;
  beginDate?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface UpdateBeginDateRequest {
  id: string;
  beginDate: string;
}

export interface UpdateEndDateRequest {
  id: string;
  endDate: string;
}

export interface UpdateTeamLeaderRequest {
  id: string;
  teamLeaderId: string;
}

export interface UpdateCommentRequest {
  id: string;
  comment: string;
}

export interface UpdateServiceAddressRequest {
  id: string;
  serviceAddress: Address;
}

export interface UpdateServiceContactRequest {
  id: string;
  // naming is contact and not serviceContact because
  // with "contact" the ContactCardWithMutation component
  // can be used. The conflict with the expected naming
  // "serviceContact" in the backend is resolved in the
  // mutation definition above.
  contact: Contact;
}

export interface UpdateRepresentativeRequest {
  id: string;
  representative: Representative;
}

export interface SignRequest {
  id: string;
  signature: string;
  dataFormat: string;
  nameOfSigner: string;
}

export interface List {
  workOrders: WorkOrder[];
  count: number;
}

export interface PreviewPDFRequest {
  id: string;
  document: WorkOrderDocument;
}

export interface DownloadPDFRequest {
  id: string;
  document: WorkOrderDocument;
  userId: string;
}

export enum WorkOrderDocument {
  All = "all",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  WorkOrder = "work_order",
  LastMinuteRiskAnalysis = "lmra",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Documentation = "documentation",
}

export interface DeleteRequest {
  id: string;
}
