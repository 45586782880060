import { Report } from "@/services/backend/htz/report/report";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { Kind } from "@/services/backend/samples/sample/kind";
import { useState } from "react";
import { Input } from "@/shared/components/ui/input";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  useSamplesDustDensitySamplePutMutation,
  useSamplesDustDensitySampleShowQuery,
} from "@/services/backend/samples/sample/dust-density-sample-service";
import { DustDensitySample } from "@/services/backend/samples/sample/dust-density-sample";
import { LaboratorySelect } from "@/shared/components/domain/samples/laboratory-select";
import { AssessmentResultSelect } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/microbial/_components/assessment-result-select";
import { MicrobialAssessment } from "@/services/backend/htz/inspection/microbial-assessment";

export function DustDensitySamplesTable({ report }: { report: Report }) {
  const assessments = report.microbialAssessments.filter(
    (assessment) => assessment.kind === Kind.DustDensity,
  );

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="pl-3">{t("Nummer")}</TableHead>
          <TableHead>{t("Labor")}</TableHead>
          <TableHead className="w-36">{t("Einheit")}</TableHead>
          <TableHead className="w-28">{t("Richtwert")}</TableHead>
          <TableHead className="w-28">{t("Konzentration")}</TableHead>
          <TableHead className="w-28">{t("Ergebnis")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {assessments.map(
          (assessment) =>
            assessment.sampleId && (
              <SampleRowLoader
                key={assessment.id}
                report={report}
                assessment={assessment}
              />
            ),
        )}
        {assessments.length === 0 && (
          <TableRow>
            <TableCell colSpan={7} className="text-center">
              {t("Keine Staubdichtemessungen")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

function SampleRowLoader({
  report,
  assessment,
}: {
  report: Report;
  assessment: MicrobialAssessment;
}) {
  const {
    data: sample,
    isLoading,
    error,
  } = useSamplesDustDensitySampleShowQuery({ id: assessment.sampleId ?? "" });

  if (isLoading) {
    return null;
  }

  if (error) {
    return null;
  }

  return <SampleRow report={report} assessment={assessment} sample={sample!} />;
}

function SampleRow({
  report,
  assessment,
  sample,
}: {
  report: Report;
  assessment: MicrobialAssessment;
  sample: DustDensitySample;
}) {
  const [request, setRequest] = useState({ ...sample });
  const [put, { isLoading, error, isSuccess, reset }] =
    useSamplesDustDensitySamplePutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  return (
    <TableRow>
      <TableCell className="py-1.5 pl-3">{sample!.number}</TableCell>
      <TableCell className="py-1.5">
        <LaboratorySelect
          labId={sample!.labId}
          onLabIdChange={(labId) => setRequest({ ...request, labId })}
          className="h-8"
          disabled={report.immutable}
        />
      </TableCell>
      <TableCell className="py-1.5">
        <Input
          type="text"
          className="h-8"
          value={request.unit}
          onChange={(e) => setRequest({ ...request, unit: e.target.value })}
          disabled={report.immutable}
        />
      </TableCell>
      <TableCell className="py-1.5">
        <Input
          type="text"
          className="h-8"
          value={request.referenceValue}
          onChange={(e) =>
            setRequest({ ...request, referenceValue: e.target.value })
          }
          disabled={report.immutable}
        />
      </TableCell>
      <TableCell className="py-1.5">
        <Input
          type="text"
          className="h-8"
          value={request.value}
          onChange={(e) => setRequest({ ...request, value: e.target.value })}
          disabled={report.immutable}
        />
      </TableCell>
      <TableCell className="py-1.5">
        <AssessmentResultSelect
          report={report}
          assessment={assessment}
          errorKey={`microbialAssessments.${assessment.id}.result`}
          disabled={report.immutable}
        />
      </TableCell>
    </TableRow>
  );
}
