import { Report } from "@/services/backend/htz/report/report";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { useState } from "react";
import { Input } from "@/shared/components/ui/input";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  useSamplesWaterSampleListQuery,
  useSamplesWaterSamplePutMutation,
} from "@/services/backend/samples/sample/water-sample-service";
import {
  Measurement,
  updateMeasurement,
  WaterSample,
} from "@/services/backend/samples/sample/water-sample";
import { MicrobialAssessment } from "@/services/backend/htz/inspection/microbial-assessment";
import { AssessmentResultSelect } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/microbial/_components/assessment-result-select";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { TableSkeleton } from "@/shared/components/ui/table-skeleton";
import { H4 } from "@/shared/components/ui/typography";
import { LaboratorySelect } from "@/shared/components/domain/samples/laboratory-select";

export function WaterSamplesTable({ report }: { report: Report }) {
  const {
    data: samplesList,
    isLoading,
    error,
  } = useSamplesWaterSampleListQuery({
    artefact: {
      active: true,
      values: [report.workOrderId ?? ""],
    },
  });

  if (isLoading) {
    return <TableSkeleton columns={["", "", "", "", ""]} />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  const coldWaterSamples = samplesList!.samples
    .filter((s) => s.waterKind === "cold")
    .filter((s) =>
      report.microbialAssessments.some((a) => a.sampleId === s.id),
    );

  const warmWaterSamples = samplesList!.samples
    .filter((s) => s.waterKind === "warm")
    .filter((s) =>
      report.microbialAssessments.some((a) => a.sampleId === s.id),
    );

  return (
    <div>
      <H4 className="pl-3 text-lg">{t("Kaltwasserproben")}</H4>
      <SamplesTable report={report} samples={coldWaterSamples} />
      <H4 className="pl-3 text-lg">{t("Warmwasserproben")}</H4>
      <SamplesTable report={report} samples={warmWaterSamples} />
    </div>
  );
}

function SamplesTable({
  report,
  samples,
}: {
  report: Report;
  samples: WaterSample[];
}) {
  if (samples.length === 0) {
    return <div className="pl-3">{t("Keine Proben vorhanden")}</div>;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="pl-3">{t("Nummer")}</TableHead>
          <TableHead>{t("Labor")}</TableHead>
          <TableHead>{t("Probenentnahme Desinfiziert")}</TableHead>
          <TableHead>{t("Probenentnahme Temperatur (°C)")}</TableHead>
          {samples[0].measurements?.map((measurement) => (
            <TableHead key={measurement.order} className="w-28">
              {`${measurement.name} ${measurement.unit ? `(${measurement.unit})` : ""}`}
            </TableHead>
          ))}
          <TableHead className="w-28">{t("Ergebnis")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {samples.map((sample) => (
          <SampleRow
            key={sample.id}
            report={report}
            assessment={
              report.microbialAssessments.find((a) => a.sampleId === sample.id)!
            }
            sample={sample}
          />
        ))}
      </TableBody>
    </Table>
  );
}

function SampleRow({
  report,
  assessment,
  sample,
}: {
  report: Report;
  assessment: MicrobialAssessment;
  sample: WaterSample;
}) {
  const [request, setRequest] = useState({ ...sample });
  const [put, { isLoading, error, isSuccess, reset }] =
    useSamplesWaterSamplePutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  return (
    <TableRow>
      <TableCell className="whitespace-nowrap py-1.5 pl-3">
        {request!.number}
      </TableCell>
      <TableCell className="py-1.5">
        <LaboratorySelect
          labId={sample!.labId}
          onLabIdChange={(labId) => setRequest({ ...request, labId })}
          className="h-8"
          disabled={report.immutable}
        />
      </TableCell>
      <TableCell className="py-1.5">{request!.desinfected}</TableCell>
      <TableCell className="py-1.5">{request!.temperature}</TableCell>
      {request.measurements?.map((measurement) => (
        <MeasurementCell
          key={measurement.order}
          measurement={measurement}
          onMeasurementChange={(m) => setRequest(updateMeasurement(request, m))}
          disabled={report.immutable}
        />
      ))}
      <TableCell className="py-1.5">
        <AssessmentResultSelect
          report={report}
          assessment={assessment}
          errorKey={`microbialAssessments.${assessment.id}.result`}
          disabled={report.immutable}
        />
      </TableCell>
    </TableRow>
  );
}

function MeasurementCell({
  measurement,
  onMeasurementChange,
  disabled,
}: {
  measurement: Measurement;
  onMeasurementChange: (measurement: Measurement) => void;
  disabled: boolean;
}) {
  return (
    <TableCell className="py-1.5">
      <Input
        type="text"
        className="h-8"
        value={measurement.value}
        onChange={(e) =>
          onMeasurementChange({ ...measurement, value: e.target.value })
        }
        disabled={disabled}
      />
    </TableCell>
  );
}
