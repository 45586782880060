import { Label } from "@/shared/components/ui/label";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";
import { Input } from "@/shared/components/ui/input";
import { cn } from "@/shared/lib/utils";

interface TextInputProps {
  label: string;
  field: string;
  text: string;
  onTextChange: (text: string) => void;
  errors: string[] | null;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

export function TextInput({
  label,
  field,
  text,
  onTextChange,
  errors,
  placeholder,
  disabled,
  className,
}: TextInputProps) {
  return (
    <div className={cn("grid content-start gap-1.5", className)}>
      <Label htmlFor={field}>{label}</Label>
      <Input
        id={field}
        type="text"
        value={text}
        onChange={(e) => onTextChange(e.target.value)}
        className={
          errors && errors.length > 0 ? "border-red-500 shadow-md" : ""
        }
        placeholder={placeholder}
        disabled={disabled}
      />
      <InputErrorMessages errors={errors ?? []} />
    </div>
  );
}
