import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import {
  AysidStatus,
  StatusResponse,
  useSamplesLabApiStatusQuery,
} from "@/services/backend/samples/labapi/service";
import { LabApiKey } from "@/services/backend/samples/lab/laboratory";
import { Delivery } from "@/services/backend/samples/delivery/delivery";
import { H4 } from "@/shared/components/ui/typography";
import { formatDate } from "@/shared/lib/utilities/date";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/shared/components/ui/alert";
import { SampleNumber } from "@/shared/components/domain/samples/sample-number";
import { Card } from "@/shared/components/ui/card";

export function DeliveryStatusDialog({ delivery }: { delivery: Delivery }) {
  const {
    data: status,
    isLoading,
    error,
  } = useSamplesLabApiStatusQuery({
    labId: delivery.labId!,
    referenceId: delivery.id,
  });

  if (delivery.cancelled) {
    return (
      <Button variant="link" className="text-destructive" disabled>
        {t("Storniert")}
      </Button>
    );
  }

  if (isLoading) {
    return (
      <Button variant="link" className="animate-pulse" disabled>
        {t("lade ...")}
      </Button>
    );
  }

  if (error) {
    return (
      <Button variant="link" className="text-destructive" disabled>
        {t("Nicht verfügbar")}
      </Button>
    );
  }

  return <StatusDialogContent delivery={delivery} status={status!} />;
}

function StatusDialogContent({
  delivery,
  status,
}: {
  delivery: Delivery;
  status: StatusResponse;
}) {
  switch (status.labKey) {
    case LabApiKey.Aysid:
      return (
        <AysidDeliveryStatusDialog
          delivery={delivery}
          status={status.payload}
        />
      );
    default:
      return null;
  }
}

function AysidDeliveryStatusDialog({
  delivery,
  status,
}: {
  delivery: Delivery;
  status: AysidStatus;
}) {
  const diff = delivery.items.length - status.totalSampleSets;

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="link">{t(status.state)}</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t("Status der Lieferung vom ")} {formatDate(delivery.sentAt)}
          </DialogTitle>
          <DialogDescription>{t("")}</DialogDescription>
        </DialogHeader>
        <div>
          <div>
            <H4 className="text-md">{t("Informationen von Aysid:")}</H4>
          </div>
          <div className="flex justify-between">
            <div>{t("Status")}</div>
            <div>{t(status.state)}</div>
          </div>
          <div className="flex justify-between">
            <div>{t("Fertige Proben")}</div>
            <div>{status.completedSampleSets}</div>
          </div>
          <div className="flex justify-between">
            <div>{t("Gesamt Proben")}</div>
            <div>{status.totalSampleSets}</div>
          </div>
        </div>
        {diff !== 0 && (
          <Alert variant="destructive">
            <AlertTitle>{t("Abweichung in den Daten")}</AlertTitle>
            <AlertDescription>
              {t(
                "Die Aysid hat eine andere Anzahl Proben registriert als versendet wurde.",
              )}
            </AlertDescription>
          </Alert>
        )}
        <Card>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t("Probennummer")}</TableHead>
                <TableHead>{t("Art")}</TableHead>
                <TableHead>{t("Wert")}</TableHead>
                <TableHead>{t("Einheit")}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {delivery.items?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className="py-1">
                    <SampleNumber kind={item.kind} id={item.sampleId} />
                  </TableCell>
                  <TableCell className="py-1">{t(item.kind)}</TableCell>
                  <TableCell className="py-1">
                    {
                      status.results?.find(
                        (r) => r.sampleSetId === item.sampleId,
                      )?.value
                    }
                  </TableCell>
                  <TableCell className="py-1">
                    {
                      status.results?.find(
                        (r) => r.sampleSetId === item.sampleId,
                      )?.unit
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Schließen")}</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
