import { Outlet, useParams } from "react-router-dom";
import {
  NavigationGroup,
  ProcessPage,
  ProcessPageMain,
  ProcessPageMainContent,
  ProcessPageMainHeader,
  ProcessPageSidebar,
  ProcessPageSidebarFooter,
  ProcessPageSidebarNavigation,
  ProcessSidebarTrigger,
} from "@/shared/service-manager/default-ui/process-page/process-page";
import t from "@/lang/lang";
import { LayoutDashboard, Phone } from "lucide-react";
import {
  ArtefactIconConfirmation,
  ArtefactIconOffer,
  ArtefactIconOrder,
  ArtefactIconReport,
  ArtefactIconWorkOrder,
} from "@/shared/components/domain/artefact/artefact-icon";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Separator } from "@/shared/components/ui/separator";
import { BreadcrumbsToArtefact } from "@/shared/service-manager/default-ui/process-page/breadcrumbs-to-artefact";
import { CloseProcessSidebarMenu } from "@/routes/gesec/processes/[processId]/_components/close-process-sidebar-menu";
import { StateWarning } from "@/shared/components/domain/process/state/state-warning";
import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
} from "@/shared/components/ui/sidebar";

export default function HTZRoute() {
  const { processId } = useParams();
  const {
    data: process,
    isLoading,
    error,
  } = useProcessesProcessShowQuery({
    id: processId ?? "",
  });

  if (isLoading) {
    return null;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <ProcessPage>
      <ProcessPageSidebar className="pt-16">
        <ProcessPageSidebarNavigation groups={sidebarNavigation} />
        <ProcessPageSidebarFooter>
          <SidebarGroup>
            <SidebarGroupLabel>{t("Vorgang")}</SidebarGroupLabel>
            <SidebarGroupContent>
              <CloseProcessSidebarMenu processId={process!.id} />
            </SidebarGroupContent>
          </SidebarGroup>
        </ProcessPageSidebarFooter>
      </ProcessPageSidebar>
      <ProcessPageMain>
        <ProcessPageMainHeader className="justify-between">
          <div className="flex items-center gap-2">
            <ProcessSidebarTrigger />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <BreadcrumbsToArtefact process={process!} />
          </div>
        </ProcessPageMainHeader>
        <ProcessPageMainContent>
          <StateWarning processId={process!.id} />
          <Outlet />
        </ProcessPageMainContent>
      </ProcessPageMain>
    </ProcessPage>
  );
}

const sidebarNavigation: NavigationGroup[] = [
  {
    label: t("Allgemein"),
    items: [
      {
        label: t("Übersicht"),
        icon: LayoutDashboard,
        to: "overview",
      },
      {
        label: t("Ansprechpartner"),
        icon: Phone,
        to: "contact",
      },
    ],
  },
  {
    label: t("Meilensteine"),
    items: [
      {
        label: t("Angebote"),
        icon: ArtefactIconOffer,
        to: "offers",
      },
      {
        label: t("Auftragseingänge"),
        icon: ArtefactIconOrder,
        to: "orders",
      },
      {
        label: t("Auftragsbestätigungen"),
        icon: ArtefactIconConfirmation,
        to: "confirmations",
      },
      {
        label: t("Arbeitsscheine"),
        icon: ArtefactIconWorkOrder,
        to: "work-orders",
      },
      {
        label: t("Berichte"),
        icon: ArtefactIconReport,
        to: "reports",
      },
    ],
  },
];
