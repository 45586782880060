import { useRltCertificateShowQuery } from "@/services/backend/rlt/certificates/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { RefreshCw } from "lucide-react";
import {
  ArtefactSheet,
  ArtefactSheetActions,
  ArtefactSheetActionsContent,
  ArtefactSheetActionsHeader,
  ArtefactSheetContent,
  ArtefactSheetContextProvider,
  ArtefactSheetHeader,
  ArtefactSheetMain,
  ArtefactSheetTitle,
  ArtefactSheetTrigger,
  useArtefactSheetContext,
} from "@/shared/service-manager/artefact/artefact-sheet";
import { Certificate } from "@/services/backend/rlt/certificates/certificate";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { CertificateComponent } from "@/routes/gesec/processes/[processId]/rlt/certificates/[certificateId]/_components/certificate-component";
import { NavigateToArtefactButton } from "@/shared/components/domain/artefact/preview/_components/navigate-to-artefact-button";
import { ProcessKind } from "@/services/backend/processes/process/kinds";
import { ArtefactPage } from "@/shared/service-manager/default-ui/artefact-page/artefact-page";

export function RltCertificatePreview({
  certificateId,
}: {
  certificateId: string;
}) {
  const {
    data: certificate,
    isLoading,
    error,
  } = useRltCertificateShowQuery({ id: certificateId });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <RefreshCw className="h-4 w-4" />;
  }

  return (
    <ArtefactSheetContextProvider>
      <CertificateArtefactSheet certificate={certificate!} />
    </ArtefactSheetContextProvider>
  );
}

function CertificateArtefactSheet({
  certificate,
}: {
  certificate: Certificate;
}) {
  const { open, setOpen } = useArtefactSheetContext();

  return (
    <ArtefactSheet open={open} onOpenChange={setOpen}>
      <ArtefactSheetTrigger asChild>
        <Button variant="ghost" size="sm">
          {t("Vorschau")}
        </Button>
      </ArtefactSheetTrigger>
      <ArtefactSheetContent className="h-[90vh]">
        <ArtefactSheetHeader>
          <ArtefactSheetTitle>
            {t(certificate!.artefactKind)} {t("Vorschau")}
          </ArtefactSheetTitle>
        </ArtefactSheetHeader>
        <ArtefactSheetMain>
          <CertificatePreview certificate={certificate!} />
        </ArtefactSheetMain>
      </ArtefactSheetContent>
    </ArtefactSheet>
  );
}

function CertificatePreview({ certificate }: { certificate: Certificate }) {
  return (
    <>
      <CertificateView certificate={certificate} />
      <CertificateActions certificate={certificate} />
    </>
  );
}

function CertificateView({ certificate }: { certificate: Certificate }) {
  return (
    <div className="md:col-span-4">
      <PersistenceContextProvider>
        <ArtefactPage>
          <div className="max-h-[80vh] overflow-auto">
            <CertificateComponent certificateId={certificate.id} />
          </div>
        </ArtefactPage>
      </PersistenceContextProvider>
    </div>
  );
}

function CertificateActions({ certificate }: { certificate: Certificate }) {
  return (
    <ArtefactSheetActions className="md:col-span-1">
      <ArtefactSheetActionsHeader
        title={t(certificate.artefactKind)}
        artefactNumber={<ArtefactNumberById artefactId={certificate.id} />}
      />
      <ArtefactSheetActionsContent>
        <NavigateToArtefactButton
          artefactId={certificate.id}
          artefactKind={certificate.artefactKind}
          processKind={ProcessKind.RLT}
          processId={certificate.processId}
          companyId={certificate.companyId}
        />
      </ArtefactSheetActionsContent>
    </ArtefactSheetActions>
  );
}
