import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { useState } from "react";
import {
  PutRequest,
  useHtzAirHandlingUnitPutMutation,
} from "@/services/backend/htz/ahu/service";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Button } from "@/shared/components/ui/button";
import { RefreshCw } from "lucide-react";
import { TextInput } from "@/shared/components/form/text-input";
import { inputValidationErrorMessages } from "@/shared/components/ui/input-error-messages";

export function AhuDataCard({ ahu }: { ahu: AirHandlingUnit }) {
  const [request, setRequest] = useState<PutRequest>({ ...ahu });
  const [put, { isLoading, error }] = useHtzAirHandlingUnitPutMutation();

  const doUpdate = () => {
    if (isLoading) {
      return;
    }

    put(request);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{ahu.name}</CardTitle>
        <CardDescription>{t("Basisdaten der RLT-Anlage")}</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-4 lg:grid-cols-4">
        <TextInput
          label={t("Name")}
          field="name"
          text={request.name}
          onTextChange={(name) => setRequest({ ...request, name })}
          errors={inputValidationErrorMessages(error, "name")}
        />
        <TextInput
          label={t("Standort")}
          field="location"
          text={request.location}
          onTextChange={(location) => setRequest({ ...request, location })}
          errors={inputValidationErrorMessages(error, "location")}
        />
        <TextInput
          label={t("Baujahr")}
          field="constructionYear"
          text={request.constructionYear}
          onTextChange={(constructionYear) =>
            setRequest({ ...request, constructionYear })
          }
          errors={inputValidationErrorMessages(error, "constructionYear")}
        />
        <TextInput
          label={t("Luftstrom m³/h")}
          field="volumeFlowRate"
          text={request.volumeFlowRate}
          onTextChange={(volumeFlowRate) =>
            setRequest({ ...request, volumeFlowRate })
          }
          errors={inputValidationErrorMessages(error, "volumeFlowRate")}
        />
        <RTKQueryErrorAlert error={error} />
      </CardContent>
      <CardFooter className="justify-end">
        <Button onClick={doUpdate} disabled={isLoading}>
          {isLoading && <RefreshCw className="mr-2 h-4 w-4 animate-spin" />}
          {t("Aktualisieren")}
        </Button>
      </CardFooter>
    </Card>
  );
}
