import { Kind } from "@/services/backend/samples/sample/kind";
import { EntityId } from "@/shared/nidavellir/types/entity-id";
import { Result } from "@/services/backend/htz/inspection/result";

export interface MicrobialAssessment {
  id: string;
  airHandlingUnitId: string;
  componentId: string;
  kind: Kind;
  sampleId: EntityId;
  createdAt: string;
  result: Result;
}

export function newMicrobial(
  id: string,
  airHandlingUnitId: string,
  componentId: string,
  kind: Kind,
  sampleId: EntityId,
): MicrobialAssessment {
  return {
    id,
    airHandlingUnitId,
    componentId,
    kind,
    sampleId,
    createdAt: new Date().toISOString(),
    result: Result.ZERO,
  };
}
