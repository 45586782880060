import { H2 } from "@/shared/components/ui/typography";
import { useParams } from "react-router-dom";
import {
  ArtefactIconCalculation,
  ArtefactIconCertificate,
  ArtefactIconConfirmation,
  ArtefactIconOffer,
  ArtefactIconOrder,
  ArtefactIconWorkOrder,
} from "@/shared/components/domain/artefact/artefact-icon";
import {
  Milestone,
  MilestoneIcon,
  Milestones,
  MilestoneStatus,
  MilestoneTitle,
} from "@/shared/service-manager/default-ui/process-page/process-page-overview";
import t from "@/lang/lang";
import { useRltOfferListQuery } from "@/services/backend/rlt/offers/service";
import { formatDate } from "@/shared/lib/utilities/date";
import { useRltOrderListQuery } from "@/services/backend/rlt/orders/service";
import { useRltConfirmationListQuery } from "@/services/backend/rlt/confirmations/service";
import { useRltWorkOrderListQuery } from "@/services/backend/rlt/work-orders/service";
import { useRltCalculationListQuery } from "@/services/backend/rlt/calculations/service";
import { useRltCertificateListQuery } from "@/services/backend/rlt/certificates/service";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/shared/components/ui/alert";

export default function OverviewRoute() {
  const { processId } = useParams();

  return (
    <div className="grid grid-cols-1 gap-4">
      <H2 className="border-none">{t("Übersicht")}</H2>
      <Alert>
        <AlertTitle>{t("Hinweis")}</AlertTitle>
        <AlertDescription>
          {t(
            "Die Übersicht ist aktuell noch mehr illustrativ. Es wird lediglich ausgewertet ob ein entsprechendes Dokument erstellt wurde. Eine konkretere Logik wird folgen.",
          )}
        </AlertDescription>
      </Alert>
      <Milestones>
        <CalculationMilestone processId={processId!} />
        <OfferMilestone processId={processId!} />
        <OrderMilestone processId={processId!} />
        <ConfirmationMilestone processId={processId!} />
        <WorkOrderMilestone processId={processId!} />
        <CertificateMilestone processId={processId!} />
      </Milestones>
    </div>
  );
}

function CalculationMilestone({ processId }: { processId: string }) {
  const { data } = useRltCalculationListQuery({
    process: { active: true, values: [processId!] },
  });

  const offer = data?.calculations[data.calculations.length - 1];

  return (
    <Milestone>
      <MilestoneIcon
        icon={ArtefactIconCalculation}
        done={(data?.calculations.length ?? 0) > 0}
      />
      <MilestoneTitle>{t("Kalkulation erstellen")}</MilestoneTitle>
      {offer && (
        <MilestoneStatus>
          {`Die letzte Kalkulation wurde am ${formatDate(offer.immutabilitySetAt ?? "")} erstellt.`}
        </MilestoneStatus>
      )}
    </Milestone>
  );
}

function OfferMilestone({ processId }: { processId: string }) {
  const { data } = useRltOfferListQuery({
    process: { active: true, values: [processId!] },
    immutable: { active: true, values: [true] },
  });

  const offer = data?.offers[data.offers.length - 1];

  return (
    <Milestone>
      <MilestoneIcon
        icon={ArtefactIconOffer}
        done={(data?.offers.length ?? 0) > 0}
      />
      <MilestoneTitle>{t("Angebot versenden")}</MilestoneTitle>
      {offer && (
        <MilestoneStatus>
          {`Das letzte Angebot wurde am ${formatDate(offer.immutabilitySetAt ?? "")} von ${offer.immutabilitySetBy} versendet.`}
        </MilestoneStatus>
      )}
    </Milestone>
  );
}

function OrderMilestone({ processId }: { processId: string }) {
  const { data } = useRltOrderListQuery({
    process: { active: true, values: [processId!] },
  });

  const order = data?.orders[data.orders.length - 1];

  return (
    <Milestone>
      <MilestoneIcon
        icon={ArtefactIconOrder}
        done={(data?.orders.length ?? 0) > 0}
      />
      <MilestoneTitle>{t("Auftragseingang")}</MilestoneTitle>
      {order && (
        <MilestoneStatus>
          {`Die letzte Bestellung ging am ${formatDate(order.date ?? "")} ein.`}
        </MilestoneStatus>
      )}
    </Milestone>
  );
}

function ConfirmationMilestone({ processId }: { processId: string }) {
  const { data } = useRltConfirmationListQuery({
    process: { active: true, values: [processId!] },
    immutable: { active: true, values: [true] },
  });

  const confirmation = data?.confirmations[data.confirmations.length - 1];

  return (
    <Milestone>
      <MilestoneIcon
        icon={ArtefactIconConfirmation}
        done={(data?.confirmations.length ?? 0) > 0}
      />
      <MilestoneTitle>{t("Auftragsbestätigung versendet")}</MilestoneTitle>
      {confirmation && (
        <MilestoneStatus>
          {`Die letzte Bestätigung wurde am ${formatDate(confirmation.confirmationDate ?? "")} von ${confirmation.immutabilitySetBy} versendet.`}
        </MilestoneStatus>
      )}
    </Milestone>
  );
}

function WorkOrderMilestone({ processId }: { processId: string }) {
  const { data } = useRltWorkOrderListQuery({
    process: { active: true, values: [processId!] },
    immutable: { active: true, values: [true] },
  });

  const workOrder = data?.workOrders[data.workOrders.length - 1];

  return (
    <Milestone>
      <MilestoneIcon
        icon={ArtefactIconWorkOrder}
        done={(data?.workOrders.length ?? 0) > 0}
      />
      <MilestoneTitle>{t("Arbeitsschein versendet")}</MilestoneTitle>
      {workOrder && (
        <MilestoneStatus>
          {`Die letzte Bestätigung wurde am ${formatDate(workOrder.endDate ?? "")} von ${workOrder.immutabilitySetBy} versendet.`}
        </MilestoneStatus>
      )}
    </Milestone>
  );
}

function CertificateMilestone({ processId }: { processId: string }) {
  const { data } = useRltCertificateListQuery({
    process: { active: true, values: [processId!] },
  });

  const certificate = data?.certificates[data.certificates.length - 1];

  return (
    <Milestone>
      <MilestoneIcon
        icon={ArtefactIconCertificate}
        done={(data?.certificates.length ?? 0) > 0}
      />
      <MilestoneTitle>{t("Zertifikat versendet")}</MilestoneTitle>
      {certificate && (
        <MilestoneStatus>
          {`Das letzte Zertifikat wurde am ${formatDate(certificate.issueDate ?? "")} erstellt.`}
        </MilestoneStatus>
      )}
    </Milestone>
  );
}
