import { MonitoringAreaSegmentCollapsible } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/monitoring/monitoring-area-segment-component";
import {
  Requirement,
  RequirementArea,
  RequirementAreaList,
} from "@/services/backend/qpm/requirements/types";
import { useQpmMonitorRecordListQuery } from "@/services/backend/qpm/monitors/service";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";

export type AreaMonitorWrapperProps = {
  requirement: Requirement;
  selectedAreas: RequirementAreaList;
  buildingName?: string;
  buildingId: string;
  levelId: string;
  levelName?: string;
};

export function MonitoringAreas({
  requirement,
  selectedAreas,
  buildingName = "",
  buildingId,
  levelId,
  levelName = "",
}: AreaMonitorWrapperProps) {
  const {
    data: initialMonitors,
    error: errorMonitors,
    isLoading: isLoadingMonitors,
  } = useQpmMonitorRecordListQuery({
    artefactMetaId: {
      active: true,
      values: [requirement.artefactData.id],
    },
  });

  if (errorMonitors) {
    return <RTKQueryErrorAlert error={errorMonitors} />;
  }

  if (isLoadingMonitors) {
    return (
      <div className="flex flex-col space-y-3 p-2">
        <Skeleton className="h-12 w-full" />
        <div className="space-y-2">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-full" />
        </div>
      </div>
    );
  }

  return (
    selectedAreas.requirementAreas.length > 0 && (
      <div className="border">
        {selectedAreas.requirementAreas.map(
          (ra: RequirementArea, index: number) => (
            <MonitoringAreaSegmentCollapsible
              requirementId={requirement.artefactData.id}
              index={index + 1}
              key={ra.area.base.id}
              area={ra}
              buildingId={buildingId}
              levelId={levelId}
              buildingName={buildingName!}
              levelName={levelName!}
              initialMonitors={initialMonitors!}
            />
          ),
        )}
      </div>
    )
  );
}
