import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { Skeleton } from "@/shared/components/ui/skeleton";

export function TableSkeleton({ columns }: { columns: string[] }) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          {columns.map((column, i) => (
            <TableHead key={column + i}>{column}</TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          {columns.map((column, i) => (
            <TableCell key={column + i}>
              <Skeleton className="h-4 w-48" />
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {columns.map((column, i) => (
            <TableCell key={column + i}>
              <Skeleton className="h-4 w-48" />
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {columns.map((column, i) => (
            <TableCell key={column + i}>
              <Skeleton className="h-4 w-48" />
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
}
