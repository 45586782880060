import { useHtzReportShowQuery } from "@/services/backend/htz/report/service";
import { useParams } from "react-router-dom";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { StructuralAssessmentsTable } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/structural/_components/structural-assessments-table";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";

export function StructuralRoute() {
  const { reportId } = useParams();
  const {
    data: report,
    isLoading,
    error,
  } = useHtzReportShowQuery({ id: reportId! });

  if (isLoading) {
    return null;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Konstruktive Beurteilung")}</CardTitle>
        <CardDescription>
          {t(
            "Hier werden die einzelnen konstruktiven Beurteilungen für die Bauteile angezeigt.",
          )}
        </CardDescription>
      </CardHeader>
      <StructuralAssessmentsTable report={report!} />
    </Card>
  );
}
