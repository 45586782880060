import { WorkOrder } from "@/services/backend/vbs/work-orders/work-order";
import { Position } from "@/services/backend/vbs/treatments/position";
import { H4 } from "@/shared/components/ui/typography";
import t from "@/lang/lang";
import {
  useVbsDocumentationDeleteImageMutation,
  useVbsDocumentationShowImageQuery,
  useVbsDocumentationShowQuery,
  useVbsDocumentationUploadImageForPositionMutation,
} from "@/services/backend/vbs/work-orders/documentation-service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { RefreshCw, Trash } from "lucide-react";
import { useToast } from "@/shared/hooks/use-toast";
import { v4 } from "uuid";
import { Label } from "@/shared/components/ui/label";
import { cn } from "@/shared/lib/utils";
import { Input } from "@/shared/components/ui/input";
import imageCompression from "browser-image-compression";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { Card } from "@/shared/components/ui/card";

export function PositionDocumentation({
  workOrder,
  position,
}: {
  workOrder: WorkOrder;
  position: Position;
}) {
  return (
    <div>
      <H4>{t("Dokumentation")}</H4>
      <div className="mb-2 text-sm text-muted-foreground">
        {t(
          "Jede Position sollte mit mindestens einem Vorher und einem Nachher Foto dokumentiert werden.",
        )}
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <Documentation
          workOrder={workOrder}
          position={position}
          beforeOrAfter="before"
        />
        <Documentation
          workOrder={workOrder}
          position={position}
          beforeOrAfter="after"
        />
      </div>
    </div>
  );
}

function Documentation(props: {
  workOrder: WorkOrder;
  position: Position;
  beforeOrAfter: "before" | "after";
}) {
  const { workOrder, position, beforeOrAfter } = props;
  return (
    <div>
      <div className="flex items-center justify-between">
        <H4>{t(beforeOrAfter)}</H4>
        <UploadImageButton
          workOrder={workOrder}
          position={position}
          beforeOrAfter={beforeOrAfter}
        />
      </div>
      <div className="mt-4">
        <DocumentationImages
          workOrder={workOrder}
          position={position}
          beforeOrAfter={beforeOrAfter}
        />
      </div>
    </div>
  );
}

function DocumentationImages(props: {
  workOrder: WorkOrder;
  position: Position;
  beforeOrAfter: "before" | "after";
}) {
  const { workOrder, position, beforeOrAfter } = props;

  const { data: documentation } = useVbsDocumentationShowQuery({
    id: workOrder.documentationId,
  });

  const documentationItem = documentation?.items.find(
    (item) => item.positionId === position.id,
  );

  if (!documentationItem) {
    return <div />;
  }

  const images =
    beforeOrAfter === "before"
      ? documentationItem.beforeImageIDs
      : documentationItem.afterImageIDs;

  return (
    <div className="space-y-2">
      {images.map((imageId) => (
        <Image
          documentationId={workOrder.documentationId}
          itemId={documentationItem.id}
          imageId={imageId}
          key={imageId}
        />
      ))}
    </div>
  );
}

function Image(props: {
  documentationId: string;
  itemId: string;
  imageId: string;
}) {
  const { documentationId, itemId, imageId } = props;
  const {
    data: image,
    isLoading,
    error,
  } = useVbsDocumentationShowImageQuery({
    id: documentationId,
    itemId,
    imageId,
  });

  if (error) {
    return (
      <div className="relative">
        <RTKQueryErrorAlert error={error} />
        <DeleteImageButton
          documentationId={documentationId}
          itemId={itemId}
          imageId={imageId}
        />
      </div>
    );
  }

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    <div className="relative">
      <img className="h-auto rounded-lg" src={image!.objectURL} alt="" />
      <DeleteImageButton
        documentationId={documentationId}
        itemId={itemId}
        imageId={imageId}
      />
    </div>
  );
}

function DeleteImageButton(props: {
  documentationId: string;
  itemId: string;
  imageId: string;
}) {
  const { documentationId, itemId, imageId } = props;
  const [open, setOpen] = useState(false);
  const [deleteImage, { isLoading, error, isSuccess, reset }] =
    useVbsDocumentationDeleteImageMutation();

  const onClick = () => {
    if (isLoading) {
      return;
    }
    deleteImage({
      id: documentationId,
      itemId,
      imageId,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      setOpen(false);
    }
  }, [isSuccess, setOpen, reset]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="absolute right-4 top-4">
          <Trash className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>{t("Bild wirklich löschen?")}</DialogTitle>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <Button onClick={onClick} variant="destructive">
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function UploadImageButton(props: {
  workOrder: WorkOrder;
  position: Position;
  beforeOrAfter: "before" | "after";
}) {
  const { beforeOrAfter, workOrder, position } = props;
  const [upload, { isLoading, error, isSuccess, reset }] =
    useVbsDocumentationUploadImageForPositionMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: t("Bild hochgeladen"),
        description: t("Das Bild wurde erfolgreich hochgeladen."),
        variant: "success",
      });
      reset();
    }
  }, [isSuccess, reset, toast]);

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  return (
    <Label
      className={cn(
        "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
        "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        "h-9 rounded-md px-3",
      )}
    >
      <Input
        type="file"
        accept="image/jpeg,image/png"
        className="hidden"
        onChange={async (e) => {
          if (e.target.files && e.target.files[0]) {
            // compress image
            const options = {
              maxSizeMB: 0.3,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            try {
              const compressedFile = await imageCompression(
                e.target.files[0],
                options,
              );
              // upload compressed image
              upload({
                id: workOrder.documentationId,
                positionId: position.id,
                positionNumber: position.number,
                componentKind: position.component.kind,
                beforeOrAfter,
                imageId: v4(),
                image: compressedFile,
              });
              e.target.value = "";
              e.target.files = null;
            } catch (err) {
              // TODO report error to sentry?
            }
          }
        }}
        disabled={isLoading}
      />
      {isLoading && <RefreshCw className="mr-2 h-4 w-4 animate-spin" />}
      <span>{t("Foto hinzufügen")}</span>
    </Label>
  );
}
